import React, { useEffect } from 'react'
import searchIcon from '../../../assets/search.svg'
import closeIcon from '../../../assets/close.svg'
import InformationIcon from '../../../assets/Information.svg'
import './Search.scss'
import Select from '../select/Select'
import { searchOptionsData, type SearchOptionsEnum } from './SearchOptions'
import { type ISearch, setSearchToken, setSearchType, clearSearchResultData } from '../../../store/slices/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../store/store'
import useDebounce from '../../../customHooks/useDebounce'
import { getSearchResult } from '../../../store/middleWares/getSearchResult'
import { SEARCH_API_CALL_DELAY } from '../../../Constants'
import { getDropdownData } from '../../../util/getDropDownPropData'
import useHandleNavigationAfterSearch from '../../../customHooks/useHandleNavigationAfterSearch'

export const Search: React.FC = () => {
  const searchData: ISearch = useSelector((state: any) => state.search)
  const { searchResult, searchToken, searchType } = searchData
  const dispatch = useDispatch<AppDispatch>()

  const navigateTo = useHandleNavigationAfterSearch(searchType.id)

  const debounceAPICall = useDebounce(() => {
    dispatch(getSearchResult())
  }, SEARCH_API_CALL_DELAY)

  useEffect(() => {
    if (searchToken) debounceAPICall()
  }, [searchToken])

  const handleSearchTypeChange = (selectedItem: string, id?: string) => {
    if (!id) return
    const updatedId = id as SearchOptionsEnum
    handleClearSearch()
    dispatch(setSearchType({ selectedItem, id: updatedId }))
  }

  /** id will be only available if user selects data from dropdown
   * Navigting to the page with the help of id returned
   */
  const handleSearchTokenChange = (selectedItem: string, id?: string) => {
    if (id) {
      navigateTo(id, selectedItem)
    }
    dispatch(setSearchToken(selectedItem))
  }

  const handleClearSearch = () => {
    dispatch(setSearchToken(''))
    dispatch(clearSearchResultData())
  }

  return (
    <div className='search-container'>
      <Select
      className='search-filter'
        name='search-options'
        value={searchType.selectedItem}
        data={searchOptionsData}
        onChange={handleSearchTypeChange}
        placeholder='Select one category'
        listClassName='search-options-list'
      />

      <img src={searchIcon} alt="search-icon" />

      <Select
        className='search-input'
        value={searchToken}
        name={'search-input'}
        placeholder = 'Enter search details'
        data={getDropdownData(searchResult)}
        onChange={handleSearchTokenChange}
        listClassName='search-result-list'/>
      {searchToken && (
        <button onClick={handleClearSearch} className='search-close-btn'>
          <img src={closeIcon} className='search-close-icon' alt="close-icon" />
        </button>
      )}
      <img src={InformationIcon} alt="information-icon" />
    </div>
  )
}

export default Search
