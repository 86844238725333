import React, { useEffect, type ReactElement } from 'react'
import './CostInformation.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  TRANSACTION_TYPE_SECTION, VIEW_TRANSACTION_TYPE
} from '../../../../types/CommonTypes'
import {
  type IAccountingInformation,
  type IAddTransactionType,
  type ICostInformation,
  type IErrorData
} from '../../../../types/TransactionTypes'

import { setCostInformationData, updateErrorDescription } from '../../../../store/slices/addTransactionSlice'
import { type AppDispatch } from '../../../../store/store'
import { NumericFormat } from 'react-number-format'
import {
  calculateSAPTotal,
  calculateSumNetProceeds,
  calculateGrossProfit,
  calculateNetGainLoss,
  calculatePropertyCost,
  calculateTotalCost,
  calculateSaleOverAppraisedValue
} from '../../../../util/costCalculations'
import { ERROR_TEXTS } from '../../../../Constants'
import { setCostInformationDataView, updateErrorDescriptionView } from '../../../../store/slices/viewTransactionSlice'

const CostInformation = (props: { view?: boolean, isBookedUpdatable?: boolean }): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { errorDesc }: IErrorData = useSelector((state: any) => {
    return props.view ? state.viewTransaction.errorData : state.addTransaction.errorData
  })

  const transactionType: IAddTransactionType = useSelector(
    (state: any) => state.addTransaction.transactionType
  )

  const propertyDataTotalAmount = useSelector(
    (state: any) => {
      return props.view ? state.viewTransaction.propertyData.totalAmount : state.addTransaction.propertyData.totalAmount
    }
  )

  useEffect(() => {
    const data: ICostInformation = { ...fieldData }
    data.book = propertyDataTotalAmount
    data.total = calculateSAPTotal(data)
    data.netProceeds = calculateSumNetProceeds(data)
    data.grossProfit = calculateGrossProfit(data)
    data.propertyCost = calculatePropertyCost(data)
    data.sapTotalInternalExpense = data.total
    data.totalCost = calculateTotalCost(data)
    data.bookValue = data.totalCost
    data.netGainLoss = calculateNetGainLoss(data)
    data.percentageSaleOverAppraisedValue =
      calculateSaleOverAppraisedValue(data)
    dispatch(props.view ? setCostInformationDataView(data) : setCostInformationData(data))
  }, [propertyDataTotalAmount])

  const isBookedTransaction: boolean | false = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.isBookedTransaction
      : state.addTransaction.isBookedTransaction
  })
  const status: IAccountingInformation = useSelector(
    (state: any) => props.view
      ? state.viewTransaction.accountingInformation
      : state.addTransaction.accountingInformation
  )

  const fieldData: ICostInformation = useSelector((state: any) =>
    props.view
      ? state.viewTransaction.costInformation
      : state.addTransaction.costInformation
  )
  useEffect(() => {
    if ((fieldData.totalCost === null || fieldData.totalCost === undefined) && props.view) {
      dispatch(setCostInformationDataView({ ...fieldData, totalCost: calculateTotalCost(fieldData) }))
    }
  }, [fieldData.totalCost])

  const currencyFieldValueChangedHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const param: Record<string, any> = { ...fieldData }

    const fieldName = e.target.name
    let value = e.target.value

    const errData = { ...errorDesc }
    errData[fieldName] = ''
    dispatch(props.view ? updateErrorDescriptionView(errData) : updateErrorDescription(errData))

    value = value.replaceAll(/[^0-9\-.]+/g, '')
    param[fieldName] = Number(value)
    const data: ICostInformation = param as ICostInformation
    data.book = propertyDataTotalAmount
    data.total = calculateSAPTotal(data)
    data.netProceeds = calculateSumNetProceeds(data)
    data.grossProfit = calculateGrossProfit(data)
    data.propertyCost = calculatePropertyCost(data)
    data.sapTotalInternalExpense = data.total
    data.totalCost = calculateTotalCost(data)
    data.bookValue = data.totalCost
    data.netGainLoss = calculateNetGainLoss(data)
    data.percentageSaleOverAppraisedValue =
      calculateSaleOverAppraisedValue(data)
    dispatch(props.view ? setCostInformationDataView(data) : setCostInformationData(data))
  }

  const isBooked = () => {
    return props.view && isBookedTransaction && !props.isBookedUpdatable
  }

  return (
    <div className="cost-information-container">
      <h2 className="sub-heading">Cost Information</h2>
      <div className="sub-section">
        {transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION || status.transactionType === VIEW_TRANSACTION_TYPE.RELINQUISH
          ? (
          <>
            <section>
              <h4 className="h4-subheader">Percentage of Appraised Value</h4>
            </section>
            <section>
              <h4 className="h4-subheader">Proceeds</h4>
            </section>
          </>
            )
          : (
          <>
            <section>
              <h4 className="h4-subheader">Percentage of Appraised Value</h4>
            </section>
            <section></section>
          </>
            )}
        <section>
          <label className="input-label-secondary">Appraisal Value</label>
          <NumericFormat
            name="appraisalValue"
            value={fieldData.appraisalValue ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            allowNegative={false}
            readOnly={isBooked()}
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            autoComplete='off'
            aria-autocomplete='none'

          />
        </section>
        <section>
          <label className="input-label-secondary">Total Consideration</label>
          <NumericFormat
            name="totalConsideration"
            value={fieldData.totalConsideration ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            onChange={currencyFieldValueChangedHandler}
            defaultValue="$0.00"
            placeholder="$0.00"
            readOnly={isBooked()}
            id={errorDesc && errorDesc.totalConsideration ? 'error-input' : ''}
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            autoComplete='off'
            aria-autocomplete='none'

          />
          <span className="error-message"> {errorDesc && errorDesc.totalConsideration ? ERROR_TEXTS.REQUIRED_LABEL : ''}
          </span>
        </section>
        {transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION ||
          status.transactionType === VIEW_TRANSACTION_TYPE.RELINQUISH
          ? (
          <>
          {fieldData.appraisalValue && (fieldData.appraisalValue > 0 || fieldData.appraisalValue < 0)
            ? (<section>
              <label className="input-label-secondary">%Sale Over Appraised Value</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="percentageSaleOverAppraisedValue"
                value = {fieldData.percentageSaleOverAppraisedValue ?? ''}
                decimalScale={2}
                suffix="%"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="0.00%"
                placeholder="100.00%"
                readOnly
              />
            </section>)
            : (
            <section></section>)}
            <section>
              <label className="input-label-secondary">Promissory Notes</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="promissoryNotes"
                value={fieldData.promissoryNotes ?? ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                onChange={currencyFieldValueChangedHandler}
                placeholder="$0.00"
                readOnly={isBooked()}
                autoComplete='off'
                aria-autocomplete='none'

              />
            </section>
          </>
            )
          : (
          <>
            <section>
              <label className="input-label-secondary">%Sale Over Appraised Value</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="percentageSaleOverAppraisedValue"
                value = {fieldData.percentageSaleOverAppraisedValue ?? ''}
                // value={
                //   fieldData.percentageSaleOverAppraisedValue
                //     ? fieldData.percentageSaleOverAppraisedValue
                //     : props.view
                //       ? calculateSaleOverAppraisedValue(fieldData)
                //       : ''
                // }
                decimalScale={2}
                suffix="%"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="0.00%"
                placeholder="100.00%"
                readOnly
              />
            </section>
            <section></section>
          </>
            )}
        <section>
          <h4 className="h4-subheader">SAP Total / Order Expense</h4>
        </section>
        <section></section>
        <section>
          <label className="input-label-secondary">
            SAP Actual Total / Labor Expenses
          </label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="sapTotalLaborExpenses"
            value={fieldData.sapTotalLaborExpenses ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            id={errorDesc && errorDesc.sapTotalLaborExpenses ? 'error-input' : ''}
            readOnly={isBooked()}
            autoComplete='off'
            aria-autocomplete='none'

          />
          <span className="error-message">
            {errorDesc && errorDesc.sapTotalLaborExpenses ? ERROR_TEXTS.REQUIRED_LABEL : ''}
          </span>
        </section>
        <section>
          <label className="input-label-secondary">External Expense</label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="externalExpense"
            value={fieldData.externalExpense ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            readOnly={isBooked()}
          />
        </section>
        <section>
          <label className="input-label-secondary">Non Labor Expenses</label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="nonLaborExpenses"
            value={fieldData.nonLaborExpenses ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            readOnly={isBooked()}
            autoComplete='off'
            aria-autocomplete='none'

          />
        </section>
        <section>
          <label className="input-label-secondary">Adjustment 1</label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="adjustment1"
            value={fieldData.adjustment1 ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            readOnly={isBooked()}
            autoComplete='off'
            aria-autocomplete='none'

          />
        </section>
        <section>
          <label className="input-label-secondary">Overhead Expenses</label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="overheadExpenses"
            value={fieldData.overheadExpenses ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            readOnly={isBooked()}
            autoComplete='off'
            aria-autocomplete='none'

          />
        </section>
        <section>
          <label className="input-label-secondary">Adjustment 2</label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="adjustment2"
            value={fieldData.adjustment2 ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            onChange={currencyFieldValueChangedHandler}
            placeholder="$0.00"
            readOnly={isBooked()}
            autoComplete='off'
            aria-autocomplete='none'

          />
        </section>
        <section>
          <span className="line"></span>
          <label className="input-label" id="total"> Total </label>
          <NumericFormat
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            name="total"
            // value={fieldData.total ? fieldData.total : props.view ? calculateSAPTotal(fieldData) : ''}
            value={fieldData.total ?? ''}
            decimalScale={2}
            prefix="$"
            thousandSeparator=","
            fixedDecimalScale
            defaultValue="$0.00"
            placeholder="$0.00"
            readOnly
          />
        </section>
        {transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION ||
          status.transactionType === VIEW_TRANSACTION_TYPE.RELINQUISH
          ? (
          <>
            <section>
              <label className="input-label" id="netProceeds">Net Proceeds</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="netProceeds"
                // value={fieldData.netProceeds ? fieldData.netProceeds : props.view ? calculateSumNetProceeds(fieldData) : ''}
                value={fieldData.netProceeds ?? ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
            <section></section>
            <section>
              <label className="input-label-secondary">Structure Value</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="structureValue"
                value={fieldData.structureValue ?? ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                onChange={currencyFieldValueChangedHandler}
                placeholder="$0.00"
                readOnly={isBooked()}
                autoComplete='off'
                aria-autocomplete='none'

              />
            </section>
            <section></section>
            <section>
              <label className="input-label-secondary">Book</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="book"
                value={propertyDataTotalAmount ?? ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                // onChange={currencyFieldValueChangedHandler}
                placeholder="$0.00"
                id={errorDesc && errorDesc.book ? 'error-input' : ''}
                readOnly
              />
              <span className="error-message">
                {errorDesc && errorDesc.book ? ERROR_TEXTS.REQUIRED_LABEL : ''}
              </span>
            </section>
            <section></section>
            <section>
              <label className="input-label" id="grossProfit">Gross Profit </label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="grossProfit"
                // value={fieldData.grossProfit ? fieldData.grossProfit : props.view ? calculateGrossProfit(fieldData) : ''}
                value={fieldData.grossProfit ?? ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
            <section></section>
            <section>
              <label className="input-label-secondary">
                SAP Actual Total / Internal Expenses
              </label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="sapTotalInternalExpense"
                value = {fieldData.sapTotalInternalExpense ?? ''}
                // value={
                //   fieldData.sapTotalInternalExpense
                //     ? fieldData.sapTotalInternalExpense
                //     : isBooked() ? calculateSAPTotal(fieldData) : ''
                // }
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
            <section></section>
            <section>
              <label className="input-label" id="netGainLoss">Net Gain/Loss</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="netGainLoss"
                // value={fieldData.netGainLoss ? fieldData.netGainLoss : props.view ? calculateNetGainLoss(fieldData) : ''}
                value={fieldData.netGainLoss ?? ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
          </>
            )
          : (
          <>
            <section>
              <label className="input-label" id="propertyCost">
                Property Cost
              </label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="propertyCost"
                value={fieldData.propertyCost ?? ''}
                // value={fieldData.propertyCost ? fieldData.propertyCost : props.view ? calculatePropertyCost(fieldData) : ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
            <section></section>
            <section></section>
            <section></section>
            <section>
              <label className="input-label-secondary">
                SAP Actual Total / Internal Expense
              </label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="sapTotalInternalExpense"
                value={fieldData.sapTotalInternalExpense ?? ''}
                // value={
                //   fieldData.sapTotalInternalExpense
                //     ? fieldData.sapTotalInternalExpense
                //     : isBooked() ? calculateSAPTotal(fieldData) : ''
                // }
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
            <section>
            </section>
            <section>
              <label className="input-label" id="totalCost">Total Cost</label>
              <NumericFormat
                className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                name="totalCost"
                value = {fieldData.totalCost}
                // value={fieldData.totalCost ? fieldData.totalCost : props.view ? calculateTotalCost(fieldData) : ''}
                decimalScale={2}
                prefix="$"
                thousandSeparator=","
                fixedDecimalScale
                defaultValue="$0.00"
                placeholder="$0.00"
                readOnly
              />
            </section>
          </>
            )}
      </div>
    </div>
  )
}

export default CostInformation
