import { COUNTY_MAPPING } from '../types/CommonTypes'
import { type IAccountingInformation } from '../types/TransactionTypes'

export const countyMapping = (county: IAccountingInformation) => {
  if (county.county === COUNTY_MAPPING.CLARK) return '###-##-###-###'
  if (county.county === COUNTY_MAPPING.RIVERSIDE || county.county === COUNTY_MAPPING.SANTA_BARBARA) return '###-###-###'
  if (county.county === COUNTY_MAPPING.IMPERIAL || county.county === COUNTY_MAPPING.KINGS || county.county === COUNTY_MAPPING.MADERA ||
    county.county === COUNTY_MAPPING.MONO || county.county === COUNTY_MAPPING.TULARE
  ) return '###-###-###-###'
  if (county.county === COUNTY_MAPPING.INYO || county.county === COUNTY_MAPPING.ORANGE) return '###-###-##'
  if (county.county === COUNTY_MAPPING.KERN) return '###-###-##-##-#'
  if (county.county === COUNTY_MAPPING.LOS_ANGELES) return '####-###-###'
  if (county.county === COUNTY_MAPPING.MARICOPA || county.county === COUNTY_MAPPING.YUMA) return '###-##-###'
  if (county.county === COUNTY_MAPPING.SAN_BERNARDINO) return '####-###-##-####'
  if (county.county === COUNTY_MAPPING.SAN_DIEGO) return '###-###-##-##'
  if (county.county === COUNTY_MAPPING.VENTURA) return '###-#-###-###'
  if (county.county === COUNTY_MAPPING.FRESNO) return '###-###-#YX'
  if (county.county === COUNTY_MAPPING.LA_PAZ) return '###-###-#Y'

  return '#######'
}
