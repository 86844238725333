import React from 'react'
import { MaintenanceOptionsEnum } from './maintenanceOptions'
import { useSelector } from 'react-redux'
import People from './customComponents/people/People'
import RouteOrganizations from './customComponents/routeOrganizations/RouteOrganizations'
import { Company } from './customComponents/company/Company'
import { InstrumentTitle } from './customComponents/instrumentType/InstrumentType'
import { DocumentType } from './customComponents/documentType/DocumentType'
import { GLAccount } from './customComponents/glAccount/GLAccount'
import { FERCAccount } from './customComponents/ferc/FERCAccount'
import { PlantLocation } from './customComponents/plantLocation/PlantLocation'
import OrderProject from './customComponents/order_project/OrderProject'
import { type SORT_DIRECTION } from '../../types/CommonTypes'
import NotFound404Page from '../../pages/common/NotFound404'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  categoryData: any[]
}

const MaintenanceCustomComponent: React.FC<ChildProps> = ({ sort, onChange, onDelete, categoryData }): JSX.Element => {
  const maintenanceOptions = useSelector((state: any) => state.maintenance.options)

  const fnComponent = () => {
    const { id } = maintenanceOptions
    switch (id) {
      case MaintenanceOptionsEnum.PEOPLE:
        return <People onChange = { onChange } sort={sort} onDelete={onDelete} peopleData= {categoryData} />
      case MaintenanceOptionsEnum.ROUTING_ORGANIZATION:
        return <RouteOrganizations onChange={ onChange } sort={sort} onDelete={onDelete} routingOrgs={categoryData}/>
      case MaintenanceOptionsEnum.COMPANIES:
        return <Company onChange={ onChange } sort={sort} onDelete={onDelete} companyData={categoryData}/>
      case MaintenanceOptionsEnum.INSTRUMENT_TYPE:
        return <InstrumentTitle onChange={ onChange } sort={sort} onDelete={onDelete} instrumentTitleData={categoryData}/>
      case MaintenanceOptionsEnum.DOCUMENT_TYPE:
        return <DocumentType onChange={ onChange } sort={sort} onDelete={onDelete} documentTypes={categoryData}/>
      case MaintenanceOptionsEnum.GL_ACCOUNT:
        return <GLAccount onChange={onChange} sort={sort} onDelete={onDelete} glAccounts={categoryData}/>
      case MaintenanceOptionsEnum.FERC_ACCT:
        return <FERCAccount onChange={onChange} sort={sort} onDelete={onDelete} fercAccounts={categoryData}/>
      case MaintenanceOptionsEnum.PLANT_LOCATION:
        return <PlantLocation onChange={onChange} sort={sort} onDelete={onDelete} plantLocations={categoryData}/>
      case MaintenanceOptionsEnum.ORDER_PROJECT:
        return <OrderProject onChange={onChange} sort={sort} onDelete={onDelete} projectData={categoryData}/>
      default:
        return <NotFound404Page />
    }
  }

  return fnComponent()
}

export default MaintenanceCustomComponent
