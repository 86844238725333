import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: { width: '80vh', paddingLeft: 30, paddingRight: 30, paddingTop: 60, paddingBottom: 60 },
  pageTitle: {
    color: '#00008B',
    fontWeight: 'heavy',
    fontStyle: 'italic',
    fontFamily: 'Times-BoldItalic',
    fontSize: 18,
    textAlign: 'left'
  },
  propertyDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 12,
    marginTop: 12,
    textAlign: 'left'
  },
  propertyTitle: {
    color: '#00008B',
    fontWeight: 'ultrabold',
    fontFamily: 'Times-BoldItalic',
    fontSize: 11,
    textAlign: 'left',
    marginRight: 20
  },
  propertyTitleValue: {
    fontWeight: 'ultrabold',
    fontFamily: 'Times-BoldItalic',
    fontSize: 12
  },
  propertyId: {
    fontWeight: 'ultrabold',
    fontFamily: 'Times-BoldItalic',
    fontSize: 12,
    paddingLeft: 20
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 3,
    borderColor: '#bdbdbd',
    color: '#00008B',
    fontSize: 11,
    paddingBottom: 10,
    marginTop: 10,
    fontFamily: 'Times-BoldItalic',
    textAlign: 'right'
  },
  headingEmpty: {
    flex: 9
  },
  emptyRow: {
    flex: 9,
    marginBottom: 3
  },
  headingDate: {
    flex: 12
  },
  dateContent: {
    flex: 12,
    marginBottom: 3
  },
  headingDtn: {
    flex: 12
  },
  dtnContent: {
    flex: 12,
    marginBottom: 3
  },
  headingPlantLoc: {
    flex: 10
  },
  plantLocContent: {
    flex: 10,
    marginBottom: 3
  },
  headingPantAcct: {
    flex: 11
  },
  plantAcctContent: {
    flex: 11,
    marginBottom: 3
  },
  headingWorkOrder: {
    flex: 13
  },
  workOrderContent: {
    flex: 13.5,
    marginBottom: 3
  },
  headingAcres: {
    flex: 15
  },
  acresContent: {
    flex: 16.5,
    marginBottom: 3
  },
  headingAmount: {
    flex: 16
  },
  amountContent: {
    flex: 16,
    marginBottom: 3
  },
  parcel: {
    fontFamily: 'Times-BoldItalic',
    fontSize: 11,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10
  },
  acqDocTitle: {
    color: '#00008B',
    marginRight: 15,
    marginBottom: 15
  },
  acqDocValue: {
    marginLeft: 25,
    marginRight: 25
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
    fontSize: 11,
    fontFamily: 'Times-Roman'
  },
  glAccount: {
    fontFamily: 'Times-BoldItalic',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: 11,
    color: '#00008B'
  },
  subTotal: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 11,
    marginBottom: 5,
    marginTop: 3
  },
  accountTotalTitle: {
    flex: 73,
    textAlign: 'right',
    color: '#00008B',
    fontFamily: 'Times-BoldItalic'
  },
  totalAcres: {
    flex: 16.5,
    fontWeight: 'extrabold',
    textAlign: 'right',
    fontFamily: 'Times-Bold'
  },
  totalAmount: {
    flex: 17.25,
    fontWeight: 'extrabold',
    textAlign: 'right',
    fontFamily: 'Times-Bold'
  },
  acqDocRemarks: {
    color: '#00008B',
    fontFamily: 'Times-BoldItalic',
    fontSize: 11
  },
  remarks: {
    fontFamily: 'Times-Roman',
    fontSize: 11
  },
  line: {
    borderBottom: 3,
    borderColor: '#bdbdbd',
    color: '#00008B',
    marginTop: 10
  },
  pageNumber: {
    color: '#00008B',
    fontFamily: 'Times-BoldItalic',
    fontSize: 10
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '108%',
    borderTop: 3,
    borderColor: '#bdbdbd',
    paddingTop: 5,
    width: '100%',
    marginLeft: 30
  },
  date: {
    color: '#00008B',
    fontFamily: 'Times-BoldItalic',
    fontSize: 10
  }
})
