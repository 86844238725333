import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_MAINTENANCE } from '../../Constants'

const getSearchData = (thunkAPI: any) => {
  const state = thunkAPI.getState()
  return state.maintenance
}

export const getMaintenanceSearchData = createAsyncThunk('maintenance-search/get', async (_, thunkAPI) => {
  try {
    const searchData = getSearchData(thunkAPI)
    const responseData = await api.get(`${GET_MAINTENANCE}/${searchData.options.id}?token=${searchData.searchToken}`)
    return responseData.data
  } catch (error: any) {
    throw error?.response?.data
  }
})
