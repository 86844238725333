import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { styles } from './propertyAcquisitionStyles'
import { setDecimalPrecision } from '../../../../util/setDecimalPrecision'
import { type ICostInformation } from '../../../../types/TransactionTypes'
import { calculateTotalCost } from '../../../../util/costCalculations'
import { MONEY } from '../../../../Constants'

const PropertyAquisition = (props: { costInformation: ICostInformation }): React.ReactElement => {
  const { costInformation } = props
  return (
    <View style={styles.acqContainer}>
      <View style={styles.acqTitle}>
        <Text>Acquisition Costs</Text>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Total Consideration:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.totalConsideration, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>External Expense:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.externalExpense, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Total Cost:</Text>
          <Text style={styles.val}>{setDecimalPrecision(calculateTotalCost(costInformation), 2, MONEY)}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.subHeading}>Appraisal value:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.appraisalValue, 2, MONEY)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>% of Appraised Value:</Text>
          <Text style={styles.val}> {costInformation.percentageSaleOverAppraisedValue
            ? setDecimalPrecision(costInformation?.percentageSaleOverAppraisedValue)
            : '0.00'}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subHeading}>SAP Actual Total/Labor Exp:</Text>
          <Text style={styles.val}>{setDecimalPrecision(costInformation?.sapTotalLaborExpenses, 2, MONEY)}</Text>
        </View>
      </View>
    </View>
  )
}

export default PropertyAquisition
