import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type PREDEFINED_REPORTS_ENUM, type REPORT_EXPORT_TYPE, reportDataMaster } from '../../components/reports/predefinedReportMapping'
import { getReport } from '../middleWares/getReport'
import { SORT_DIRECTION, StatusTypesEnum } from '../../types/CommonTypes'
import { type IErrorData } from '../../types/TransactionTypes'
import { errorInitialData } from './addTransactionInitialData'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { downloadReport } from '../middleWares/downloadReport'
import { reportsSortingOrder } from './reportsSortingOrder'
import { initialReport } from '../../Constants'

export interface IReports {
  selectedReport: PREDEFINED_REPORTS_ENUM
  exportReport: {
    isExportModalVisible: boolean
    exportType: REPORT_EXPORT_TYPE | undefined
  }
  rangeOfPlantLocation: {
    first: string
    last: string
  }
  workAllocationReport1: {
    woNumber1: string
    cost: string
  }
  workAllocationReport2: {
    woNumber2: string
    cost2: string
  }
  varianceByPlantLocation: {
    month: string
    year: string
  }
  varianceLastUpdatedDate: string
  resReport: {
    startMonthYear: string
    endMonthYear: string
  }
  reoReport: {
    startMonthYear: string
    endMonthYear: string
  }
  glAccount: string
  fullPartialPL: string
  propertyInventoryDetails: string
  reportData: any
  sortingOrder: Record<string, SORT_DIRECTION>
  status: IStatusResponse
  exportStatus: IStatusResponse
  pageCount: number
  errorData: IErrorData
  totalRows: number
}

export const reportsInitialData: IReports = {
  selectedReport: initialReport,
  exportReport: {
    isExportModalVisible: false,
    exportType: undefined
  },
  varianceLastUpdatedDate: '',
  rangeOfPlantLocation: {
    first: '',
    last: ''
  },
  workAllocationReport1: {
    woNumber1: '',
    cost: ''
  },
  workAllocationReport2: {
    woNumber2: '',
    cost2: ''
  },
  varianceByPlantLocation: {
    month: '',
    year: ''
  },
  resReport: {
    startMonthYear: '',
    endMonthYear: ''
  },
  reoReport: {
    startMonthYear: '',
    endMonthYear: ''
  },
  glAccount: '',
  fullPartialPL: '',
  propertyInventoryDetails: '',
  reportData: [],
  sortingOrder: { ...reportsSortingOrder[initialReport] },
  status: { type: undefined, message: '' },
  exportStatus: { type: undefined, message: '' },
  pageCount: 0,
  errorData: errorInitialData,
  totalRows: 0
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState: reportsInitialData,
  reducers: {
    setSorting (state: IReports, action: PayloadAction<Record<string, SORT_DIRECTION>>) {
      const [[columnName, sortOrder]] = Object.entries(action.payload)
      const updatedSortOrder = sortOrder === SORT_DIRECTION.ASC ? SORT_DIRECTION.DSC : SORT_DIRECTION.ASC
      state.sortingOrder = { ...reportsInitialData.sortingOrder, [columnName]: updatedSortOrder }
    },

    resetSortingOrder (state) {
      state.sortingOrder = { ...reportsInitialData.sortingOrder }
    },

    setPredefinedReport (state, action: PayloadAction<string>) {
      const selectedReport = action.payload as PREDEFINED_REPORTS_ENUM
      state.selectedReport = selectedReport
      state.sortingOrder = { ...reportsSortingOrder[selectedReport] }
    },

    setGLAccount (state, action: PayloadAction<string>) {
      state.glAccount = action.payload
    },

    setFullPartialPL (state, action: PayloadAction<string>) {
      state.fullPartialPL = action.payload
    },

    setWorkAllocationReport1 (state, action: PayloadAction<{ woNumber1: string } | { cost: string } | { woNumber1: string, cost: string }>) {
      state.workAllocationReport1 = { ...state.workAllocationReport1, ...action.payload }
    },

    setWorkAllocationReport2 (state, action: PayloadAction<{ woNumber2: string } | { cost2: string } | { woNumber2: string, cost2: string }>) {
      state.workAllocationReport2 = { ...state.workAllocationReport2, ...action.payload }
    },

    setVarianceByPlantLocation (state, action: PayloadAction<{ month: string } | { year: string } | { month: string, year: string }>) {
      state.varianceByPlantLocation = { ...state.varianceByPlantLocation, ...action.payload }
    },

    setRangeOfPlantLocation (state, action: PayloadAction<Record<any, string>>) {
      state.rangeOfPlantLocation = { ...state.rangeOfPlantLocation, ...action.payload }
    },
    setResReportParameter (state, action: PayloadAction<{ startMonthYear: string } | { endMonthYear: string } | { startMonthYear: string, endMonthYear: string }>) {
      state.resReport = { ...state.resReport, ...action.payload }
    },
    setReoReportParameter (state, action: PayloadAction<{ startMonthYear: string } | { endMonthYear: string } | { startMonthYear: string, endMonthYear: string }>) {
      state.reoReport = { ...state.reoReport, ...action.payload }
    },
    setErrorData (state, action: PayloadAction<IErrorData>) {
      state.errorData = { ...state.errorData, ...action.payload }
    },

    setPropInventoryDetails (state, action: PayloadAction<string>) {
      state.propertyInventoryDetails = action.payload
    },

    setExportReportModalVisible (state, action: PayloadAction<boolean>) {
      state.exportReport.isExportModalVisible = action.payload
    },

    setExportReportType (state, action: PayloadAction<REPORT_EXPORT_TYPE>) {
      state.exportReport.exportType = action.payload
    },

    resetReportInputFields (state) {
      state.rangeOfPlantLocation = reportsInitialData.rangeOfPlantLocation
      state.glAccount = reportsInitialData.glAccount
      state.workAllocationReport1 = reportsInitialData.workAllocationReport1
      state.workAllocationReport2 = reportsInitialData.workAllocationReport2
      state.fullPartialPL = reportsInitialData.fullPartialPL
      state.propertyInventoryDetails = reportsInitialData.propertyInventoryDetails
      state.reoReport = reportsInitialData.reoReport
      state.resReport = reportsInitialData.resReport
      state.varianceByPlantLocation = reportsInitialData.varianceByPlantLocation
    },

    resetReportFields (state) {
      state.selectedReport = reportsInitialData.selectedReport
      state.rangeOfPlantLocation = reportsInitialData.rangeOfPlantLocation
      state.glAccount = reportsInitialData.glAccount
      state.workAllocationReport1 = reportsInitialData.workAllocationReport1
      state.workAllocationReport2 = reportsInitialData.workAllocationReport2
      state.fullPartialPL = reportsInitialData.fullPartialPL
      state.propertyInventoryDetails = reportsInitialData.propertyInventoryDetails
      state.varianceByPlantLocation = reportsInitialData.varianceByPlantLocation
      state.reportData = reportsInitialData.reportData
      state.pageCount = reportsInitialData.pageCount
      state.status = reportsInitialData.status
      state.sortingOrder = reportsInitialData.sortingOrder
      state.errorData = reportsInitialData.errorData
      state.resReport = reportsInitialData.resReport
      state.reoReport = reportsInitialData.reoReport
    },
    resetReportFieldsModal (state) {
      state.rangeOfPlantLocation = reportsInitialData.rangeOfPlantLocation
      state.glAccount = reportsInitialData.glAccount
      state.workAllocationReport1 = reportsInitialData.workAllocationReport1
      state.workAllocationReport2 = reportsInitialData.workAllocationReport2
      state.fullPartialPL = reportsInitialData.fullPartialPL
      state.propertyInventoryDetails = reportsInitialData.propertyInventoryDetails
      state.reportData = reportsInitialData.reportData
      state.pageCount = reportsInitialData.pageCount
      state.status = reportsInitialData.status
      state.sortingOrder = reportsInitialData.sortingOrder
      state.errorData = reportsInitialData.errorData
      state.varianceByPlantLocation = reportsInitialData.varianceByPlantLocation
    },

    resetExportReport (state) {
      state.exportReport.exportType = reportsInitialData.exportReport.exportType
      state.exportReport.isExportModalVisible = reportsInitialData.exportReport.isExportModalVisible
      state.exportStatus.message = reportsInitialData.exportStatus.message
      state.exportStatus.type = reportsInitialData.exportStatus.type
    }
  },
  extraReducers (builder) {
    builder.addCase(getReport.pending, (state) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = reportsInitialData.status.message
    })

    builder.addCase(getReport.fulfilled, (state, action) => {
      state.reportData = action.payload?.data ?? []
      state.pageCount = action.payload?.totalPages ?? 0
      state.reportData = reportDataMaster[state.selectedReport].updateData(action.payload?.data)
      state.varianceLastUpdatedDate = action.payload?.lastUpdatedAt
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = reportsInitialData.status.message
      state.totalRows = action.payload?.totalResult ?? 0
    })

    builder.addCase(getReport.rejected, (state, action) => {
      state.reportData = []
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.payload as string
    })

    builder.addCase(downloadReport.fulfilled, (state, action) => {
      state.exportStatus.type = StatusTypesEnum.SUCCESS
      state.exportStatus.message = 'Report Exported Successfully'
    })

    builder.addCase(downloadReport.pending, (state) => {
      state.exportStatus.type = StatusTypesEnum.LOADING
      state.exportStatus.message = reportsInitialData.status.message
    })

    builder.addCase(downloadReport.rejected, (state, action) => {
      state.exportStatus.type = StatusTypesEnum.ERROR
      state.exportStatus.message = action.error.message
    })
  }
})

export const {
  setPredefinedReport
  , setGLAccount
  , setFullPartialPL
  , setRangeOfPlantLocation
  , setWorkAllocationReport1
  , setWorkAllocationReport2
  , resetReportFields
  , resetReportFieldsModal
  , setSorting
  , setPropInventoryDetails
  , setErrorData
  , resetReportInputFields
  , setVarianceByPlantLocation
  , setExportReportModalVisible
  , setExportReportType
  , resetExportReport
  , setReoReportParameter
  , setResReportParameter
  , resetSortingOrder
} = reportsSlice.actions
export default reportsSlice.reducer
