// 'use strict';
import React, { useEffect, useRef, useState } from 'react'
import './PropertyActivityData.scss'
import Input from '../../../sharedModules/input/Input'
import Select from '../../../sharedModules/select/Select'
import {
  type IAccountingInformation,
  type IAddTransactionType
} from '../../../../types/TransactionTypes'
import {
  DropDownFiledsEnum,
  TRANSACTION_TYPE_SECTION,
  VIEW_TRANSACTION_TYPE
} from '../../../../types/CommonTypes'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import { setPropertyData, setTotalPropertyData, updatePropertyDataError } from '../../../../store/slices/addTransactionSlice'
import { getDropdownData } from '../../../../util/getDropDownPropData'
import { DROPDOWN_API_CALL_DELAY, PROPERTY_DATA_ACRES, PROPERTY_DATA_AMOUNT } from '../../../../Constants'
import {
  type IViewPropertyCombinedData,
  setPropertyDataView,
  setTotalPropertyDataView,
  updateViewTransactionPropertyDataError
} from '../../../../store/slices/viewTransactionSlice'
import useDebounce from '../../../../customHooks/useDebounce'
import { getDropdownFilteredValues, getParcelNoDropdownValue } from '../../../../store/middleWares/getDropdownValues'
import { type AppDispatch } from '../../../../store/store'
import { calculatePropertyTotal } from '../../../../util/propertyCalculations'
import { NumericFormat } from 'react-number-format'
import SelectTableList from '../../../sharedModules/select/SelectTableList'

const PropertyActivityDataSub = (props: {
  id: number
  view?: boolean
  isBookedUpdatable?: boolean
  refresh?: boolean
  removeHandler?: (id: any) => any
}) => {
  const [scaleForAcre, setScaleForAcre] = useState<number | null>(3)
  const [scaleForAmount, setScaleForAmount] = useState<number | null>(2)

  const transactionType: IAddTransactionType = useSelector(
    (state: any) => state.addTransaction.transactionType
  )

  const propertyDataError: any[] = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.errorData.propertyDataError
      : state.addTransaction.errorData.propertyDataError
  })

  const isBookedTransaction: boolean = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.isBookedTransaction
      : state.addTransaction.isBookedTransaction
  })

  const workOrder = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.accountingInformation.workOrderNumber
      : state.addTransaction.accountingInformation.workOrderNumber
  })

  const id: number = props.id - 1

  const dispatch = useDispatch<AppDispatch>()

  const fercAcct = useSelector((state: any) => state.dropDown.fercAcct)
  const glAcct = useSelector((state: any) => state.dropDown.glAcct)
  const plantLocation = useSelector(
    (state: any) => state.dropDown.plantLocation
  )
  const ownership = useSelector((state: any) => state.dropDown.ownership)
  const acqDocNo = useSelector((state: any) => state.dropDown.acqDocNo)
  const parcelNumber = useSelector((state: any) => state.dropDown.parcelNumber)

  const fieldData: IViewPropertyCombinedData[] = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.propertyData.fieldData
      : state.addTransaction.propertyData.fieldData
  })

  const status: IAccountingInformation = useSelector(
    (state: any) => state.viewTransaction.accountingInformation
  )

  const fieldDataRef = useRef(fieldData)

  useEffect(() => {
    fieldDataRef.current = fieldData
  }, [fieldData])

  const numberOfProperties = fieldData?.length

  useEffect(() => {
    doAmountUpdateForAcq()
  }, [numberOfProperties])

  useEffect(() => {
    setScaleForAcre(3)
    setScaleForAmount(2)
  }, [props.refresh])

  const doAmountUpdateForAcq = () => {
    if (isAcquisitionTransaction()) {
      amountPerParcel()
    }
  }

  const onRemove = () => {
    props.removeHandler!(props.id)
    const newFieldData = [...fieldDataRef.current]
    newFieldData.splice(id, 1)

    const calculated = calculatePropertyTotal([...newFieldData])
    dispatch(
      props.view
        ? setTotalPropertyDataView(calculated)
        : setTotalPropertyData(calculated)
    )

    const removed = { index: id, data: null }
    dispatch(
      props.view ? setPropertyDataView(removed) : setPropertyData(removed)
    )

    // if view remove from db
  }

  const onParcelValueBlurHandler = (parcelValue: string) => {
    const numericPrefix = parcelValue.replace(/^([0-9]+)[^0-9]+/, '$1')
    if (numericPrefix === parcelValue && /[^0-9]+/.test(parcelValue)) {
      onFieldValueChangeHandler({ parcelNumber: parcelValue })
      return
    }
    let newValue = parcelValue
    if (numericPrefix.length === 2) {
      newValue = '0' + parcelValue
    }
    if (numericPrefix.length === 1) {
      newValue = '00' + parcelValue
    }
    onFieldValueChangeHandler({ parcelNumber: newValue })
  }

  const onFieldValueChangeHandler = (data: {
    [key in keyof IViewPropertyCombinedData]?: any;
  },
  index?: number) => {
    setDecimalScaleForAcreAmount(data)
    const selectedField = Object.keys(data)[0]
    const updatedId = index ?? id
    const newError = [...propertyDataError]

    // reset error
    if (propertyDataError.length > 0) {
      // if (isAcquisitionTransaction()) {
      //   for (let i = 0; i < propertyDataError.length; i++) {
      //     const err = { ...propertyDataError[i] }
      //     if (err && fieldData[i][PROPERTY_DATA_AMOUNT]) {
      //       err[PROPERTY_DATA_AMOUNT] = ''
      //     }
      //     newError.splice(i, 1, err)
      //   }
      // }
      const err = { ...propertyDataError[updatedId] }
      if (err) {
        err[selectedField] = ''
        if (selectedField === 'acqDocNo') err.parcelNumber = ''
      }
      newError.splice(updatedId, 1, err)
      if (props.view) {
        dispatch(updateViewTransactionPropertyDataError(newError))
      } else dispatch(updatePropertyDataError(newError))
    }

    if (
      selectedField === PROPERTY_DATA_ACRES ||
      selectedField === PROPERTY_DATA_AMOUNT
    ) {
      const newFieldData = [...fieldData]

      if (selectedField === PROPERTY_DATA_ACRES) { newFieldData[updatedId] = Object.assign({}, newFieldData[updatedId], data) } else newFieldData[updatedId] = Object.assign({}, newFieldData[updatedId], data)

      const calculated = calculatePropertyTotal([...newFieldData])
      dispatch(
        props.view
          ? setTotalPropertyDataView(calculated)
          : setTotalPropertyData(calculated)
      )
    }
    const updated = {
      index: updatedId,
      data: { ...fieldDataRef.current[updatedId], ...data }
    }

    dispatch(
      props.view ? setPropertyDataView(updated) : setPropertyData(updated)
    )
  }

  const setDecimalScaleForAcreAmount = (data: any) => {
    const selectedField = Object.keys(data)[0]
    const value = Object.values(data)[0]
    const decimalValue = String(value)?.split('.')[1]

    // precision correction if user entered value does not met the required number of precisions
    // to set the decimal scale attribute dynamically
    if (selectedField === PROPERTY_DATA_ACRES && (!decimalValue || decimalValue.length < 3)) {
      setScaleForAcre(3)
    } else if (selectedField === PROPERTY_DATA_ACRES) setScaleForAcre(null)

    if (selectedField === PROPERTY_DATA_AMOUNT && (!decimalValue || decimalValue.length < 2)) {
      setScaleForAmount(2)
    } else if (selectedField === PROPERTY_DATA_AMOUNT) setScaleForAmount(null)
  }

  const getDropdownValuesWithoutDelay = (field: string, value: string, id?: number) => {
    dispatch(getDropdownFilteredValues({ field, token: value, duplicateComponentId: id }))
  }

  const debounceAPICall = useDebounce((field: string, value: string, id: number) => {
    dispatch(getDropdownFilteredValues({ field, token: value, duplicateComponentId: id }))
  }, DROPDOWN_API_CALL_DELAY)

  const onDropdownChangeHandler = (data: any, field: string, id?: number) => {
    onFieldValueChangeHandler(data)
    const value: string = Object.values(data)[0] as string
    debounceAPICall(field, value, id)
  }

  const setErrorStyleAndMessage = (fieldName: string) => {
    const StyleAndText = {
      errorStyle: '',
      errorText: ''
    }
    const array: any = []
    if (propertyDataError.length > 0) {
      propertyDataError.forEach((item: any, index: number) => {
        if (index === id && item) {
          const text = propertyDataError[id][fieldName] ? propertyDataError[id][fieldName] : ''
          const style = propertyDataError[id][fieldName] ? 'error-input' : ''
          const _obj = {
            [fieldName]: { errorText: text, errorStyle: style }
          }
          array[id] = { ...array[id], ..._obj }
          StyleAndText.errorText = propertyDataError[id][fieldName] ? propertyDataError[id][fieldName] : ''
          StyleAndText.errorStyle = propertyDataError[id][fieldName] ? 'error-input' : ''
        }
      })
    }
    return array
  }
  const isBooked = () => {
    return props.view && isBookedTransaction && !props.isBookedUpdatable
  }

  const totalAcres = useSelector(
    (state: any) => props.view ? state.viewTransaction.propertyData.totalAcres : state.addTransaction.propertyData.totalAcres
  )
  const totalAmount = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.costInformation.totalCost
      : state.addTransaction.costInformation.totalCost
  })
  const amountPerParcel = () => {
    const acresPerParcel = fieldData[id] ? Number(String(fieldData[id]?.acres).replace(/\$/g, '')) ?? '0.000' : '0.000'

    if (Number(totalAcres) === 0) {
      const amt = (Number(totalAmount) > 0) ? (totalAmount / numberOfProperties) ?? '0.00' : '0.00'
      for (let i = 0; i < numberOfProperties; i++) {
        onFieldValueChangeHandler({ amount: amt }, i)
      }
    } else {
      const amt = (Number(totalAcres) > 0) ? (Number(acresPerParcel) / totalAcres * totalAmount) : '0.00'
      onFieldValueChangeHandler({ amount: amt })
    }
  }

  useEffect(() => {
    doAmountUpdateForAcq()
  }, [totalAcres, totalAmount])

  const isAcquisitionTransaction = (): boolean => {
    if (props.view && status.transactionType && status.transactionType !== VIEW_TRANSACTION_TYPE.RELINQUISH) return true
    if (!props.view && transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION) return true
    return false
  }

  const dropdownFields = (
    <>
      <section className="dropdown-section">
        <label className="input-label">Plant Loc</label>
        <Select
          key={`plant-loc${props.id}`}
          name={`plant-loc${props.id}`}
          value={fieldData[id] ? fieldData[id].plantLoc ?? '' : ''}
          data={getDropdownData(plantLocation)}
          onChange={(selectedItem: string) => {
            onDropdownChangeHandler(
              { plantLoc: selectedItem },
              DropDownFiledsEnum.PLANT_LOCATION
            )
          }
          }
          triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.PLANT_LOCATION, value) }}
          className={isBooked() ? 'read-input' : setErrorStyleAndMessage('plantLoc')[id]?.plantLoc?.errorStyle}
          errorDesc={setErrorStyleAndMessage('plantLoc')[id]?.plantLoc?.errorText}
          readonly={isBooked()}
          placeholder={isBooked() ? '' : 'Select an option'}
        // className={isBooked() ? "read-input" : ""}
        />
      </section>
      <section className="dropdown-section">
        <label className="input-label">FERC Acct</label>
        <Select
          key={`ferc-acct${props.id}`}
          name={`ferc-acct${props.id}`}
          data={getDropdownData(fercAcct)}
          value={fieldData[id] ? fieldData[id].fercAcct ?? '' : ''}
          onChange={(selectedItem: string) => {
            onDropdownChangeHandler(
              { fercAcct: selectedItem },
              DropDownFiledsEnum.FERC_ACCT
            )
          }
          }
          triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.FERC_ACCT, value) }}
          className={isBooked() ? 'read-input' : setErrorStyleAndMessage('fercAcct')[id]?.fercAcct?.errorStyle}
          errorDesc={setErrorStyleAndMessage('fercAcct')[id]?.fercAcct?.errorText}
          readonly={isBooked()}
          placeholder={isBooked() ? '' : 'Select an option'}
        // className={isBooked() ? "read-input" : ""}
        />
      </section>
      <section className="dropdown-section">
        <label className="input-label">GL Acct</label>
        <Select
          key={`gl-acct${props.id}`}
          name={`gl-acct${props.id}`}
          data={getDropdownData(glAcct)}
          value={fieldData[id] ? fieldData[id].glAcct ?? '' : ''}
          onChange={(selectedItem: string) => {
            onDropdownChangeHandler(
              { glAcct: selectedItem },
              DropDownFiledsEnum.GL_ACCT
            )
          }
          }
          triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.GL_ACCT, value) }}
          className={isBooked() ? 'read-input' : setErrorStyleAndMessage('glAcct')[id]?.glAcct?.errorStyle}
          errorDesc={setErrorStyleAndMessage('glAcct')[id]?.glAcct?.errorText}
          readonly={isBooked()}
          placeholder={isBooked() ? '' : 'Select an option'}
        // className={isBooked() ? "read-input" : ""}
        />
      </section>
    </>
  )

  const enableRemoveHandlerButton = (): boolean => {
    if ((props.removeHandler != null) && (!isBookedTransaction || props.isBookedUpdatable)) return true
    return false
  }
  return (
    <>
      {isAcquisitionTransaction()
        ? (
          <div className="sub-section" key={props.id}>
            <section>
              <label className="input-label">Parcel</label>
              <Input
                name={`parcel${props.id}`}
                value={fieldData[id] ? fieldData[id].parcelNumber?.substring(0, 5) ?? '' : ''}
                onChange={(e) => { onFieldValueChangeHandler({ parcelNumber: e.target.value?.substring(0, 5) }) }}
                onBlur={(e) => { onParcelValueBlurHandler(e.target.value) }}
                className={isBooked() ? 'read-input' : setErrorStyleAndMessage('parcelNumber')[id]?.parcelNumber?.errorStyle}
                errorDesc={setErrorStyleAndMessage('parcelNumber')[id]?.parcelNumber?.errorText}
                readOnly={isBooked()}
                placeholder={isBooked() ? '' : '###'}
              />
            </section>
            {dropdownFields}
            <section>
              <label className="input-label">Drawing</label>
              <Input
                name={`drawing${props.id}`}
                placeholder={isBooked() ? '' : '-'}
                value={fieldData[id] ? fieldData[id].drawing ?? '' : ''}
                onChange={(e) => { onFieldValueChangeHandler({ drawing: e.target.value?.substring(0, 10) }) }
                }
                readOnly={isBooked()}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section>
              <label className="input-label">Map Size</label>
              <Input
                name={`map-size${props.id}`}
                placeholder={isBooked() ? '' : '-'}
                value={fieldData[id] ? fieldData[id].mapSize ?? '' : ''}
                onChange={(e) => { onFieldValueChangeHandler({ mapSize: e.target.value?.substring(0, 20) }) }
                }
                readOnly={isBooked()}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section className="dropdown-section">
              <label className="input-label">Fee/Ease</label>
              <Select
                key={`fee-ease${props.id}`}
                name={`fee-ease${props.id}`}
                data={getDropdownData(ownership)}
                value={fieldData[id] ? fieldData[id].ownership ?? '' : ''}
                onChange={(selectedItem: string) => {
                  onDropdownChangeHandler(
                    { ownership: selectedItem },
                    DropDownFiledsEnum.OWNERSHIP
                  )
                }
                }
                triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.OWNERSHIP, value) }}
                className={isBooked() ? 'read-input' : setErrorStyleAndMessage('ownership')[id]?.ownership?.errorStyle}
                errorDesc={setErrorStyleAndMessage('ownership')[id]?.ownership?.errorText}
                readonly={isBooked()}
                placeholder={isBooked() ? '' : 'Select an option'}
              // className={isBooked() ? "read-input" : ""}
              />
            </section>
            <section>
              <label className="input-label">Acres</label>

              <NumericFormat
              allowNegative={false}
                name={`acres${props.id}`}
                value={fieldData[id] ? fieldData[id]?.acres : '0.000'}
                thousandSeparator=","
                readOnly={isBooked()}
                placeholder={'0.000'}
                // {isBooked() ? '' : '0.000'}
                autoComplete='off'
                aria-autocomplete='none'
                className={isBooked() ? 'read-input input-select-box right-align' : `input-select-box right-align ${setErrorStyleAndMessage('acres')[id]?.acres?.errorStyle}`}
                onChange={(e) => { onFieldValueChangeHandler({ acres: e.target.value }) }
                }
                decimalScale={scaleForAcre ?? undefined}
                fixedDecimalScale = {scaleForAcre !== null }
              />

              {
                setErrorStyleAndMessage('acres')[id]?.acres?.errorText &&
                <label className='error-message'>{setErrorStyleAndMessage('acres')[id]?.acres?.errorText}</label>
              }
            </section>
            <section>
              <label className="input-label">Amount</label>
              <NumericFormat
              // allowNegative={false}
              decimalScale={2}
              fixedDecimalScale
                name={`amount${props.id}`}
                thousandSeparator=","
                prefix='$'
                placeholder={'$0.00'}
                // {isBooked() ? '' : '$0.00'}
                value={fieldData[id] ? fieldData[id]?.amount ?? '0.00' : '0.00'}
                autoComplete='off'
                aria-autocomplete='none'
                readOnly
                // = {isBooked()}
                className={isBooked() ? 'read-input input-select-box right-align' : `input-select-box right-align ${setErrorStyleAndMessage('amount')[id]?.amount?.errorStyle}`}

              />
              {
                setErrorStyleAndMessage('amount')[id]?.amount?.errorText &&
                <label className='error-message'>{setErrorStyleAndMessage('amount')[id]?.amount?.errorText}</label>
              }
            </section>
            {enableRemoveHandlerButton() && (
                <div
                  className="delete-row-icon-container"
                  onClick={() => { onRemove() }}
                >
                  <DeleteSVG />
                </div>)}
          </div>
          )
        : (
          <div className="sub-section" key={props.id}>
            <section className="dropdown-section">
              <label className="input-label">Acq Doc</label>
              <SelectTableList
                key={`acq-doc${props.id}`}
                name={`acqDocNo${props.id}`}
                optionalArg={'parcelNumber'}
                value={fieldData[id] ? fieldData[id]?.acqDocNo ?? '' : ''}
                data={getDropdownData(acqDocNo)}
                onChange={(selectedItem: string, valueToFill?: string, id?: string) => {
                  onDropdownChangeHandler(
                    { acqDocNo: selectedItem, parcelNumber: valueToFill },
                    DropDownFiledsEnum.ACQ_DOC_NO
                  )
                  if (id) {
                    dispatch(getParcelNoDropdownValue(selectedItem))
                    // dispatch(props.view ? clearParcelNumberView({ id: props.id - 1 }) : clearParcelNumber({ id: props.id - 1 }))
                  }
                }
                }
                triggerOnFocus={(value: string) => {
                  getDropdownValuesWithoutDelay(DropDownFiledsEnum.ACQ_DOC_NO, value)
                }}
                className={isBooked() ? 'read-input' : setErrorStyleAndMessage('acqDocNo')[id]?.acqDocNo?.errorStyle}
                errorDesc={setErrorStyleAndMessage('acqDocNo')[id]?.acqDocNo?.errorText}
                readonly={isBooked()}
                placeholder={isBooked() ? '' : 'Select an option'}
              />

            </section>
            <section className="dropdown-section">
              <label className="input-label">Parcel</label>
              <Select
                key={`parcel${props.id}`}
                name={`parcel${props.id}`}
                value={fieldData[id] ? fieldData[id].parcelNumber?.substring(0, 5) ?? '' : ''}
                data={getDropdownData(parcelNumber)}
                onChange={(selectedItem: string) => {
                  onDropdownChangeHandler(
                    { parcelNumber: selectedItem },
                    DropDownFiledsEnum.PARCEL_NUMBER, id
                  )
                }
                }
                triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.PARCEL_NUMBER, value, id) }}
                className={isBooked() ? 'read-input' : setErrorStyleAndMessage('parcelNumber')[id]?.parcelNumber?.errorStyle}
                errorDesc={setErrorStyleAndMessage('parcelNumber')[id]?.parcelNumber?.errorText}
                readonly={isBooked()}
                placeholder={isBooked() ? '' : 'Select an option'}
              />
            </section>
            {dropdownFields}
            <section>
              <label className="input-label">Map Size</label>
              <Input
                name={`map-size${props.id}`}
                placeholder={isBooked() ? '' : '-'}
                value={fieldData[id] ? fieldData[id].mapSize ?? '' : ''}
                onChange={(e) => { onFieldValueChangeHandler({ mapSize: e.target.value }) }
                }
                readOnly={isBooked()}
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section>
              <label className="input-label">W.O</label>
              <Input
                name={`w-o${props.id}`}
                placeholder={isBooked() ? '' : '-'}
                value={workOrder ?? ''}
                /* value={fieldData[id] ? fieldData[id].workOrder ?? '' : ''}
                onChange={(e) => { onFieldValueChangeHandler({ workOrder: e.target.value }) }
                }
                readOnly={isBooked()} */
                readOnly
                className={isBooked() ? 'read-input' : ''}
              />
            </section>
            <section>
              <label className="input-label">Acres</label>

              <NumericFormat
              allowNegative={false}
                name={`acres${props.id}`}
                value={fieldData[id] ? fieldData[id]?.acres : '0.000'}
                thousandSeparator=","
                readOnly={isBooked()}
                placeholder = {'0.000'}
                autoComplete='off'
                aria-autocomplete='none'
                // {isBooked() ? '' : '0.000'}
                className={isBooked() ? 'read-input input-select-box right-align' : `input-select-box right-align ${setErrorStyleAndMessage('acres')[id]?.acres?.errorStyle}`}
                onChange={(e) => { onFieldValueChangeHandler({ acres: e.target.value }) }

                }
                {...(scaleForAcre && { decimalScale: scaleForAcre })}
                // decimalScale={scaleForAcre}
                fixedDecimalScale = {scaleForAcre !== null }
              />
              {
                setErrorStyleAndMessage('acres')[id]?.acres?.errorText &&
                <label className='error-message'>{setErrorStyleAndMessage('acres')[id]?.acres?.errorText}</label>
              }
            </section>
            <section>
              <label className="input-label">Amount</label>
              <NumericFormat
              allowNegative={false}
                name={`amount${props.id}`}
                value={fieldData[id] ? fieldData[id]?.amount ?? '0.00' : '0.00'}
                onChange={(e) => { onFieldValueChangeHandler({ amount: e.target.value }) }
                }
                readOnly={isBooked()}
                autoComplete='off'
                aria-autocomplete='none'
                placeholder={isBooked() ? '' : '$0.00'}
                className={isBooked() ? 'read-input input-select-box right-align' : `input-select-box right-align ${setErrorStyleAndMessage('amount')[id]?.amount?.errorStyle}`}
                prefix='$'
                thousandSeparator=','
                {...(scaleForAmount && { decimalScale: scaleForAmount })}
                // decimalScale={scaleForAcre}
                fixedDecimalScale = {scaleForAmount !== null }
              />

              {
                setErrorStyleAndMessage('amount')[id]?.amount?.errorText &&
                <label className='error-message'>{setErrorStyleAndMessage('amount')[id]?.amount?.errorText}</label>
              }

            </section>
            { enableRemoveHandlerButton() && (
                <div
                  className="delete-row-icon-container"
                  onClick={() => { onRemove() }}
                >
                  <DeleteSVG />
                </div>
            )}
          </div>
          )}
    </>
  )
}

export default PropertyActivityDataSub
