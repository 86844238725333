import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthorized: false
  },
  reducers: {
    setAuthorized: (state) => {
      state.isAuthorized = true
    },
    clearAuthorized: (state) => {
      state.isAuthorized = false
    }
  }

})
export const { setAuthorized, clearAuthorized } = authSlice.actions

export default authSlice.reducer
