import React, { useEffect } from 'react'
import './Modal.scss'
import ReactDOM from 'react-dom'

interface Props {
  children?: string | JSX.Element | JSX.Element[]
}

interface IModalProp extends Props {
  className?: string
}

export const ModalHeader = ({ children }: Props) => {
  return <div className="modal-header">{children}</div>
}

export const ModalBody = ({ children }: Props) => {
  return <div className="modal-body">{children}</div>
}

export const ModalFooter = ({ children }: Props) => {
  return <div className="modal-footer">{children}</div>
}

const Modal = ({ children, className }: IModalProp) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])
  return ReactDOM.createPortal(
    <div className='modal-wrapper'>
      <div className={`modal-container ${className ?? ''}`}>{children}</div>
    </div>, document.getElementById('modal-root')!)
}

export default Modal
