import React, { type ReactElement } from 'react'
import './Button.scss'
import { ReactComponent as CancelButtonSVG } from '../../../assets/CancelButton.svg'

interface IButtonProps {
  type: 'confirm' | 'cancel'
  children: string
  buttonType?: 'submit' | 'reset' | 'button'
  className?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Button = (props: IButtonProps): ReactElement => {
  const isConfirmButton = props.type === 'confirm'

  return (
    <button
      className={`${isConfirmButton ? 'confirm-button' : 'cancel-button'} ${
        props.className ? props.className : ''
      }  ${props.disabled ? 'button-disabled' : ''}`}
      onClick={props.onClick}
      type={props.buttonType ?? 'button'}
      disabled={props.disabled}
    >
      {!isConfirmButton && <CancelButtonSVG />}
      {props.children}
    </button>
  )
}

export default Button
