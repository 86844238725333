import React, { useState, useRef, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Search from '../search/Search'
import './SubHeader.scss'
import settingsIcon from '../../../assets/settings.png'
import { navigationPath } from '../../../types/CommonTypes'
import { useMsal } from '@azure/msal-react'
import { useDispatch } from 'react-redux'
import { clearAuthorized } from '../../../store/slices/authSlice'

const SubHeader: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { instance } = useMsal()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const handleclickOutside = (event: MouseEvent) => {
      if (
        (dropdownRef.current != null) &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleclickOutside)

    return () => { document.removeEventListener('mousedown', handleclickOutside) }
  }, [])

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const logout = async () => {
    dispatch(clearAuthorized())
    await instance.logoutRedirect({
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: '/'
    })
  }

  const navigateMaintenance = () => {
    navigate(navigationPath.MAINTENANCE)
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <div className="sub-header-container">
      <ul className="reis-nav">
        <NavLink to={navigationPath.HOME} className={({ isActive }) =>

          (isActive || location.pathname.includes(navigationPath.ADD_TRANSACTION) || location.pathname.includes(navigationPath.VIEW_TRANSACTION))

            ? 'active'
            : 'Menu 1'} end>
          <li>
            Transactions
          </li>
        </NavLink>
        <NavLink to={navigationPath.PROPERTIES} className={({ isActive }) => (isActive || location.pathname.includes(navigationPath.PROPERTIES) || location.pathname.includes(navigationPath.ADD_PROPERTY)) ? 'active' : 'Menu 2'}>
          <li>
            Properties
          </li>
        </NavLink>
        <NavLink to={navigationPath.REPORTS} className={({ isActive }) => isActive ? 'active' : 'Menu 3'}>
          <li>
            Reports
          </li>
        </NavLink>
      </ul>
      <div className="search-box-container">
        <Search />
      </div>
      <div className="settings-container" ref={dropdownRef}>
        <img
          className="settings-icon"
          src={settingsIcon}
          alt="reis-settings"
          onClick={handleDropdownClick}
        />
        {isDropdownOpen && (
          <div className="settings-menu-container" id="settings-menu">
            <div className="maintenance-menu" onClick={navigateMaintenance}>
              <p>Maintenance</p>
            </div>
            <div className="logout" onClick={() => { logout() }}>
              <p>Logout</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubHeader
