import React, { useEffect, useState } from 'react'
import './ViewProperty.scss'
import TransactionInformation from '../../../components/viewProperty/transactionInformation/TransactionInformation'
import InventoryInformation from '../../../components/viewProperty/inventoryInformation/InventoryInformation'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../store/store'
import { getPropertyTransactionById } from '../../../store/middleWares/getPropertyById'
import { useParams } from 'react-router'
import InventoryReference from '../../../components/viewProperty/inventoryReference/InventoryReference'
import Button from '../../../components/sharedModules/button/Button'
import { updateProperty } from '../../../store/middleWares/updateProperty'
import { type IInventoryInformation, resetInventoryInfo } from '../../../store/slices/viewPropertySlice'
import { Link, useNavigate } from 'react-router-dom'
import ResponseModal from '../../../components/welcome/ResponseModal'
import scrollToTop from '../../../util/scrollToTop'
import { navigationPath } from '../../../types/CommonTypes'
import { getAllInventoryDataForPDF } from '../../../store/middleWares/getAllInventoryDataForPDF'
import { ReactComponent as CloseButton } from '../../../assets/close.svg'
import WarningModal from '../../../components/welcome/WarningModal'
import { PROPERTY_UPDATE_DELETE_ENUM } from '../../../Constants'
import { deleteProperty } from '../../../store/middleWares/deleteProperty'
import { restoreLastPageUrl } from '../../../util/restoreLastPageUrl'

const ViewProperty: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const { id } = useParams()
  const [viewPdf, setViewPdf] = useState<boolean>(false)
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [showDeleteWarning, setshowDeleteWarning] = useState<boolean>(false)
  const [showResponse, setResponseValue] = useState<boolean>(false)
  const [isFilled, setIsFilled] = useState({ propertyName: true, propId: true })

  const displayName = useSelector(
    (state: any) => state.viewProperty.displayName
  )

  const fieldData: IInventoryInformation = useSelector(
    (state: any) => {
      return state.viewProperty.inventoryInformation
    }
  )

  const pdfUrl = useSelector(
    (state: any) => state.viewProperty.inventoryPDFdata
  )

  const isDeletedSuccessfully: boolean | false = useSelector((state: any) => {
    return state.viewProperty.inventoryInformation.isDeletedSuccessfully
  })

  useEffect(() => {
    dispatch(getPropertyTransactionById(id))
    scrollToTop()
    return () => {
      dispatch(resetInventoryInfo())
    }
  }, [id, dispatch])

  const onSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const obj = {
      propertyName: !!fieldData.propertyName,
      propId: !!fieldData.propId
    }
    setIsFilled(obj)
    if (fieldData.propertyName && fieldData.propId) {
      dispatch(updateProperty(id))
      setIsEdited(false)
      setIsFilled({ propertyName: true, propId: true })
      setResponseValue(true)
    } else {
      scrollToTop()
    }
  }

  const onCancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(resetInventoryInfo())
    setResponseValue(false)
    navigate(restoreLastPageUrl('/properties'))
  }

  const handlePdfgeneration = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    dispatch(getAllInventoryDataForPDF(id))
    setViewPdf(true)
  }

  const handleCloseBtnclick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setViewPdf(false)
  }

  const onFormEdit = () => {
    setIsEdited(true)
  }

  const onDeleteHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    setshowDeleteWarning(true)
  }

  const onModalCancelHandler = () => {
    setshowDeleteWarning(false)
  }

  const onDeleteConfirm = () => {
    setshowDeleteWarning(false)
    dispatch(deleteProperty())
    setResponseValue(true)
  }

  const navigateFn = () => {
    if (isDeletedSuccessfully) navigate(navigationPath.PROPERTIES)
    scrollToTop()
  }

  return (
    <>
      { showResponse && <ResponseModal navigateFn={navigateFn}/>}
      {showDeleteWarning && <WarningModal warningLabel='Delete Property' modalAction='Delete' view={showDeleteWarning} txnOpertionEnum={PROPERTY_UPDATE_DELETE_ENUM.DELETE}
        cancelFn={onModalCancelHandler} confirmFn={onDeleteConfirm} />}
      <form className="form-container" autoSave='off'>
        <div className="form-heading">
          <h2>Properties - {displayName} Detail</h2>
        </div>
        <div className="form-container">
          <InventoryInformation onFormChange={onFormEdit} isFilled={isFilled} setIsFilled={setIsFilled}/>
          <InventoryReference />
          <TransactionInformation />

        </div>
        <div className="add-transaction-footer">
          <div className='dlt-btn-container'>
            <span>
              <Button onClick={onCancelHandler} type="cancel">
                Cancel
              </Button>
            </span>
            <span className='dlt-property-btn'>
              <Button
                onClick={onDeleteHandler}
                type="confirm">
                Delete
              </Button>
            </span>
          </div>
          <span className="add-transaction-button-group">
            <Link to={navigationPath.ADD_TRANSACTION}>
              <Button
                className="route-button"
                type="confirm"
              >
                Add Transaction
              </Button>
            </Link>
            <Button
              onClick={onSave}
              className={isEdited ? 'save-create-pdf-button' : 'print-button'}
              type="confirm"
            >
              Save
            </Button>
            <Button
              onClick={handlePdfgeneration}
              className="save-create-pdf-button"
              type="confirm"
            >
              Create .pdf
            </Button>
          </span>
        </div>
      </form>
      {viewPdf && (
        <>
          <div className="modal-wrapper">
            <div className="close-btn" onClick={handleCloseBtnclick}>
              <CloseButton className="close-svg" />
            </div>
            <div className={'modal-container transaction-pdf-modal'}>
              {<iframe src={pdfUrl} style={{ width: '100%', height: '100vh' }} className='pdf-modal'></iframe>}
            </div>
          </div>
        </>)}
    </>

  )
}

export default ViewProperty
