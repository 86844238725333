import { ERROR_TEXTS } from '../Constants'
import { type IPropertyInventory, type InventoryAccountingDataType } from '../store/slices/viewPropertyInventorySlice'
import { type IErrorData } from '../types/TransactionTypes'

const inventoryDataValidation = (
  inventoryData: InventoryAccountingDataType[],
  isSaveButtonSubmitted: boolean
) => {
  const errData: IErrorData = {
    isError: false,
    errorDesc: {},
    propertyDataError: [],
    defaultErr: ''
  }
  const pDataError: any[] = []
  if (inventoryData && isSaveButtonSubmitted) {
    inventoryData.forEach((value, index) => {
      if (value) {
        if (!value.xferDate) {
          errData.errorDesc.xferDate = ERROR_TEXTS.XFER_DATE_IS_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            xferDate: ERROR_TEXTS.REQUIRED_LABEL
          }
        }

        if (!value.workOrderNumber) {
          errData.errorDesc.wo = ERROR_TEXTS.WO_IS_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            workOrderNumber: ERROR_TEXTS.REQUIRED_LABEL
          }
        }
        if (!value.plantLoc) {
          errData.errorDesc.plantLoc = ERROR_TEXTS.PLANT_LOC_IS_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            plantLoc: ERROR_TEXTS.REQUIRED_LABEL
          }
        }
        if (!value.glAccount) {
          errData.errorDesc.glAccount = ERROR_TEXTS.GL_ACCT_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            glAccount: ERROR_TEXTS.REQUIRED_LABEL
          }
        }
        if (!value.ferc || value.ferc === ' ') {
          errData.errorDesc.ferc = ERROR_TEXTS.FERC_ACCT_IS_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            ferc: ERROR_TEXTS.REQUIRED_LABEL
          }
        }
        if (value.acres === null || (value.acres as any as string) === '') {
          errData.errorDesc.acres = ERROR_TEXTS.ACRES_IS_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            acres: ERROR_TEXTS.REQUIRED_LABEL
          }
        }
        /*
        if (!value.amount) {
          errData.errorDesc.amount = ERROR_TEXTS.AMOUNT_IS_REQUIRED
          pDataError[index] = {
            ...pDataError[index],
            amount: ERROR_TEXTS.AMOUNT_IS_REQUIRED
          }
        }
        */
      }
    })
    errData.propertyDataError = pDataError
  }
  return errData
}

const onSaveInventoryValidation = (
  inventoryData: IPropertyInventory,
  isSaveButtonSubmitted: boolean
) => {
  const { inventoryAccountingData } = inventoryData
  const newInventory = inventoryAccountingData.reduce((result: any, inventory, index) => {
    result[index] = inventory.propAcctId ? null : inventory
    return result
  }, [])
  const inventoryError: IErrorData =
    inventoryDataValidation(newInventory, isSaveButtonSubmitted)

  if (
    Object.values(inventoryError.errorDesc).length > 0 ||
    inventoryError.propertyDataError.length > 0
  ) {
    inventoryError.isError = true
    inventoryError.defaultErr = ERROR_TEXTS.DEFAULT_ERROR
  }
  return inventoryError
}

export default onSaveInventoryValidation
