import { RESULT_INITAL_PAGE, RESULT_PER_PAGE_PAGINATION } from '../Constants'
import { SearchOptionsEnum } from '../components/sharedModules/search/SearchOptions'
import { navigationPath } from '../types/CommonTypes'
import { useNavigate } from 'react-router-dom'

const useHandleNavigationAfterSearch = (searchType: SearchOptionsEnum): (id: string, value: string) => void => {
  const navigate = useNavigate()
  let url: string | null = null

  const navigateTo = (id: string, value: string) => {
    switch (searchType) {
      case SearchOptionsEnum.RECORD_NUMBER:
        url = `${navigationPath.VIEW_TRANSACTION}/${id}`
        break

      case SearchOptionsEnum.PROPERTY_ID:
      case SearchOptionsEnum.PROPERTY_NAME:
      case SearchOptionsEnum.PROPERTY_NUMBER:
      // case SearchOptionsEnum.ACQDOC_NUMBER:
        url = `${navigationPath.PROPERTIES}/${id}`
        break
      case SearchOptionsEnum.ACQDOC_NUMBER:
        url = `${navigationPath.PROPERTIES}?column=acqDocNo&value=${value}&page=${RESULT_INITAL_PAGE}&limit=${RESULT_PER_PAGE_PAGINATION}`
        break

      case SearchOptionsEnum.APN:
        url = `${navigationPath.VIEW_TRANSACTION}?column=apn&value=${value}&page=${RESULT_INITAL_PAGE}&limit=${RESULT_PER_PAGE_PAGINATION}`
        break

      case SearchOptionsEnum.CITY:
        url = `${navigationPath.PROPERTIES}?column=city&value=${value}&page=${RESULT_INITAL_PAGE}&limit=${RESULT_PER_PAGE_PAGINATION}`
        break

      case SearchOptionsEnum.COUNTY:
        url = `${navigationPath.PROPERTIES}?column=county&value=${value}&page=${RESULT_INITAL_PAGE}&limit=${RESULT_PER_PAGE_PAGINATION}`
        break

      case SearchOptionsEnum.WORKORDER_NUMBER:
        url = `${navigationPath.VIEW_TRANSACTION}?column=woNo&value=${value}&page=${RESULT_INITAL_PAGE}&limit=${RESULT_PER_PAGE_PAGINATION}`
        break

      case SearchOptionsEnum.SERIAL_NUMBER:
        url = `${navigationPath.VIEW_TRANSACTION}/${id}`
        break

      default:
        break
    }
    if (!url) return
    navigate(url)
  }

  return navigateTo
}

export default useHandleNavigationAfterSearch
