/* eslint-disable no-useless-escape */
// export const setDecimalPrecision = (value: any, decimalPoints?: number): any => {
//   let result: any = value

import { MONEY } from '../Constants'

//   if (value || Number(value) === 0) {
//     if (!decimalPoints) decimalPoints = 2
//     result = (+(Math.round(+(Number(value) + 'e' + decimalPoints)) + 'e' + -decimalPoints)).toFixed(decimalPoints)
//     const isNegative = Math.sign(Number(result)) === -1
//     const removeMinus = result.replace('-', '')
//     result = numberWithCommas(removeMinus)
//     result = isNegative ? `(${result})` : `${result}`
//   }
//   return result
// }

export const updatedToFixed = (value: string | undefined | number | null, decimalPoints: number) => {
  // const result = (+(Math.round(+(Number(value) + 'e' + decimalPoints)) + 'e' + -decimalPoints)).toFixed(decimalPoints)

  // eslint-disable-next-line eqeqeq
  if ((typeof value === 'number' && value == 0) || value == '0') {
    return '0.' + '0'.repeat(decimalPoints)
  }
  if (!value) {
    return '0.' + '0'.repeat(decimalPoints)
  }
  if (typeof value === 'string') {
    return value
  }
  if (value.toFixed(decimalPoints) === '-0.00' || value.toFixed(decimalPoints) === '-0.000') {
    return value.toFixed(decimalPoints).replace('-', '')
  }
  const inputAsNumber = Number(value)
  let result
  if (decimalPoints === 2) {
    const thrdPositionValue = Math.floor(Math.abs(inputAsNumber) * 1000) % 10
    result =
      thrdPositionValue >= 5
        ? (Math.ceil(Math.abs(inputAsNumber) * 100) / 100 * Math.sign(inputAsNumber)).toFixed(decimalPoints)
        : inputAsNumber.toFixed(decimalPoints)
  }

  if (decimalPoints === 3) {
    const thrdPositionValue = Math.floor(Math.abs(inputAsNumber) * 10000) % 10
    result =
      thrdPositionValue >= 5
        ? (Math.ceil(Math.abs(inputAsNumber) * 1000) / 1000 * Math.sign(inputAsNumber)).toFixed(decimalPoints)
        : inputAsNumber.toFixed(decimalPoints)
  }
  return result
}

export const setDecimalPrecision = (value: any, decimalPoints?: number, type?: string): any => {
  let result = value
  decimalPoints = decimalPoints ?? 2
  if (value || Number(value) === 0) {
    const formattedValue = value ? Number(String(value).replaceAll(',', '')) : value
    const toFixedValue = updatedToFixed(formattedValue, decimalPoints)

    const isNegative = Math.sign(Number(toFixedValue)) === -1
    const removeMinus = toFixedValue ? toFixedValue.replace('-', '') : '0'.repeat(decimalPoints)
    result = numberWithCommas(removeMinus)
    if (type === MONEY) {
      result = isNegative ? `($${result})` : `$${result}`
    } else { result = isNegative ? `(${result})` : `${result}` }
  }
  return result
}

export const formatNegNumbersWithBraces = (value: any) => {
  let result = value

  if (value || Number(value) === 0) {
    // result = Number(value).toFixed(9)
    const isNegative = Math.sign(Number(result)) === -1
    const removeMinus = String(result).replace('-', '')
    const numWithComma = numberWithCommasForAcres(removeMinus)
    result = isNegative ? `(${numWithComma})` : `${numWithComma}`
  }
  return result
}

const regexForRemoveComma = /\B(?=(\d{3})+(?!\d))/g
export const numberWithCommas = (x: any) => {
  return x.toString().replace(regexForRemoveComma, ',')
}

export const numberWithCommasForAcres = (x: any) => {
  const parts = x.toString().split('.')
  return parts[0].replace(regexForRemoveComma, ',') + (parts.length > 1 ? ('.' + parts[1]) : '')
}

export const stringToNumber = (x: any) => {
  if (typeof x === 'string') {
    return Number(x.replace(/[^0-9\-\.]+/g, ''))
  } else if (typeof x !== 'number') {
    return Number(x)
  }
  return x
}
