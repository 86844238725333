import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { ADD_PROPERTY_ENDPOINT, UNAUTHORISED_MESSAGE } from '../../Constants'
import { type IPropertyView } from '../slices/viewPropertySlice'

const getPropertyState = (state: any) => state.addProperty

export const addProperty = createAsyncThunk('properties/add-property', async (_, { getState }) => {
  try {
    const propertyData: IPropertyView = getPropertyState(getState())
    const responseData = await api.post(`/${ADD_PROPERTY_ENDPOINT}`,
      {
        inventoryInformation: propertyData.inventoryInformation

      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    return responseData.data
  } catch (error: any) {
    const { statusCode } = error.response.data
    if (statusCode === 401) {
      throw new Error(UNAUTHORISED_MESSAGE)
    } else {
      throw error.response.data
    }
  }
})
