/* eslint-disable no-case-declarations */
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import './Select.scss'
import { ERROR_TEXTS } from '../../../Constants'
import { useSelector } from 'react-redux'
import { StatusTypesEnum } from '../../../types/CommonTypes'

interface ISelectprops {
  name: string
  data: () => { id: string[], options: any[] }
  onChange: (selectedItem: string, id?: string) => void
  triggerOnFocus?: (value: string) => void
  ignoreValidation?: boolean
  className?: string
  value?: any
  errorDesc?: string
  readonly?: boolean
  placeholder?: string
  listClassName?: string
}

const Select = (props: ISelectprops): ReactElement => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false)
  const dropdownThreshold = useSelector((state: any) => state.dropDown.listThreshold)
  const status: StatusTypesEnum = useSelector((state: any) => state.dropDown.status)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const statusRef = useRef<StatusTypesEnum | undefined>(undefined)
  const optionsRef = useRef<string[]>([])
  const { id, options } = props.data()
  let selectedIndex = -1
  function myFunction (event: any) {
    if (props.readonly) return
    const children = Array.from(
      dropdownRef.current != null ? dropdownRef.current.children : []
    )

    const selectOption = (index: number) => {
      children[selectedIndex]?.classList.remove('dropbtn-selected')
      selectedIndex = index
      children[selectedIndex]?.classList.add('dropbtn-selected')
    }

    function scrollToSelectedElement (container: any) {
      if (!container) return
      const selectedElement: any = children[selectedIndex]
      const containerHeight = container.offsetHeight
      const selectedTop: number = selectedElement.offsetTop
      const selectedHeight = selectedElement.offsetHeight
      const scrollBottom: number = container.scrollTop + containerHeight
      if (selectedTop < container.scrollTop) {
        container.scrollTop = selectedTop
      } else if (selectedTop + selectedHeight > scrollBottom) {
        container.scrollTop = selectedTop + selectedHeight - containerHeight
      }
    }
    const container = dropdownRef.current

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        selectOption((selectedIndex + 1) % children.length)
        scrollToSelectedElement(container)
        break
      case 'ArrowUp':
        event.preventDefault()
        selectOption((selectedIndex - 1 + children.length) % children.length)
        scrollToSelectedElement(container)
        break
      case 'Enter':
        event.preventDefault()
        if (selectedIndex < 0 && children.length) {
          const selectedOption = children[0].innerHTML.trim()
          const selectedOptionId =
            children[0].getAttribute('data-id') ?? ''
          props.onChange(selectedOption, selectedOptionId)
          setIsListOpen(false)
        } else if (selectedIndex >= 0) {
          const selectedOption = children[selectedIndex].innerHTML.trim()
          const selectedOptionId =
            children[selectedIndex].getAttribute('data-id') ?? ''
          // Note: includes doesn't work for number array: if (!options.includes(selectedOption + '')) return
          let result = false
          for (let oi = 0; oi < options.length; oi++) {
            if (options[oi] + '' === selectedOption + '') {
              result = true
              break
            }
          }
          if (!result) return
          props.onChange(selectedOption, selectedOptionId)
          setIsListOpen(false)
        }
        break
      case 'Tab':
        const index = optionsRef.current.findIndex((item) => typeof item === 'string' && item.toLowerCase() === inputRef.current?.value.toLowerCase())
        const isIncludes = optionsRef.current.includes(inputRef.current?.value ?? '')
        if (!isIncludes && index !== -1) {
          props.onChange(optionsRef.current[index])
          setIsListOpen(false)
          break
        }
        if (selectedIndex >= 0 && index === -1) {
          const selectedOption = children[selectedIndex].innerHTML.trim()
          const selectedOptionId =
            children[selectedIndex].getAttribute('data-id') ?? ''
          if (!options.includes(selectedOption)) return
          props.onChange(selectedOption, selectedOptionId)
          setIsListOpen(false)
          break
        }
        if (!props.ignoreValidation) {
          if (inputRef.current && !isIncludes && status !== StatusTypesEnum.LOADING) props.onChange('', '')
          setIsListOpen(false)
          break
        }
        break
    }
  }

  function catchMissedFilter () {
    if (!inputRef.current || !props.triggerOnFocus) return
    if (optionsRef.current.length > 0) {
      if (inputRef.current.value === '' && optionsRef.current.length === dropdownThreshold) {
        return
      }
      // eslint-disable-next-line eqeqeq
      if (optionsRef.current.filter((item: string) => item === inputRef.current?.value).length === optionsRef.current.length) {
        return
      }
      props.triggerOnFocus(inputRef.current.value)
    }
  }

  useEffect(() => {
    optionsRef.current = options
  }, [options])

  useEffect(() => {
    statusRef.current = status
  }, [status])

  useEffect(() => {
    const handleclickOutside = (event: MouseEvent) => {
      if (props.readonly) {
        if (isListOpen) setIsListOpen(false)
      }
      if (
        dropdownRef.current != null &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current != null &&
        !inputRef.current.contains(event.target as Node)
      ) {
        if (!props.ignoreValidation) {
          if (inputRef.current.value && !optionsRef.current.includes(inputRef.current.value) && statusRef.current !== StatusTypesEnum.LOADING) {
            props.onChange('', '')
          }
        }
        const index = optionsRef.current.findIndex((item) => typeof item === 'string' && item.toLowerCase() === inputRef.current?.value.toLowerCase())
        if (!optionsRef.current.includes(inputRef.current.value) && index !== -1) {
          props.onChange(optionsRef.current[index])
        }
        setIsListOpen(false)
      }
    }
    document.addEventListener('mousedown', handleclickOutside)
    return () => {
      document.removeEventListener('mousedown', handleclickOutside)
    }
  }, [])

  return (
    <>
      <input
        key={props.name}
        name={props.name}
        className={`input-select-box select-dropdown ${props.className ? props.className : ''
          }`}
        ref={inputRef}
        value={props.value ? props.value : ''}
        placeholder={props.placeholder ?? 'Select an option'}
        onChange={(e) => {
          if (props.readonly) return
          if (!isListOpen) setIsListOpen(true)
          props.onChange(
            e.target.value,
            e.target.getAttribute('data-id') ?? ''
          )
        }}
        onFocus={(e) => {
          if (props.readonly) return
          catchMissedFilter()
          setIsListOpen(true)
        }}
        onMouseDown={(e) => {
          if (props.readonly) return
          setIsListOpen(true)
        }}
        onKeyDown={myFunction}
        readOnly={props.readonly}
        autoComplete="off"
        list="autocompleteOff"
        aria-autocomplete="none"
      />
      {props.errorDesc &&
        <p className="error-message">
          {props.errorDesc ?? ERROR_TEXTS.REQUIRED_LABEL}
        </p>
      }

      {isListOpen && (
        <ul
          className={`dropdown-result-list ${props.listClassName ?? ''}`}
          ref={dropdownRef}
        >
          {options.map((option: string, index: number) => (
            <li
              key={index}
              data-id={id[index]}
              onClick={() => {
                props.onChange(option, id[index])
                setIsListOpen(false)
              }}
            >
              {' '}
              {option}{' '}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default Select
