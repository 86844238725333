/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_PROPERTY_INVENTORY_DATA, UNAUTHORISED_MESSAGE } from '../../Constants'

export const updateInventory = createAsyncThunk(
  'property/inventory/id',
  async (id: any, thunkAPI): Promise<any> => {
    try {
      const state: any = thunkAPI.getState()
      const inventoryAccountingData =
        state.viewPropertyInventory.inventoryAccountingData
      const comment = state.viewPropertyInventory.comments
      const prevComment =
        state.viewPropertyInventory.inventoryInfo.prevComments
      const responseData = await api
        .patch(`/${GET_PROPERTY_INVENTORY_DATA}/${id}`, {
          body: {
            inventoryAccountingData,
            comment,
            prevComment
          }
        })
      return responseData?.data
    } catch (error: any) {
      const { statusCode } = error.response.data
      if (statusCode === 401) {
        return thunkAPI.rejectWithValue({ statusCode: 401, message: UNAUTHORISED_MESSAGE })
      } else return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)
