import { type PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getPropertyTransactionById } from '../middleWares/getPropertyById'
import { getAllInventoryDataForPDF } from '../middleWares/getAllInventoryDataForPDF'
import { updateProperty } from '../middleWares/updateProperty'
import { type SORT_DIRECTION, StatusTypesEnum } from '../../types/CommonTypes'
import { arraySort } from '../../util/arraySort'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { DEFAULT_INVENTORY_REF_COLUMN, DEFAULT_SORTING_ORDER, DEFAULT_TRANSACTION_INFO_COOLUMN, INITIAL_SORTING_ORDER } from '../../Constants'
import { deleteProperty } from '../middleWares/deleteProperty'

export interface TransactionInfoType {
  recordNo: number
  propertyName: string
  transactionType: string
  serialNumber: string
  documentNo: string
  transactionStatus: string
  workOrderNumber: string
  jobOrder: string
  preparedBy: string
}

export interface IInventoryRef {
  acqDocNo: number
  parcelNo: number
  ownership: string
  totalAmount: string
  totalAcres: string
  mapSize: string
  propertyCounty: string
  propertyState: string
  serialNumber: string
  drawing: number
}

export interface IInventoryInformation {
  propNo: string
  propertyName: string
  propId: string
  propertyAddress: string
  propertyCounty: string
  propertyCity: string
  propertyType: string
  thomasPage: string
  thomasGrid: string
  iso: number
  propertyState: string
  totalAcres: string | number
  totalAmount?: string | number
  zipCode: string
  totalBookValue: string | number
  previousComments?: string
  comments?: string
  isDeletedSuccessfully: boolean
}

export type InventoryRefSortOrderObjectType = {
  [k in keyof IInventoryRef]: SORT_DIRECTION
}

export type TransactionInfoSortOrderObjectType = {
  [k in keyof TransactionInfoType]: SORT_DIRECTION
}

const IInventoryRefSortOrderObjectInitalData: InventoryRefSortOrderObjectType = {
  acqDocNo: DEFAULT_SORTING_ORDER,
  parcelNo: DEFAULT_SORTING_ORDER,
  ownership: DEFAULT_SORTING_ORDER,
  totalAmount: DEFAULT_SORTING_ORDER,
  totalAcres: DEFAULT_SORTING_ORDER,
  mapSize: DEFAULT_SORTING_ORDER,
  propertyCounty: DEFAULT_SORTING_ORDER,
  propertyState: DEFAULT_SORTING_ORDER,
  serialNumber: DEFAULT_SORTING_ORDER,
  drawing: DEFAULT_SORTING_ORDER
}

const TransactionInfoSortOrderObjectInitalData: TransactionInfoSortOrderObjectType = {
  recordNo: DEFAULT_SORTING_ORDER,
  propertyName: DEFAULT_SORTING_ORDER,
  transactionType: DEFAULT_SORTING_ORDER,
  serialNumber: DEFAULT_SORTING_ORDER,
  documentNo: DEFAULT_SORTING_ORDER,
  transactionStatus: DEFAULT_SORTING_ORDER,
  workOrderNumber: DEFAULT_SORTING_ORDER,
  jobOrder: DEFAULT_SORTING_ORDER,
  preparedBy: DEFAULT_SORTING_ORDER
}

export interface IPropertyView {
  displayName: string
  transactionInformation: TransactionInfoType[]
  inventoryInformation: IInventoryInformation | Record<string, unknown>
  comments: string
  inventoryReferenceList: IInventoryRef[]
  status: IStatusResponse
  transactionInfoSortingOrder: TransactionInfoSortOrderObjectType
  inventoryRefSortingOrder: InventoryRefSortOrderObjectType
  inventoryPDFdata: string
}

export const inventoryInformationInitialData: IInventoryInformation = {
  propNo: '',
  propertyName: '',
  propId: '',
  propertyAddress: '',
  propertyCounty: '',
  propertyCity: '',
  propertyType: '',
  thomasPage: '',
  thomasGrid: '',
  iso: 0,
  propertyState: '',
  totalAcres: '',
  totalAmount: '',
  zipCode: '',
  totalBookValue: '',
  previousComments: '',
  isDeletedSuccessfully: false

}

export const viewPropertyInitialData: IPropertyView = {
  displayName: '',
  transactionInformation: [],
  status: { type: undefined, message: '' },
  transactionInfoSortingOrder: TransactionInfoSortOrderObjectInitalData,
  inventoryReferenceList: [],
  comments: '',
  inventoryInformation: inventoryInformationInitialData,
  inventoryRefSortingOrder: IInventoryRefSortOrderObjectInitalData,
  inventoryPDFdata: ''
}

const viewPropertySlice = createSlice({
  name: 'viewProperty',
  initialState: viewPropertyInitialData,
  reducers: {
    setSortedTransactionInfo (state, action: PayloadAction<TransactionInfoSortOrderObjectType>) {
      const [[columnName, sortOrder]] = Object.entries(action.payload)
      const { sortedData, sortOption } = arraySort(sortOrder, state.transactionInformation, columnName)
      const updatedSort = { ...TransactionInfoSortOrderObjectInitalData, [columnName]: sortOption }
      state.transactionInformation = sortedData
      state.transactionInfoSortingOrder = updatedSort
    },

    setSortedInventoryInfo (state, action: PayloadAction<InventoryRefSortOrderObjectType>) {
      const [[columnName, sortOrder]] = Object.entries(action.payload)
      const { sortedData, sortOption } = arraySort(sortOrder, state.inventoryReferenceList, columnName)
      const updatedSort = { ...IInventoryRefSortOrderObjectInitalData, [columnName]: sortOption }
      state.inventoryReferenceList = sortedData
      state.inventoryRefSortingOrder = updatedSort
    },

    setComments (state, action) {
      state.comments = action.payload
    },

    setISO (state, action) {
      state.inventoryInformation = { ...state.inventoryInformation, iso: action.payload ? 1 : 0 }
    },
    resetInventoryInfo (state) {
      state.transactionInformation = []
      state.status = { type: undefined, message: '' }
      state.inventoryRefSortingOrder = IInventoryRefSortOrderObjectInitalData
      state.inventoryReferenceList = []
      state.comments = ''
      state.inventoryInformation = inventoryInformationInitialData
      state.transactionInfoSortingOrder = TransactionInfoSortOrderObjectInitalData
    },
    resetViewPropertyStatus (state) {
      state.status = { type: undefined, message: '' }
    },
    updateViewPropertyInfo (state, action: PayloadAction<any>) {
      const updatedData = { ...state.inventoryInformation, ...action.payload }
      state.inventoryInformation = updatedData
    },

    updatePropertyInfoDropdowns (state, action: PayloadAction<any>) {
      const updatedData = { ...state.inventoryInformation, ...action.payload }
      state.inventoryInformation = updatedData
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPropertyTransactionById.fulfilled, (state, action) => {
      if (action.payload.inventoryInfo) {
        state.inventoryInformation = action.payload.inventoryInfo
        state.displayName = action.payload.inventoryInfo.propertyName
      }
      state.inventoryRefSortingOrder = IInventoryRefSortOrderObjectInitalData
      if (action.payload.inventoryRef) {
        const { sortedData } = arraySort(INITIAL_SORTING_ORDER, action.payload.inventoryRef, DEFAULT_INVENTORY_REF_COLUMN)
        state.inventoryReferenceList = sortedData
      }
      if (action.payload.transactionInfo) {
        const { sortedData } = arraySort(INITIAL_SORTING_ORDER, action.payload.transactionInfo, DEFAULT_TRANSACTION_INFO_COOLUMN)
        state.transactionInformation = sortedData
      }
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
    })

    builder.addCase(getAllInventoryDataForPDF.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.inventoryPDFdata = action.payload
    })

    builder.addCase(getAllInventoryDataForPDF.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })

    builder.addCase(deleteProperty.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.inventoryInformation.isDeletedSuccessfully = true
    })

    builder.addCase(deleteProperty.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
      state.inventoryInformation.isDeletedSuccessfully = false
    })

    builder.addCase(deleteProperty.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })

    // TODO: Error handling
    builder.addCase(getPropertyTransactionById.rejected, (state, action) => {
      const { error } = action
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = error.message
    })

    builder.addCase(updateProperty.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.comments = ''
      state.inventoryInformation = action.payload.inventoryInfo
      state.displayName = action.payload.inventoryInfo.propertyName
    })

    builder.addCase(updateProperty.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
    })

    builder.addCase(updateProperty.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })

    builder.addMatcher(isAnyOf(getPropertyTransactionById.pending,
      updateProperty.pending), (state) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = viewPropertyInitialData.status.message
    })
  }
})

export const {
  setSortedTransactionInfo
  , setSortedInventoryInfo
  , setComments
  , setISO
  , resetInventoryInfo
  , resetViewPropertyStatus,
  updateViewPropertyInfo,
  updatePropertyInfoDropdowns
} = viewPropertySlice.actions
export default viewPropertySlice.reducer
