import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DropDownFiledsEnum, StatusTypesEnum } from '../../types/CommonTypes'
import { getDropdownFilteredValues, getDropdownThreshold, getDropdownValues, getParcelNoDropdownValue } from '../middleWares/getDropdownValues'
import { setDecimalPrecision } from '../../util/setDecimalPrecision'
import { MONEY } from '../../Constants'

export interface IAcqDocData {
  refId: number
  acqDocNo: string
  parcelNumber: string
  serialNumber: string
  drawing: string
  totalAcres: string
  totalAmount: string
}
export interface IDropdown {
  agent: any[]
  transactionStatus: any[]
  property: any[]
  instrumentTitle: any[]
  documentType: any[]
  transactionDocType: any[]
  transactionType: any[]
  county: any[]
  organization: any[]
  titleCompany: any[]
  escrowCompany: any[]
  plantLocation: any[]
  fercAcct: any[]
  glAcct: any[]
  ownership: any[]
  acqDocNo: IAcqDocData[]
  parcelNumber: any[]
  city: any[]
  state: any[]
  propertyType: any[]
  listThreshold: number | null
  status: StatusTypesEnum | undefined
}

export const dropDownInitialData: IDropdown = {
  agent: [],
  transactionStatus: [],
  property: [],
  instrumentTitle: [],
  documentType: [],
  transactionDocType: [],
  transactionType: [],
  county: [],
  organization: [],
  titleCompany: [],
  escrowCompany: [],
  plantLocation: [],
  fercAcct: [],
  glAcct: [],
  ownership: [],
  acqDocNo: [],
  parcelNumber: [],
  city: [],
  state: [],
  propertyType: [],
  listThreshold: null,
  status: undefined
}

const dropdDownSlice = createSlice({
  name: 'dropDown',
  initialState: dropDownInitialData,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getDropdownValues.fulfilled, (state, action) => {
      const { data, fields } = action.payload
      for (const field of fields) {
        switch (field) {
          case DropDownFiledsEnum.AGENT:
            state.agent = data[DropDownFiledsEnum.AGENT]
            break
          case DropDownFiledsEnum.COUNTY:
            state.county = data[DropDownFiledsEnum.COUNTY]
            break
          case DropDownFiledsEnum.DOCUMENT_TYPE:
            state.documentType = data[DropDownFiledsEnum.DOCUMENT_TYPE]
            break
          case DropDownFiledsEnum.TRANSACTION_DOC_TYPE:
            state.transactionDocType = data[DropDownFiledsEnum.TRANSACTION_DOC_TYPE]
            break
          case DropDownFiledsEnum.ESCROW_COMPANY:
            state.escrowCompany = data[DropDownFiledsEnum.ESCROW_COMPANY]
            break
          case DropDownFiledsEnum.FERC_ACCT:
            state.fercAcct = data[DropDownFiledsEnum.FERC_ACCT]
            break
          case DropDownFiledsEnum.GL_ACCT:
            state.glAcct = data[DropDownFiledsEnum.GL_ACCT]
            break
          case DropDownFiledsEnum.INSTRUMENT_TITLE:
            state.instrumentTitle = data[DropDownFiledsEnum.INSTRUMENT_TITLE]
            break
          case DropDownFiledsEnum.ORGANIZATION:
            state.organization = data[DropDownFiledsEnum.ORGANIZATION]
            break
          case DropDownFiledsEnum.PLANT_LOCATION:
            state.plantLocation = data[DropDownFiledsEnum.PLANT_LOCATION]
            break
          case DropDownFiledsEnum.PROPERTY:
            state.property = data[DropDownFiledsEnum.PROPERTY]
            break
          case DropDownFiledsEnum.TITLE_COMPANY:
            state.titleCompany = data[DropDownFiledsEnum.TITLE_COMPANY]
            break
          case DropDownFiledsEnum.TRANSACTION_STATUS:
            state.transactionStatus = data[DropDownFiledsEnum.TRANSACTION_STATUS]
            break
          case DropDownFiledsEnum.TRANSACTION_TYPE:
            state.transactionType = data[DropDownFiledsEnum.TRANSACTION_TYPE]
            break
          case DropDownFiledsEnum.OWNERSHIP:
            state.ownership = data[DropDownFiledsEnum.OWNERSHIP]
            break
          case DropDownFiledsEnum.ACQ_DOC_NO:
            state.acqDocNo = data[DropDownFiledsEnum.ACQ_DOC_NO]?.map((item: any) => {
              const { id, name } = item
              return {
                id,
                name: { ...name, totalAcres: setDecimalPrecision(name.totalAcres, 3), totalAmount: setDecimalPrecision(name.totalAmount, 2, MONEY) }
              }
            })
            break
          case DropDownFiledsEnum.PARCEL_NUMBER:
            state.parcelNumber = data[DropDownFiledsEnum.PARCEL_NUMBER]
            break
          case DropDownFiledsEnum.CITY:
            state.city = data[DropDownFiledsEnum.CITY]
            break
          case DropDownFiledsEnum.STATE:
            state.state = data[DropDownFiledsEnum.STATE]
            break

          case DropDownFiledsEnum.PROPERTY_TYPE:
            state.propertyType = data[DropDownFiledsEnum.PROPERTY_TYPE]
            break
          default:
            break
        }
      }
    })
    builder.addCase(getDropdownFilteredValues.pending, (state: IDropdown, action: PayloadAction<any>) => {
      state.status = StatusTypesEnum.LOADING
    })
    builder.addCase(getDropdownFilteredValues.rejected, (state: IDropdown, action: PayloadAction<any>) => {
      state.status = StatusTypesEnum.ERROR
    })
    builder.addCase(getParcelNoDropdownValue.pending, (state: IDropdown, action: PayloadAction<any>) => {
      state.status = StatusTypesEnum.LOADING
    })
    builder.addCase(getParcelNoDropdownValue.rejected, (state: IDropdown, action: PayloadAction<any>) => {
      state.status = StatusTypesEnum.ERROR
    })
    builder.addCase(getParcelNoDropdownValue.fulfilled, (state: IDropdown, action: PayloadAction<any>) => {
      state.parcelNumber = action.payload.data
    })
    builder.addCase(getDropdownFilteredValues.fulfilled, (state: IDropdown, action: PayloadAction<any>) => {
      state.status = StatusTypesEnum.SUCCESS
      const data = action.payload ?? []
      for (const field of Object.keys(data)) {
        switch (field) {
          case DropDownFiledsEnum.AGENT:
            state.agent = data[DropDownFiledsEnum.AGENT]
            break
          case DropDownFiledsEnum.COUNTY:
            state.county = data[DropDownFiledsEnum.COUNTY]
            break
          case DropDownFiledsEnum.DOCUMENT_TYPE:
            state.documentType = data[DropDownFiledsEnum.DOCUMENT_TYPE]
            break
          case DropDownFiledsEnum.TRANSACTION_DOC_TYPE:
            state.transactionDocType = data[DropDownFiledsEnum.TRANSACTION_DOC_TYPE]
            break
          case DropDownFiledsEnum.ESCROW_COMPANY:
            state.escrowCompany = data[DropDownFiledsEnum.ESCROW_COMPANY]
            break
          case DropDownFiledsEnum.FERC_ACCT:
            state.fercAcct = data[DropDownFiledsEnum.FERC_ACCT]
            break
          case DropDownFiledsEnum.GL_ACCT:
            state.glAcct = data[DropDownFiledsEnum.GL_ACCT]
            break
          case DropDownFiledsEnum.INSTRUMENT_TITLE:
            state.instrumentTitle = data[DropDownFiledsEnum.INSTRUMENT_TITLE]
            break
          case DropDownFiledsEnum.ORGANIZATION:
            state.organization = data[DropDownFiledsEnum.ORGANIZATION]
            break
          case DropDownFiledsEnum.PLANT_LOCATION:
            state.plantLocation = data[DropDownFiledsEnum.PLANT_LOCATION]
            break
          case DropDownFiledsEnum.PROPERTY:
            state.property = data[DropDownFiledsEnum.PROPERTY]
            break
          case DropDownFiledsEnum.TITLE_COMPANY:
            state.titleCompany = data[DropDownFiledsEnum.TITLE_COMPANY]
            break
          case DropDownFiledsEnum.TRANSACTION_STATUS:
            state.transactionStatus = data[DropDownFiledsEnum.TRANSACTION_STATUS]
            break
          case DropDownFiledsEnum.TRANSACTION_TYPE:
            state.transactionType = data[DropDownFiledsEnum.TRANSACTION_TYPE]
            break
          case DropDownFiledsEnum.OWNERSHIP:
            state.ownership = data[DropDownFiledsEnum.OWNERSHIP]
            break
          case DropDownFiledsEnum.ACQ_DOC_NO:
            state.acqDocNo = data[DropDownFiledsEnum.ACQ_DOC_NO]?.map((item: any) => {
              const { id, name } = item
              return {
                id,
                name: { ...name, totalAcres: setDecimalPrecision(name.totalAcres, 3), totalAmount: setDecimalPrecision(name.totalAmount, 2, MONEY) }
              }
            })

            break
          case DropDownFiledsEnum.PARCEL_NUMBER:
            state.parcelNumber = data[DropDownFiledsEnum.PARCEL_NUMBER]
            break
          case DropDownFiledsEnum.CITY:
            state.city = data[DropDownFiledsEnum.CITY]
            break
          case DropDownFiledsEnum.STATE:
            state.state = data[DropDownFiledsEnum.STATE]
            break

          case DropDownFiledsEnum.PROPERTY_TYPE:
            state.propertyType = data[DropDownFiledsEnum.PROPERTY_TYPE]
            break
          default:
            break
        }
      }
    })
    builder.addCase(getDropdownThreshold.fulfilled, (state: IDropdown, action: PayloadAction<any>) => {
      state.listThreshold = action.payload
    })
  }
})

export default dropdDownSlice.reducer
