import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_REPORT } from '../../Constants'
import { PREDEFINED_REPORTS_ENUM } from '../../components/reports/predefinedReportMapping'

export const getReportUrl = (selectedReport: PREDEFINED_REPORTS_ENUM, param: Record<string, any>): string => {
  switch (selectedReport) {
    case PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP

    case PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION:
      return `${PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION}?month=${param.varianceByPlantLocation.month}&year=${param.varianceByPlantLocation.year}`

    case PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT:
      return `${PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT}?glAccount=${param.glAccount}`

    case PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION:
      return `${PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION}?plantLoc=${param.fullPartialPL}`

    case PREDEFINED_REPORTS_ENUM.PROPERTY_LIST:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_LIST

    case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL:
      return `${PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL}?plantLoc=${param.propertyInventoryDetails}`

    case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION

    case PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES

    case PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS:
    {
      const { first, last } = param.rangeOfPlantLocation
      const secondArg = last || first
      return `${PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS}?plantLoc1=${first}&plantLoc2=${secondArg}`
    }

    case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2:
      return `${PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2}?workOrderNumber=${param.workAllocationReport2.woNumber2}&cost=${param.workAllocationReport2.cost2}`

    case PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD:
      return `${PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD}?org=res&startMonthYear=${param.resReport.startMonthYear}&endMonthYear=${param.resReport.endMonthYear}`

    case PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD:
      return `${PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD}?org=reo&startMonthYear=${param.reoReport.startMonthYear}&endMonthYear=${param.reoReport.endMonthYear}`
    case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1:
      return `${PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1}?workOrderNumber=${param.workAllocationReport1.woNumber1}&cost=${param.workAllocationReport1.cost}`
    default:
      return ''
  }
}

export const getReport = createAsyncThunk(
  'get-reports', async ({ selectedReport, param, defaultParams }:
  { selectedReport: PREDEFINED_REPORTS_ENUM, param: Record<string, any>, defaultParams: Record<string, any> }, { rejectWithValue }) => {
    const url = getReportUrl(selectedReport, param)
    try {
      const responseData = await api.get(`/${GET_REPORT}/${url}`, {
        params: defaultParams
      })
      if (!responseData.data) throw new Error('No response data')
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode } = error.response.data
        if (statusCode === 401) {
          return rejectWithValue(error.response.data)
        } else {
          throw error.response.data
        }
      }
      // if (error.response && error.response.data && error.response.data.message) {
      //   const errorData = error.response.data.message
      //   return rejectWithValue(Array.isArray(errorData) ? errorData[0] : errorData)
      // }
    }
  })
