export enum SearchOptionsEnum {
  RECORD_NUMBER = 'recordNumber',
  PROPERTY_NAME = 'propertyName',
  PROPERTY_NUMBER = 'propertyNo',
  PROPERTY_ID = 'propertyId',
  COUNTY = 'county',
  CITY = 'city',
  ACQDOC_NUMBER = 'acqDocNo',
  SERIAL_NUMBER = 'serialNo',
  APN = 'apn',
  WORKORDER_NUMBER = 'workorderNo'
}

export const searchOptionsData = (): { id: string[], options: any[] } => {
  return {
    id: [
      SearchOptionsEnum.SERIAL_NUMBER,
      SearchOptionsEnum.ACQDOC_NUMBER,
      SearchOptionsEnum.PROPERTY_NAME,
      SearchOptionsEnum.PROPERTY_NUMBER,
      SearchOptionsEnum.PROPERTY_ID,
      SearchOptionsEnum.RECORD_NUMBER,
      SearchOptionsEnum.COUNTY,
      SearchOptionsEnum.CITY,
      SearchOptionsEnum.WORKORDER_NUMBER,
      SearchOptionsEnum.APN
    ],
    options: [
      'Serial Number',
      'Acquisition Document Number',
      'Property Name',
      'Property Number',
      'Property ID',
      'Record Number',
      'County',
      'City',
      'Work Order Number',
      'APN'
    ]
  }
}
