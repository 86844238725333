import React, { type ReactElement } from 'react'
import './RadioButton.scss'

interface IRadioButtonProps {
  id: string
  name: string
  value: string | number
  label?: string
  className?: string
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioButton = (props: IRadioButtonProps): ReactElement => {
  return (
    <section className='radio-button'>
      <input name={props.name} type="radio" id={props.id} onChange={props.onChange} value={props.value} className={`${props.className ? props.className : ''}`} checked={props.checked}/>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
    </section>
  )
}

export default RadioButton
