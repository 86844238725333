import React, { useEffect, useState } from 'react'
import './ViewTransaction.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../store/store'
import { useParams } from 'react-router'
import Button from '../../../components/sharedModules/button/Button'
import { useNavigate } from 'react-router-dom'
import { getTransactionByRecordNo } from '../../../store/middleWares/getTransactionByRecordNo'
import AccountingInformation from '../../../components/transactions/addTransaction/accountingInformation/AccountingInformation'
import CrossReferenceInformation from '../../../components/transactions/addTransaction/crossReferenceInformation/CrossReferenceInformation'
import CostInformation from '../../../components/transactions/addTransaction/costInformation/CostInformation'
import PropertyActivityData from '../../../components/transactions/addTransaction/propertyActivityData/PropertyActivityData'
import { resetViewTransactionFields, setIsPostSubmiitedValue, doValidationOnSave, resetErrorValueInView, resetViewTransactionStatus } from '../../../store/slices/viewTransactionSlice'
import { getDropdownValues } from '../../../store/middleWares/getDropdownValues'
import { DropDownFiledsEnum, PDF_TYPES, StatusTypesEnum, navigationPath } from '../../../types/CommonTypes'
import { type IErrorData } from '../../../types/TransactionTypes'
import ResponseModal from '../../../components/welcome/ResponseModal'
import TransactionBokedConfirmationModal from '../../../components/transactions/addTransaction/addTransactionModal/TransactionBokedConfirmationModal'
import ErrorModal from '../../../components/welcome/ErrorModal'
import { updateTransaction } from '../../../store/middleWares/updateTransaction'
import PdfModal from '../../../components/pdfGenerator/pdfModal/PdfModal'
import scrollToTop from '../../../util/scrollToTop'
// import { Attachments } from '../../../components/transactions/addTransaction/attachments/Attachments'
// import EdmrmErrorModal from '../../../components/welcome/EdmrmErrorModal'
import { resetAddTransactionStatus } from '../../../store/slices/addTransactionSlice'
import WarningModal from '../../../components/welcome/WarningModal'
import { UPDATE_DELETE_ENUM } from '../../../Constants'
import { deleteTransaction } from '../../../store/middleWares/deleteTransaction'
import { restoreLastPageUrl } from '../../../util/restoreLastPageUrl'

const ViewTransaction: React.FC = () => {
  const [show, setShowValue] = useState<boolean>(false)
  const [showResponse, setResponseValue] = useState<boolean>(false)
  const [showDeleteWarning, setshowDeleteWarning] = useState<boolean>(false)
  const [showUpdateWarning, setshowUpdateWarning] = useState<boolean>(false)
  const [isBookedUpdatable, setBookedUpdatable] = useState<boolean | undefined>(false)
  const [refresh, setRefresh] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const { recordNo } = useParams()
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [viewPdf, setViewPdf] = useState<boolean>(false)

  const {
    isError,
    errorDesc,
    defaultErr
  }: IErrorData = useSelector(
    (state: any) => state.viewTransaction.errorData
  )

  useEffect(() => {
    dispatch(resetViewTransactionStatus())
    setShowValue(false)
    dispatch(getTransactionByRecordNo(recordNo))
    scrollToTop()
    return () => {
      dispatch(resetViewTransactionFields())
      dispatch(resetAddTransactionStatus())
      setBookedUpdatable(false)
    }
  }, [recordNo])

  const isBookedTransaction: boolean | false = useSelector((state: any) => {
    return state.viewTransaction.isBookedTransaction
  })
  const isStatusPostSaved: boolean | false = useSelector((state: any) => {
    return state.viewTransaction.isStatusPostSaved
  })

  const isDeletedSuccessfully: boolean | false = useSelector((state: any) => {
    return state.viewTransaction.isDeletedSuccessfully
  })

  /* const attachmentsFromDb = useSelector((state: any) => {
    return state.viewTransaction.attachmentsFromDb
  }) */

  const isPostSubmitted: boolean | false = useSelector((state: any) => {
    return state.viewTransaction.isPostSubmitted
  })
  const isStatusPost: boolean | false = useSelector((state: any) => {
    return state.viewTransaction.isStatusPost
  })
  const statusType: StatusTypesEnum = useSelector((state: any) => {
    return state.viewTransaction.status.type
  })

  const propNo = useSelector((state: any) => {
    return state.viewTransaction.accountingInformation.propertyNo
  })

  useEffect(() => {
    if (!isBookedTransaction && isBookedUpdatable) setBookedUpdatable(false)
  }, [isBookedTransaction])

  useEffect(() => {
    dispatch(getDropdownValues([DropDownFiledsEnum.AGENT, DropDownFiledsEnum.COUNTY,
      DropDownFiledsEnum.DOCUMENT_TYPE, DropDownFiledsEnum.TRANSACTION_DOC_TYPE, DropDownFiledsEnum.ESCROW_COMPANY, DropDownFiledsEnum.FERC_ACCT,
      DropDownFiledsEnum.GL_ACCT, DropDownFiledsEnum.INSTRUMENT_TITLE, DropDownFiledsEnum.ORGANIZATION,
      DropDownFiledsEnum.PLANT_LOCATION, DropDownFiledsEnum.PROPERTY, DropDownFiledsEnum.TITLE_COMPANY,
      DropDownFiledsEnum.TRANSACTION_STATUS, DropDownFiledsEnum.TRANSACTION_TYPE, DropDownFiledsEnum.OWNERSHIP, DropDownFiledsEnum.ACQ_DOC_NO]))
  }, [recordNo])

  useEffect(() => {
    if (propNo && propNo !== '') dispatch(getDropdownValues([DropDownFiledsEnum.ACQ_DOC_NO]))
  }, [propNo])

  useEffect(() => {
    if (!isError && formSubmitted) {
      if (!isPostSubmitted && !isStatusPost) {
        dispatch(updateTransaction({ addTransaction: false }))
        setFormSubmitted(false)
      }
      if (isPostSubmitted && !isStatusPost && isBookedUpdatable) {
        dispatch(updateTransaction({ addTransaction: false }))
        setFormSubmitted(false)
      }
    }
  }, [formSubmitted])

  const onSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event?.preventDefault()
    dispatch(doValidationOnSave(false))
    setFormSubmitted(true)
    setResponseValue(true)
    if (!isError) setShowValue(true)
  }

  const onCancelHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    dispatch(resetViewTransactionFields())
    setResponseValue(false)
    setShowValue(false)
    navigate(restoreLastPageUrl())
  }

  const onDeleteHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    setshowDeleteWarning(true)
  }

  const onDeleteConfirm = () => {
    setshowDeleteWarning(false)
    // dispatch middleware
    dispatch(deleteTransaction())
    setResponseValue(true)
  }

  const onModalCancelHandler = () => {
    setshowDeleteWarning(false)
  }

  const onUpdateButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    setshowUpdateWarning(true)
  }

  const onUpdateConfirm = () => {
    setshowUpdateWarning(false)
    setBookedUpdatable(true)
  }

  const onUpdateCancelHandler = () => {
    setshowUpdateWarning(false)
  }

  const onPostClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    //  step 1: check for validations first
    dispatch(doValidationOnSave(true))
    dispatch(setIsPostSubmiitedValue(true))
    setShowValue(true)
    setResponseValue(true)
  }

  const handlePdfgeneration = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    setViewPdf(true)
  }

  const resetShowValue = () => {
    setShowValue(false)
  }

  const onFormChange = () => {
    setShowValue(false)
    setResponseValue(false)
    if (formSubmitted) setFormSubmitted(false)
  }

  const showConfirmModal = (): boolean => {
    // post
    if (isStatusPost && show) {
      return true
    } else if (show && !isBookedUpdatable && isBookedUpdatable !== undefined && !isStatusPost) { // booked
      return true
    } else return false
  }

  const navigateFn = () => {
    if (isDeletedSuccessfully) navigate(navigationPath.HOME)
    if (isBookedTransaction && isBookedUpdatable) {
      if (statusType === StatusTypesEnum.ERROR) setBookedUpdatable(true)
      if (statusType === StatusTypesEnum.SUCCESS) {
        setBookedUpdatable(undefined)
        dispatch(getTransactionByRecordNo(recordNo))
        setRefresh(!refresh)
      }
    }
    if (!isDeletedSuccessfully && !isBookedTransaction && !isBookedUpdatable) {
      setRefresh(!refresh)
    }
  }
  return (
    <>
      { showResponse && <ResponseModal view={true} navigateFn={navigateFn}/>}
      { showDeleteWarning && <WarningModal warningLabel='Delete Transaction' modalAction='Delete' view ={showDeleteWarning} txnOpertionEnum= {UPDATE_DELETE_ENUM.DELETE}
      cancelFn={onModalCancelHandler} confirmFn={onDeleteConfirm}/>}

      { showUpdateWarning && <WarningModal warningLabel='Update Transaction' modalAction='Update' view ={showUpdateWarning} txnOpertionEnum= {UPDATE_DELETE_ENUM.UPDATE}
      cancelFn={onUpdateCancelHandler} confirmFn={onUpdateConfirm}/>}

      {/* <EdmrmErrorModal /> */}
      <TransactionBokedConfirmationModal view={true} show={showConfirmModal()} resetShowValue= {resetShowValue}/>
      <ErrorModal defaultErr={defaultErr}
        text={Object.values(errorDesc)}
        show={isError}
        resetFn = {resetErrorValueInView}
        callBack={() => { setFormSubmitted(false) }}
      />
      <form className="form-container" onChange={onFormChange}
      autoSave='off'>
        <div className="form-heading">
          <h2>View Property Transaction</h2>
        </div>
        <div className="view-transaction-form-container">
          <AccountingInformation view={true} isBookedUpdatable = {isBookedUpdatable}/>
          <PropertyActivityData view={true} isBookedUpdatable = {isBookedUpdatable} refresh={refresh}/>
          <CostInformation view={true} isBookedUpdatable = {isBookedUpdatable}/>
          <CrossReferenceInformation view={true} isBookedUpdatable = {isBookedUpdatable}/>
          {/* <Attachments view={true} fileList={attachmentsFromDb} bookedTransaction={isBookedTransaction} isBookedUpdatable = {isBookedUpdatable}/> */}
        </div>
        <div className="add-transaction-footer">
          <span className='view-transaction-cancel-button-group'>
            <Button onClick={onCancelHandler} type="cancel">
              Cancel
            </Button>
            {!isBookedTransaction &&
              <Button
                onClick={onDeleteHandler}
                type="confirm">
                Delete
              </Button>
            }
            </span>

          {!isBookedTransaction
            ? (

            <span className="add-transaction-button-group">

              <Button
                onClick={onPostClickHandler}
                className="post-button"
                disabled={!isStatusPostSaved}
                type="confirm"
              >
                Post
              </Button>

              <Button
                onClick={onSave}
                className="save-button"
                disabled={false}
                type="confirm"
              >
                Save
              </Button>

              <Button
                onClick={handlePdfgeneration}
                className="save-create-pdf-button"
                type="confirm"
              >
                Create .pdf
              </Button>
            </span>
              )
            : (
            <span className="add-transaction-button-group">
              {!isBookedUpdatable
                ? <Button
                onClick={onUpdateButtonHandler}
                className="save-button"
                type="confirm"
              >
                Update
              </Button>
                : <Button
                onClick={onSave}
                className="save-button"
                disabled={false}
                type="confirm"
              >
                Save
              </Button>}

              {/* <Button
                onClick={buttonOnclickHandler}
                className="print-button"
                type="confirm"
              >
                Print
              </Button> */}

              <Button
                onClick={handlePdfgeneration}
                className="save-create-pdf-button"
                type="confirm"
              >
                Create .pdf
              </Button>

            </span>
              )}
        </div>
      </form>
      {viewPdf && (
        <PdfModal onClose={setViewPdf} className='transaction-pdf-modal' pdfType={PDF_TYPES.VIEW_TRANSACTION_PDF}/>
      )}
    </>
  )
}

export default ViewTransaction
