import { graphRequest, msalInstance, tokenScope } from './authConfig'

export const login = async () => {
  try {
    await msalInstance.loginRedirect(graphRequest)
  } catch (error) {
    console.log(`Logging error ----> ${error}`)
  }
}

export const acquireToken = async () => {
  try {
    let tokenResponse = await msalInstance.handleRedirectPromise()

    let accountObj
    if (tokenResponse) {
      accountObj = tokenResponse.account
    } else {
      accountObj = msalInstance.getAllAccounts()[0]
    }

    // if (accountObj && tokenResponse) {
    //   console.log('[AuthService.init] Got valid accountObj and tokenResponse')
    //   return tokenResponse.accessToken
    // } else
    if (accountObj) {
      console.log('[AuthService.init] Got valid accountObj.')
      try {
        tokenResponse = await msalInstance.acquireTokenSilent({
          account: msalInstance.getAllAccounts()[0],
          scopes: tokenScope.scopes
        })
        return tokenResponse.accessToken
      } catch (err) {
        console.log('[AuthService.init] acquire token silent failed:', err)
        login()
      }
    } else {
      console.log('[AuthService.init] No accountObject or tokenResponse present. User must now login.')
      login()
    }
  } catch (error) {
    console.error('[AuthService.init] Failed to handleRedirectPromise()', error)
  }
}

export const acquireTokenForGraph = async () => {
  try {
    let tokenResponse = await msalInstance.handleRedirectPromise()

    let accountObj
    if (tokenResponse) {
      accountObj = tokenResponse.account
    } else {
      accountObj = msalInstance.getAllAccounts()[0]
    }

    if (accountObj) {
      console.log('[AuthService.init] Got valid accountObj.')
      try {
        tokenResponse = await msalInstance.acquireTokenSilent({
          account: msalInstance.getAllAccounts()[0],
          scopes: graphRequest.scopes
        })
        return { accessToken: tokenResponse.accessToken, idToken: tokenResponse.idToken }
      } catch (err) {
        console.log('[AuthService.init] acquire token silent failed:', err)
        login()
      }
    } else {
      console.log('[AuthService.init] No accountObject or tokenResponse present. User must now login.')
      login()
    }
  } catch (error) {
    console.error('[AuthService.init] Failed to handleRedirectPromise()', error)
  }
}
