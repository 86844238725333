import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SearchOptionsEnum } from '../../components/sharedModules/search/SearchOptions'
import { getSearchResult } from '../middleWares/getSearchResult'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { StatusTypesEnum } from '../../types/CommonTypes'

export interface ISearch {
  searchType: { selectedItem: string, id: SearchOptionsEnum }
  searchToken: string
  searchResult: Array<{ id: string, name: string }>
  status: IStatusResponse
}

export const searchInitialData: ISearch = {
  searchType: { selectedItem: 'Property Number', id: SearchOptionsEnum.PROPERTY_NUMBER },
  searchToken: '',
  searchResult: [],
  status: { type: undefined, message: undefined }
}

export const searchSlice = createSlice({
  name: 'search',
  initialState: searchInitialData,
  reducers: {
    setSearchType (state, action: PayloadAction<{ selectedItem: string, id: SearchOptionsEnum }>) {
      state.searchType = action.payload
    },
    setSearchToken (state, action: PayloadAction<string>) {
      state.searchToken = action.payload
    },
    clearSearchResultData (state) {
      state.searchResult = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchResult.fulfilled, (state, action) => {
      state.searchResult = action.payload
      state.status.type = StatusTypesEnum.SUCCESS
    })

    builder.addCase(getSearchResult.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
      state.searchResult = []
    })
  }
})

export const { setSearchToken, setSearchType, clearSearchResultData } = searchSlice.actions
export default searchSlice.reducer
