import { useEffect, useRef } from 'react'

const useDebounce = (callback: any, delay: number) => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined | string>('')

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const deboucedCallback = (...args: any[]) => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      // eslint-disable-next-line n/no-callback-literal
      callback(...args)
    }, delay)
  }
  return deboucedCallback
}

export default useDebounce
