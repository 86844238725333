import { type ADD_TRANSACTION_TYPE, TRANSACTION_TYPE_SECTION } from '../../types/CommonTypes'

export const TRANSACTION_TYPE_MAP: {
  [key in ADD_TRANSACTION_TYPE]: { section: TRANSACTION_TYPE_SECTION };
} = {
  QUITCLAIM: { section: TRANSACTION_TYPE_SECTION.DISPOSITION },
  GRANTOUT: { section: TRANSACTION_TYPE_SECTION.DISPOSITION },
  SALE: { section: TRANSACTION_TYPE_SECTION.DISPOSITION },
  LANDPURCHASE: { section: TRANSACTION_TYPE_SECTION.ACQUISITION },
  EASEMENT: { section: TRANSACTION_TYPE_SECTION.ACQUISITION }
}
