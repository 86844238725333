import React, { type ReactElement } from 'react'
import {
  type IPropertyInventory,
  type InventoryAccountingDataType
} from '../../../store/slices/viewPropertyInventorySlice'
import {
  Page,
  Text,
  View,
  Document,
  Line,
  Svg
} from '@react-pdf/renderer'
import { formatNegNumbersWithBraces, setDecimalPrecision, stringToNumber } from '../../../util/setDecimalPrecision'
import { styles } from './inventoryPdfStyles'
import { MONEY } from '../../../Constants'

const PropertyInventoryPdf = (props: {
  inventoryData: IPropertyInventory
}): ReactElement => {
  const { inventoryAccountingData, inventoryInfo } = props.inventoryData
  const groupedAcctData: Record<string, InventoryAccountingDataType[]> = {}
  inventoryAccountingData.forEach((data) => {
    if (data.glAccount) {
      if (!groupedAcctData[data.glAccount]) {
        groupedAcctData[data.glAccount] = [data]
      } else {
        groupedAcctData[data.glAccount].push(data)
      }
    }
  })
  const reportTotalAcres = Number(
    [...inventoryAccountingData]
      .reduce((total: number, obj) => total + Number(stringToNumber(obj.acres)), 0)
      .toFixed(9)
  )
  const reportTotalAmount = Number(
    [...inventoryAccountingData]
      .reduce((total: number, obj) => total + Number(stringToNumber(obj.amount)), 0)
      .toFixed(2)
  )

  const isPropIdPresent = () => {
    return inventoryInfo.propId ? String(inventoryInfo.propId) : ''
  }

  const remarks: string = props.inventoryData.inventoryInfo.prevComments
    ? String(props.inventoryData.inventoryInfo.prevComments)
    : ''

  const lineSvg = () => {
    return (
      <Svg height="5" width="580">
        <Line
          x1="410"
          y1="5"
          x2="538"
          y2="5"
          strokeWidth={3}
          stroke="#00008B"
        />
      </Svg>
    )
  }

  return (
    <Document pageMode={'fullScreen'}>
      <Page size="A4" style={styles.page}>
        <View fixed>
          <Text style={styles.pageTitle}>
            Property Transactions by Document and Account
          </Text>
          <View style={styles.propertyDetails}>
            <Text style={styles.propertyTitle}>Property:</Text>
            <Text style={styles.propertyTitleValue}>
              {String(inventoryInfo.propertyName)}
            </Text>
            <Text style={styles.propertyId}>{isPropIdPresent()}</Text>
          </View>
          <View style={styles.headingContainer}>
            <Text style={styles.headingEmpty}></Text>
            <Text style={styles.headingDate}>Date</Text>
            <Text style={styles.headingDtn}>DTN</Text>
            <Text style={styles.headingPlantLoc}>Plant Loc</Text>
            <Text style={styles.headingPantAcct}>Plant Acct</Text>
            <Text style={styles.headingWorkOrder}>WO</Text>
            <Text style={styles.headingAcres}>Acres</Text>
            <Text style={styles.headingAmount}>Amount</Text>
          </View>
        </View>
        <View style={styles.parcel}>
          <Text style={styles.acqDocTitle}>Acq Doc/Pcl:</Text>
          <Text style={styles.acqDocValue}>{String(inventoryInfo.acqDocNo)}</Text>
          <Text>{String(inventoryInfo.parcelNo)}</Text>
        </View>

        {Object.keys(groupedAcctData)
          .sort()
          .map((key, index) => {
            let sum = 0
            let totalAcres = 0
            const component = groupedAcctData[key].map((data) => {
              sum += Number(data.amount)
              totalAcres += Number(stringToNumber(data.acres))
              return (
                <View style={styles.dataContainer} key={index}>
                  <View style={styles.emptyRow}></View>
                  <View style={styles.dateContent}>
                    <Text>{data.xferDate}</Text>
                  </View>
                  <View style={styles.dtnContent}>
                    <Text>{data.dtn}</Text>
                  </View>
                  <View style={styles.plantLocContent}>
                    <Text>{data.plantLoc}</Text>
                  </View>
                  <View style={styles.plantAcctContent}>
                    <Text>{data.ferc}</Text>
                  </View>
                  <View style={styles.workOrderContent}>
                    <Text>{data.workOrderNumber}</Text>
                  </View>
                  <View style={styles.acresContent}>
                    <Text>{formatNegNumbersWithBraces(data.acres)}</Text>
                  </View>
                  <View style={styles.amountContent}>
                    <Text>{setDecimalPrecision(data.amount, 2, MONEY)}</Text>
                  </View>
                </View>
              )
            })
            return (
              <View key={index}>
                <View style={styles.glAccount}>
                  <Text>{key}</Text>
                </View>
                {component}
                <Svg height="5" width="580">
                  <Line
                    x1="410"
                    y1="5"
                    x2="538"
                    y2="5"
                    strokeWidth={1}
                    stroke="#00008B"
                  />
                </Svg>
                <View style={styles.subTotal}>
                  <Text style={styles.accountTotalTitle}>Account Total:</Text>
                  <Text style={styles.totalAcres}>
                    {formatNegNumbersWithBraces(Number(totalAcres.toFixed(9)))}
                  </Text>
                  <Text style={styles.totalAmount}>
                    {setDecimalPrecision(sum, 2, MONEY)}
                  </Text>
                </View>
              </View>
            )
          })}
        {lineSvg()}
        {lineSvg()}
        <View style={styles.subTotal}>
          <Text style={styles.accountTotalTitle}>Acq Doc/Pcl Total:</Text>
          <Text style={styles.totalAcres}>
            {formatNegNumbersWithBraces(Number(reportTotalAcres.toFixed(9)))}
          </Text>
          <Text style={styles.totalAmount}>
            {setDecimalPrecision(reportTotalAmount, 2, MONEY)}
          </Text>
        </View>
        {lineSvg()}
        {lineSvg()}
        <View style={styles.subTotal}>
          <Text style={styles.accountTotalTitle}>Report Total:</Text>
          <Text style={styles.totalAcres}>{formatNegNumbersWithBraces(Number(reportTotalAcres.toFixed(9)))}</Text>
          <Text style={styles.totalAmount}>
            {setDecimalPrecision(reportTotalAmount, 2, MONEY)}
          </Text>
        </View>
        <View style={styles.parcel}>
          <Text style={styles.acqDocRemarks}>Acq Doc Remarks: </Text>
        </View>
        <View>
          <Text style={styles.remarks}>{remarks}</Text>
        </View>
          <View style={styles.footer} fixed>
            <Text style={styles.date}>{new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Page ${pageNumber} of ${totalPages}`
            )} ></Text>
          </View>
      </Page>
    </Document>
  )
}

export default PropertyInventoryPdf
