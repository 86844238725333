/**
 * Restores URL with the table of entities, depending on the last stored pagination value
 * @param path Optional hard path to override last stored `pathname` value
 * @returns restored URL for pagination with page parameter set to 1
 */
export const restoreLastPageUrl = (path?: string) => {
  const lastPageState = JSON.parse(localStorage.lastPageState ?? '{____pathname: "/transactions", page: 1, limit: 100, sort: "", dir: "ASC"}')
  const e = encodeURIComponent
  const uri = [path ?? lastPageState.____pathname]
  uri.push('?')
  Object.keys(lastPageState).forEach(key => {
    if (key !== '____pathname') {
      uri.push(key)
      uri.push('=')
      uri.push(e(lastPageState[key]))
      uri.push('&')
    }
  })
  console.log('restoreLastPageUrl :: ' + uri.join())
  return uri.join('')
}
