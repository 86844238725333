import React, { useEffect } from 'react'
import Pagination from '../../components/sharedModules/pagination/Pagination'
import Button from '../../components/sharedModules/button/Button'
import { SORT_DIRECTION, navigationPath } from '../../types/CommonTypes'
import './Transactions.scss'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactionList } from '../../store/middleWares/getTransactionList'
import { type AppDispatch } from '../../store/store'
import { setSorting, type ITransaction, type TransactionSortOrderObjectType, resetSortingOrder } from '../../store/slices/transactionSlice'
import Table from '../../components/sharedModules/table/Table'
import { transactionListHeadings } from '../../components/sharedModules/table/TableHeadingMapping'
import { resetAddTransactionFields } from '../../store/slices/addTransactionSlice'
import { resetViewTransactionFields } from '../../store/slices/viewTransactionSlice'
import TableRowCount from '../../components/sharedModules/tableRowCount/TableRowCount'
import { getParamsBasedonURL } from '../../util/getTableParamsBasedonURL'
import { withMsal } from '@azure/msal-react'
import UnauthorizedWarningPage from '../common/UnauthorizedWarningPage'
// import { ReactComponent as WarningSvg } from '../../assets/warning.svg'
// import { DELETE_TRANSACTION_WARNING } from '../../Constants'

const Transactions: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(location.search)
  const { transactionList, pageCount, sortingOrder, totalTransactions }: ITransaction = useSelector(
    (state: any) => state.transaction
  )
  const isAuthorized = useSelector(
    (state: any) => state.auth.isAuthorized
  )

  useEffect(() => {
    const params = getParamsBasedonURL(searchParams)
    if (!params.sort || !params.dir || (params.dir !== SORT_DIRECTION.ASC && params.dir !== SORT_DIRECTION.DSC)) {
      dispatch(resetSortingOrder())
    }
    dispatch(getTransactionList(params))
  }, [location.search])

  const tableNavigationLinks = [{
    linkIndex: 0,
    path: navigationPath.VIEW_TRANSACTION + '/',
    linkKey: null
  },
  {
    // path: navigationPath.VIEW_PROPERTY_INVENTORY_DETAIL,
    path: `${navigationPath.PROPERTIES}?column=acqDocNo&value=`,
    linkKey: null, // 'refId',
    linkIndex: 5
  }]

  useEffect(() => {
    return () => {
      dispatch(resetAddTransactionFields())
      dispatch(resetViewTransactionFields())
    }
  }, [])

  const doSort = (sortData: Record<string, SORT_DIRECTION>) => {
    const sort = Object.keys(sortData)[0]
    const dir = Object.values(sortData)[0]
    searchParams.set('sort', sort)
    searchParams.set('dir', dir)
    setSearchParams(searchParams)
    dispatch(setSorting(sortData as TransactionSortOrderObjectType))
  }
  return (
    <section className="transactions-container">
      {
        isAuthorized
          ? <>
        <div className="transactions-card">
          <h2>Transactions</h2>
          <Link to={navigationPath.ADD_TRANSACTION}>
              <Button type="confirm" className="transaction-btn-style">
                Add Transaction
              </Button>
            </Link>
          {/* <div className='dlt-warning-container'>
             <div className='dlt-warning'>
              <WarningSvg />
              <p className='warning-text'>{DELETE_TRANSACTION_WARNING}</p>
            </div>

          </div> */}
        </div>
        <TableRowCount count={totalTransactions}
          subText=" Select a record number to view details or add a transaction by
        selecting the button at right." />
        <Table
          data={transactionList}
          headings={transactionListHeadings}
          sortingOrderObj={sortingOrder}
          onClick={(sortData: Record<string, SORT_DIRECTION>) => { doSort(sortData) }}
          navigationLinks={tableNavigationLinks}
          isAllSortingRequired={true}
        />
        <Pagination totalPages={pageCount} />
        </>
          : <UnauthorizedWarningPage />
      }
    </section>
  )
}

export default withMsal(Transactions)
