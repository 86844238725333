import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_PROPERTIES_ENDPOINT } from '../../Constants'

export const getPropertyList = createAsyncThunk('property/get', async (params: any, { rejectWithValue }): Promise<any> => {
  try {
    const responseData = await api.get(`/${GET_PROPERTIES_ENDPOINT}`, {
      params
    })
    return responseData.data
  } catch (error: any) {
    if (error.response && error.response.data) {
      const { statusCode } = error.response.data
      if (statusCode === 401) {
        return rejectWithValue(error.response.data)
      } else {
        throw error.response.data
      }
    }
  }
})
