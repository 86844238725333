import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getPropertyInventoryByAcqDocNo } from '../middleWares/getPropertyInventory'
import { type SORT_DIRECTION, StatusTypesEnum } from '../../types/CommonTypes'
import { updateInventory } from '../middleWares/updateInventory'
import { arraySort } from '../../util/arraySort'
import { DEFAULT_SORTING_ORDER } from '../../Constants'
import { type IErrorData } from '../../types/TransactionTypes'
import { errorInitialData } from './addTransactionInitialData'
import onSaveInventoryValidation from '../../util/inventoryValidation'

interface ErrorPayload {
  message: string
  details?: any
}
export interface InventoryAccountingDataType {
  xferDate: string
  dtn: string | null
  ferc: string
  plantLoc: string
  workOrderNumber: string
  glAccount: string | null
  acres: number
  amount: number
  propAcctId?: number
  acqDocNo?: string
  parcelNo?: string
}

export interface IInventoryAccounting {
  fieldData: InventoryAccountingDataType[]
  errorData: IErrorData
}

export interface IStatusResponse {
  type: StatusTypesEnum | undefined
  message: string | undefined
}

export interface InventoryInformationType {
  refId: string
  acqDocNo: string
  parcelNo: string
  propId: string
  propertyName: string
  totalAcres: number
  totalAmount: number
  prevComments: string
}

export type SortOrderObjectType = {
  [k in keyof InventoryAccountingDataType]: SORT_DIRECTION
}

const SortOrderObjectInitialData: SortOrderObjectType = {
  xferDate: DEFAULT_SORTING_ORDER,
  dtn: DEFAULT_SORTING_ORDER,
  ferc: DEFAULT_SORTING_ORDER,
  plantLoc: DEFAULT_SORTING_ORDER,
  workOrderNumber: DEFAULT_SORTING_ORDER,
  glAccount: DEFAULT_SORTING_ORDER,
  acres: DEFAULT_SORTING_ORDER,
  amount: DEFAULT_SORTING_ORDER
}

export interface IPropertyInventory {
  inventoryAccountingData: InventoryAccountingDataType[]
  inventoryInfo: InventoryInformationType | Record<string, unknown>
  comments: string
  sortOrder: SortOrderObjectType
  status: IStatusResponse
  errorData: IErrorData

}

export const viewPropertyINventoryInitialdata: IPropertyInventory = {
  inventoryAccountingData: [],
  inventoryInfo: {},
  comments: '',
  sortOrder: SortOrderObjectInitialData,
  status: { type: undefined, message: '' },
  errorData: errorInitialData
}

const viewPropertyInventorySlice = createSlice({
  name: 'viewPropertyInventory',
  initialState: viewPropertyINventoryInitialdata,
  reducers: {
    setSorting (state, action: PayloadAction<SortOrderObjectType>) {
      const [[columnName, sortOrder]] = Object.entries(action.payload)
      const { sortedData, sortOption } = arraySort(sortOrder, state.inventoryAccountingData, columnName)
      const updatedSort = { ...SortOrderObjectInitialData, [columnName]: sortOption }
      state.inventoryAccountingData = sortedData
      state.sortOrder = updatedSort
    },

    setComments (state, action) {
      state.comments = action.payload
    },
    resetViewPropertyInventoryStatus (state) {
      state.status = { type: undefined, message: '' }
    },
    resetPropertyInventoryInfo (state) {
      state.inventoryAccountingData = []
      state.inventoryInfo = {}
      state.comments = ''
      state.sortOrder = SortOrderObjectInitialData
      state.status = { type: undefined, message: '' }
      state.errorData = errorInitialData
    },

    updateInventoryAccountingData (state, action: PayloadAction<{ data: any, index: number } >) {
      const { index, data } = action.payload
      const updatedData = [...state.inventoryAccountingData]
      updatedData[index] = { ...updatedData[index], ...data }
      state.inventoryAccountingData = updatedData
    },

    doValidationInventoryOnSave (state: IPropertyInventory, action: PayloadAction<boolean>) {
      const errdata = onSaveInventoryValidation(state, action.payload)
      state.errorData = errdata
    },

    updateErrorDescription (state, action: PayloadAction<any>) {
      state.errorData = { ...state.errorData, propertyDataError: action.payload }
    },

    resetErrorValue (state, action: PayloadAction<boolean>) {
      state.errorData.isError = action.payload
      state.errorData.defaultErr = ''
    },

    addInventoryRow (state, action) {
      state.inventoryAccountingData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPropertyInventoryByAcqDocNo.fulfilled,
      (state, action) => {
        state.inventoryAccountingData = action.payload.inventoryAccountingData
        state.inventoryInfo = action.payload.inventoryInformation
        state.status.type = StatusTypesEnum.SUCCESS
        state.status.message = action.payload.message
      }
    )

    builder.addCase(
      getPropertyInventoryByAcqDocNo.pending,
      (state) => {
        state.status.type = StatusTypesEnum.LOADING
      }
    )

    // TODO: Error handling
    builder.addCase(
      getPropertyInventoryByAcqDocNo.rejected,
      (state, action) => {
        state.status = {
          type: StatusTypesEnum.ERROR,
          message: action.error?.message
        }
      }
    )
    builder.addCase(updateInventory.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = viewPropertyINventoryInitialdata.status.message
    })

    builder.addCase(updateInventory.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.comments = ''
      state.inventoryInfo = action.payload.inventoryInfo
      state.inventoryAccountingData = action.payload.inventoryAcctData
    })

    builder.addCase(updateInventory.rejected, (state, action) => {
      const errorPayload = action.payload as ErrorPayload
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = errorPayload.message
      if (errorPayload.details) state.errorData = { ...state.errorData, propertyDataError: errorPayload.details }
    })
  }
})

export const {
  setSorting,
  setComments,
  resetViewPropertyInventoryStatus,
  resetPropertyInventoryInfo,
  updateInventoryAccountingData,
  addInventoryRow,
  doValidationInventoryOnSave,
  resetErrorValue,
  updateErrorDescription
} = viewPropertyInventorySlice.actions
export default viewPropertyInventorySlice.reducer
