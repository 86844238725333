import { RESULT_INITAL_PAGE, RESULT_PER_PAGE_PAGINATION } from '../Constants'

export const getParamsBasedonURL = (searchParams: URLSearchParams, reset?: boolean): Record<any, any> => {
  if (reset) {
    return {
      page: RESULT_INITAL_PAGE,
      limit: RESULT_PER_PAGE_PAGINATION
    }
  }
  const column = searchParams.get('column')
  const value = searchParams.get('value')
  const dir = searchParams.get('dir')
  const sort = searchParams.get('sort')
  const params: Record<string, any> = {
    page: Number(searchParams.get('page') ?? RESULT_INITAL_PAGE),
    limit: Number(searchParams.get('limit') ?? RESULT_PER_PAGE_PAGINATION)
  }
  if (column) params.column = column
  if (value) params.value = value
  if (dir) params.dir = dir
  if (sort) params.sort = sort
  return params
}
