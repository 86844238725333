import React, { useEffect, type ChangeEvent } from 'react'
import './InventoryInformation.scss'
import Input from '../../sharedModules/input/Input'
import CheckBox from '../../sharedModules/checkbox/CheckBox'
import TextArea from '../../sharedModules/textArea/TextArea'
import { type IInventoryInformation, setComments, setISO, updateViewPropertyInfo } from '../../../store/slices/viewPropertySlice'
import { useDispatch, useSelector } from 'react-redux'
import { NumericFormat } from 'react-number-format'
import { updatedToFixed } from '../../../util/setDecimalPrecision'
import { updatePropertyInfoDropdowns } from '../../../store/slices/viewPropertySlice'
import { trimPropertyInfo } from '../../../util/propertyCalculations'
import Select from '../../sharedModules/select/Select'
import { getDropdownData } from '../../../util/getDropDownPropData'
import { DropDownFiledsEnum } from '../../../types/CommonTypes'
import { DROPDOWN_API_CALL_DELAY } from '../../../Constants'
import useDebounce from '../../../customHooks/useDebounce'
import { getDropdownFilteredValues, getDropdownValues } from '../../../store/middleWares/getDropdownValues'
import { type AppDispatch } from '../../../store/store'

interface IInventoryProps {
  onFormChange?: () => void
  isFilled: { propertyName: boolean, propId: boolean }
  setIsFilled: React.Dispatch<React.SetStateAction<{ propertyName: boolean, propId: boolean }>>
}
const InventoryInformation: React.FC<IInventoryProps> = ({ onFormChange, isFilled, setIsFilled }) => {
  const dispatch = useDispatch<AppDispatch>()
  const fieldData: IInventoryInformation = useSelector(
    (state: any) => {
      return state.viewProperty.inventoryInformation
    }
  )

  const acres = useSelector(
    (state: any) => {
      return updatedToFixed(state.viewProperty.inventoryInformation.totalAcres, 3)
    }
  )

  const totalBookValue = useSelector(
    (state: any) => {
      return updatedToFixed(state.viewProperty.inventoryInformation.totalBookValue, 2)
    }
  )
  const comments = useSelector(
    (state: any) => state.viewProperty.comments
  )

  const county = useSelector((state: any) => state.dropDown.county)
  const city = useSelector((state: any) => state.dropDown.city)
  const state = useSelector((state: any) => state.dropDown.state)
  const propertyType = useSelector((state: any) => state.dropDown.propertyType)

  useEffect(() => {
    dispatch(getDropdownValues([DropDownFiledsEnum.COUNTY, DropDownFiledsEnum.CITY, DropDownFiledsEnum.STATE, DropDownFiledsEnum.PROPERTY_TYPE]))
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    const inputField = e.target.name
    let { value } = e.target
    value = trimPropertyInfo(inputField, value)
    dispatch(updateViewPropertyInfo({ [inputField]: value }))
  }

  const debounceAPICall = useDebounce((field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }, DROPDOWN_API_CALL_DELAY)

  const onDropdownValueChange = (data: any, field: string) => {
    dispatch(updatePropertyInfoDropdowns(data))
    const value: string = Object.values(data)[0] as string
    debounceAPICall(field, value)
  }

  const getDropdownValuesWithoutDelay = (field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }

  return (
    <div className="inventory-container" onChange={onFormChange}>
      <h2 className="sub-heading">Inventory Information</h2>

      <div className="inventory-body-container">
        <section>
          <label className="input-label">Property Number</label>
          <Input
            name="propNo"
            value={fieldData.propNo ?? ''}
            className="read-input-read-only"
            readOnly={true}
            onChange={handleInputChange}
          />
        </section>

        <section>
          <label className="input-label">Property</label>
          <Input
            name="propertyName"
            value={fieldData.propertyName ?? ''}
            className={isFilled.propertyName ? 'read-input' : 'read-input err'}
            readOnly={false}
            onChange={(e) => {
              handleInputChange(e)
              setIsFilled({ ...isFilled, propertyName: true })
            }}
          />
            {!isFilled.propertyName && <p className="error-message ">This field is required</p>}
        </section>
        <section>
          <label className="input-label">Property ID</label>
          <Input
            name="propId"
            value={fieldData.propId ?? ''}
            className={isFilled.propId ? 'read-input' : 'read-input err'}
            readOnly={false}
            onChange={(e) => {
              handleInputChange(e)
              setIsFilled({ ...isFilled, propId: true })
            }}
          />
            {!isFilled.propId && <p className="error-message ">This field is required</p>}
        </section>
        <section>
          <label className="input-label">Address</label>
          <Input
            name="propertyAddress"
            value={fieldData.propertyAddress ?? ''}
            className="read-input"
            readOnly={false}
            onChange={handleInputChange}
          />
        </section>
        <section className='property-info-dropdown'>
          <label className="input-label">County</label>
          < Select
            name="propertyCounty"
            key="county"
            value={fieldData?.propertyCounty ?? ''}
            ignoreValidation={true}
            className="property-input-select"
            data={getDropdownData(county)}
            onChange={(selectedItem: string) => {
              onDropdownValueChange(
                { propertyCounty: selectedItem },
                DropDownFiledsEnum.COUNTY
              )
              if (onFormChange) onFormChange()
            }}
            triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.COUNTY, value) }}
          />
        </section>
        <section className='property-info-dropdown'>
          <label className="input-label">Type</label>
          < Select
              name="propertyType"
              key="propertyType"
              value={fieldData?.propertyType ?? ''}
              ignoreValidation={true}
              className="property-input-select"
              data={getDropdownData(propertyType)}
              onChange={(selectedItem: string) => {
                onDropdownValueChange(
                  { propertyType: selectedItem },
                  DropDownFiledsEnum.PROPERTY_TYPE
                )
                if (onFormChange) onFormChange()
              }}
              triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.PROPERTY_TYPE, value) }}
            />
        </section>

        <div className="divided-section">
          <div className="divided-left-section">
            <section className='property-info-dropdown'>
              <label className="input-label">City</label>
              < Select
                name="propertyCity"
                key="city"
                value={fieldData?.propertyCity ?? ''}
                ignoreValidation={true}
                className="property-input-select"
                data={getDropdownData(city)}
                onChange={(selectedItem: string) => {
                  onDropdownValueChange(
                    { propertyCity: selectedItem },
                    DropDownFiledsEnum.CITY
                  )
                  if (onFormChange) onFormChange()
                }}
                triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.CITY, value) }}
              />
            </section>
            <section>
              <label className="input-label">Thomas Page / Grid</label>
              <span className="thomas-page-grid-section">
                <Input
                  name="thomasPage"
                  value={fieldData.thomasPage ?? ''}
                  className="read-input"
                  readOnly={false}
                  onChange={handleInputChange}
                />
                <Input
                  name="thomasGrid"
                  value={fieldData.thomasGrid ?? ''}
                  className="read-input"
                  readOnly={false}
                  onChange={handleInputChange}
                />
              </span>

            </section>
          </div>
          <div className="divided-right-section">
            <label className="select-if-applicable">Select If Applicable</label>
            <CheckBox
              id="iso"
              name="iso"
              value="iso"
              checked={fieldData.iso === 1}
              onChange={(e: any) =>
                dispatch(setISO(e.target.checked))
              }
              label="ISO"
            />
          </div>
        </div>

        <div className="divided-section">
          <div className="divided-left-section">
            <section className='property-info-dropdown'>
              <label className="input-label">State</label>
              < Select
                name="propertyState"
                key="state"
                value={fieldData?.propertyState ?? ''}
                ignoreValidation={true}
                className="property-input-select"
                data={getDropdownData(state)}
                onChange={(selectedItem: string) => {
                  onDropdownValueChange(
                    { propertyState: selectedItem },
                    DropDownFiledsEnum.STATE
                  )
                  if (onFormChange) onFormChange()
                }}
                triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.STATE, value) }}
              />
            </section>

            <section>
              <label className="input-label">Total Acres</label>
              <NumericFormat
                name="totalAcres"
                value={acres}
                className="input-select-box read-input-read-only"
                readOnly={true}
                decimalScale={3}
                thousandSeparator
                fixedDecimalScale
                // onChange={handleInputChange}
              />
            </section>
            <section>
              <label className="input-label">Zip Code</label>
              <Input
                name="zipCode"
                value={fieldData.zipCode ?? ''}
                className="read-input"
                readOnly={false}
                onChange={handleInputChange}
              />
            </section>

            <section>
              <label className="input-label">Book Value</label>
              <NumericFormat
                name="totalBookValue"
                value={totalBookValue}
                className="input-select-box read-input-read-only"
                readOnly={true}
                prefix="$"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                // onChange={handleInputChange}
              />
            </section>
          </div >
          <div className="divided-right-section">
            <section>
              <label className="input-label">Previous Comments</label>
              <TextArea
                readonly={true}
                name="previousComments"
                value={fieldData.previousComments ?? ''}
                className="read-input read-only"
              />
            </section>
          </div>
        </div >
      </div >
      <div className="comments-div">
        <section>
          <label className='input-label'>Add Comments</label>
          <TextArea
            className="comments-textarea"
            name="comments"
            placeholder="Enter text" // (upto 200 characters)"
            value={comments ?? ''}
            onChange={(e: any) =>
              dispatch(setComments(e.target.value))
            }
          />
        </section>
      </div>
    </div >
  )
}

export default InventoryInformation
