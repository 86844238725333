import React from 'react'
import '../../App'
import { NO_RESULT, TRY_AGAIN_LABEL } from '../../Constants'
import NoDataImg from '../../assets/NoDataFoundIcon.svg'

const NotFound404Page: React.FC = () => {
  return (
    <section className="error-container">
      <div>
        <img src={NoDataImg} />
      </div>

      <label className='label-no-data-found-with-y'>{NO_RESULT}</label>
      <label className='please-try-again-wit'>{TRY_AGAIN_LABEL}</label>
    </section>
  )
}

export default NotFound404Page
