import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_PROPERTY_INVENTORY_DATA } from '../../Constants'

export const getPropertyInventoryByAcqDocNo = createAsyncThunk('property/inventory/:id', async (acqDocNo: any): Promise<any> => {
  try {
    const responseData = await api.get(`/${GET_PROPERTY_INVENTORY_DATA}/${acqDocNo}`)
    return responseData.data
  } catch (error: any) {
    throw error?.response?.data
  }
})
