import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { type InventoryAccountingDataType } from '../../../store/slices/viewPropertyInventorySlice'
import './InventoryAcctData.scss'
import TableRowCount from '../../sharedModules/tableRowCount/TableRowCount'
import InventoryAcctDataSub from '../inventoryAcctDataSub/InventoryAcctDataSub'
import Button from '../../sharedModules/button/Button'
import { addInventoryRow } from '../../../store/slices/viewPropertyInventorySlice'

interface ChildProps {
  onChange: () => void
}

const InventoryAcctData: React.FC<ChildProps> = ({ onChange }) => {
  const dispatch = useDispatch()
  const inventoryAccountingData: InventoryAccountingDataType[] = useSelector(
    (state: any) => {
      return state.viewPropertyInventory.inventoryAccountingData
    })

  const onAddButtonClickHandler = (): void => {
    const newData: InventoryAccountingDataType = {
      xferDate: String(new Date().toISOString().split('T')[0]),
      dtn: '',
      ferc: '',
      plantLoc: '',
      workOrderNumber: '',
      glAccount: '',
      acres: 0.000,
      amount: 0.00
    }
    dispatch(addInventoryRow([...inventoryAccountingData, newData]))
    onChange()
  }

  const handleDelete = (indexToDelete: number) => {
    const inventoryData = [...inventoryAccountingData]
    inventoryData.splice(indexToDelete, 1)
    dispatch(addInventoryRow(inventoryData))
    onChange()
  }

  const handleINputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  return (
    <section className='inventory-acct-data-container'>
      <div>
        <h2 className='inventory-acct-heading'>Property Inventory Accounting Data</h2>
        <TableRowCount count={inventoryAccountingData?.length} subText="" />
      </div>
      <div className='aact-data' onChange={ handleINputChange }>
        {
          inventoryAccountingData.length
            ? (
                inventoryAccountingData.map((item, index) => {
                  return (
                  <InventoryAcctDataSub item={item} index={index} key={index} onDelete={() => { handleDelete(index) }}/>
                  )
                })
              )
            : ''
        }
      </div>
      <div className="add-another-button-container">
        <Button
          buttonType="button"
          className="add-another-button"
          type="confirm"
          onClick={onAddButtonClickHandler}
        >
          +Add Another
        </Button>
      </div>
    </section>
  )
}

export default InventoryAcctData
