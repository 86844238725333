import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { ADD_TRANSACTION_ENDPOINT, UNAUTHORISED_MESSAGE } from '../../Constants'
import { type IAccountingInformation, type IAddTransaction } from '../../types/TransactionTypes'
import { type ITransactionView } from '../slices/viewTransactionSlice'

const getTransactionState = (state: any) => {
  return state.addTransaction
}
const getViewTransactionState = (state: any) => {
  return state.viewTransaction
}
export const postTransaction = createAsyncThunk(
  'add-transaction/:transactionId',
  async (view: boolean, { getState }): Promise<any> => {
    const newData: IAddTransaction | ITransactionView = view
      ? getViewTransactionState(getState())
      : getTransactionState(getState())
    const { transId } = newData.accountingInformation
    let accountingData: IAccountingInformation
    const isPost = newData.isPostSubmitted
    if (isPost) {
      accountingData = { ...newData.accountingInformation, transactionStatus: 'Booked' }
    } else {
      accountingData = newData.accountingInformation
    }
    try {
      const responseData = await api.patch(`/${ADD_TRANSACTION_ENDPOINT}/${transId}`,
        {
          accountingInformation: accountingData,
          costInformation: newData.costInformation,
          crossReference: newData.crossReference,
          propertyData: newData.propertyData
        }

      )
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode } = error.response.data
        if (statusCode === 401) {
          throw new Error(UNAUTHORISED_MESSAGE)
        } else {
          throw error.response.data
        }
      }
    }
  })
