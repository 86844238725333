import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_DROPDOWN_THRESHOLD, GET_DROPDOWN_VALUE_ENDPOINT } from '../../Constants'
import { DropDownFiledsEnum } from '../../types/CommonTypes'

const getTransactionSection = (thunkAPI: any) => {
  const state: any = thunkAPI.getState()
  return (
    state.addTransaction.transactionType.section ||
    state.viewTransaction.transactionType.section ||
    'ACQUISITION'
  )
}

const getPropNo = (thunkAPI: any) => {
  const state: any = thunkAPI.getState()
  const propNo = state.addTransaction.accountingInformation.propertyNo ||
  state.viewTransaction.accountingInformation.propertyNo

  return propNo
}

const getAcqDocNo = (thunkAPI: any, duplicateComponentId: number) => {
  const state: any = thunkAPI.getState()
  const acqDocNo = state.addTransaction.propertyData.fieldData[duplicateComponentId].acqDocNo ||
  state.viewTransaction.propertyData.fieldData[duplicateComponentId].acqDocNo
  return acqDocNo
}

export const getDropdownThreshold = createAsyncThunk(
  'dropdown-threshold/get',
  async () => {
    const responseData = await api.get(
      `${GET_DROPDOWN_VALUE_ENDPOINT}/${GET_DROPDOWN_THRESHOLD}`
    )
    return responseData.data
  }
)

export const getDropdownValues = createAsyncThunk(
  'dropdown-values/get',
  async (fields: string[], thunkAPI) => {
    const transSection = getTransactionSection(thunkAPI)
    const propNo = getPropNo(thunkAPI)
    const joinedFields = fields.join(',')
    const responseData = await api.get(
      `/${GET_DROPDOWN_VALUE_ENDPOINT}?fields=${joinedFields}&propNo=${propNo}&transType=${transSection}`
    )
    return { data: responseData.data, fields }
  }
)

/**
 * @param - Accepts object with field , toke and id (optional) properties
 * id is optional parameter, if can be used in case if the middleware is used from any of the duplicate components.
 */
export const getDropdownFilteredValues = createAsyncThunk(
  'dropdown-values/get/field',
  async (search: { field: string, token: string, duplicateComponentId?: number }, thunkAPI) => {
    const transSection = getTransactionSection(thunkAPI)
    let apiUrl = ''
    if (search.field === DropDownFiledsEnum.PARCEL_NUMBER) {
      if (search.duplicateComponentId === null || search.duplicateComponentId === undefined) return
      const acqDocNo = getAcqDocNo(thunkAPI, search.duplicateComponentId)
      if (acqDocNo === null || acqDocNo === undefined || acqDocNo === '') return
      apiUrl = `/${GET_DROPDOWN_VALUE_ENDPOINT}/${search.field}?acqDocNo=${acqDocNo}&token=${search.token}&transType=${transSection}`
    } else if (search.field === DropDownFiledsEnum.ACQ_DOC_NO) {
      const propNo = getPropNo(thunkAPI)
      if (propNo === null || propNo === undefined || propNo === '') return
      apiUrl = `/${GET_DROPDOWN_VALUE_ENDPOINT}/${search.field}?propNo=${propNo}&token=${search.token}&transType=${transSection}`
    } else {
      apiUrl = `/${GET_DROPDOWN_VALUE_ENDPOINT}/${search.field}?token=${search.token}&transType=${transSection}`
    }
    const responseData = await api.get(apiUrl)
    return { [search.field]: responseData.data }
  }
)

export const getParcelNoDropdownValue = createAsyncThunk(
  'dropdown-values/get/parcelNo',
  async (acqDocNo: string, thunkAPI) => {
    const transSection = getTransactionSection(thunkAPI)
    if (acqDocNo == null || acqDocNo === undefined || acqDocNo === '') return { data: [] }
    const responseData = await api.get(
      `/${GET_DROPDOWN_VALUE_ENDPOINT}/parcelNo?acqDocNo=${acqDocNo}&transType=${transSection}`
    )
    return { data: responseData.data }
  }
)
