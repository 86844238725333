import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_PROPERTIES_ENDPOINT, GET_PDF_ENDPOINT } from '../../Constants'

export const getAllInventoryDataForPDF = createAsyncThunk('properties/:id/create-pdf', async (propertyNumber: any): Promise<any> => {
  const responseData = await api.get(`/${GET_PROPERTIES_ENDPOINT}/${propertyNumber}/${GET_PDF_ENDPOINT}`, {
    responseType: 'blob'
  })
  const pdfstream = await responseData.data
  const blob = new Blob([pdfstream], { type: 'application/pdf' })
  return URL.createObjectURL(blob)
})
