import React from 'react'
import '../routeOrganizations/RouteOrganizations.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { type IFERCAccount } from '../../../../types/MaintenanceCategoryTypes'
import { setDeleteItemIndex, setMaintananceData, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import Button from '../../../sharedModules/button/Button'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import Input from '../../../sharedModules/input/Input'
import { sanitizeGlAct } from '../../../../util/sanitizeString'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  fercAccounts: IFERCAccount[]
}

export const FERCAccount: React.FC<ChildProps> = ({ sort, onChange, onDelete, fercAccounts }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })
  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  const onAddButtonClickHandler = (): void => {
    const newData: IFERCAccount = {
      fercId: '',
      fercNo: '',
      fercDescription: ''
    }
    dispatch(setMaintananceData([...fercAccounts, newData]))
    onChange()
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value } = e.target
    const { name } = e.target
    if (name === 'fercNo') {
      const val = sanitizeGlAct(value)
      value = val?.substring(0, 10)
    }
    if (name === 'fercDescription') value = value.substring(0, 50)

    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }

  const renderComponent = (item: IFERCAccount, index: number) => {
    const { fercDescription, fercNo } = item

    return (
            <div className='div-container' key={index}>
                <section className='input-section small'>
                    <Input
                    className='input-box'
                        name='fercNo'
                        value={fercNo ?? '' }
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <section className='input-section large'>
                    <Input
                        name='fercDescription'
                        value={fercDescription ?? ''}
                        onChange={(e) => { handleChange(e, index) }}
                    />
                </section>
                <div className='delete-icon'>
                    <DeleteSVG onClick={(e) => {
                      handleDeleteByIndex(e, index)
                    }} />
                </div>
            </div>
    )
  }
  return (
        <div className='container'>
            <div className='Header-container'>
                <label className='heading-label'>FERC Accounts</label>
            </div>
            <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
                headerList={maintenanceDataMaster[MaintenanceOptionsEnum.FERC_ACCT].tableHeader}
                smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.FERC_ACCT].smallColumnIndexes}
                className={'icon'} />
            <div onChange={handleInputChange}>
                {
                    fercAccounts?.length > 0 &&
                      fercAccounts.map((item, index) => {
                        return renderComponent(item, index)
                      })
                }
            </div>
            <div className="add-another-button-container">
                <Button
                    buttonType="button"
                    className="add-another-button"
                    type="confirm"
                    onClick={onAddButtonClickHandler}
                >
                    +Add Another
                </Button>
            </div>
        </div>

  )
}
