import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { styles } from './propertyAccountingStyles'
import { formatDateForFormData } from '../../../../util/getCurrentDate'
import { setDecimalPrecision } from '../../../../util/setDecimalPrecision'
import { VIEW_TRANSACTION_TYPE } from '../../../../types/CommonTypes'
import { calculateTotalCost } from '../../../../util/costCalculations'
import { MONEY } from '../../../../Constants'
import { reformatApnForPdf } from '../../../../util/validateApn'

const PropertyAccouting = ({ accountingInformation, propertyData, costInformation }: any): React.ReactElement => {
  return (
    <View>
      <Text style={styles.accountingInfoTitle}>Property Transaction</Text>
      <View style={styles.titleBorder}></View>
      <View style={styles.container}>
        <View style={styles.recordNo}>
          <Text style={styles.subHeading}>Record No:</Text>
          <Text style={styles.val}>{accountingInformation.recordNo}</Text>
        </View>
        <View style={styles.preparedBy}>
          <Text style={styles.subHeading}>Prepared By:</Text>
          <Text style={styles.val}>{accountingInformation.preparedBy}</Text>
        </View>
        <View style={styles.recordedDate}>
          <Text style={styles.subHeading}>Date:</Text>
          <Text style={styles.val}>{accountingInformation.preparedDate ? formatDateForFormData(accountingInformation.preparedDate) : ''}</Text>
        </View>
        <View style={styles.agent}>
          <Text style={styles.subHeading}>Agent:</Text>
          <Text style={styles.val}>{accountingInformation.agent}</Text>
        </View>
        <View style={styles.status}>
          <Text style={styles.subHeading}>Status:</Text>
          <Text style={styles.val}>{accountingInformation.transactionStatus}</Text>
        </View>
        <View style={styles.statusDate}>
          <Text style={styles.subHeading}>Status Date:</Text>
          <Text style={styles.val}>{accountingInformation.statusDate ? formatDateForFormData(accountingInformation.statusDate) : ''}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.propertyName}>
          <Text style={styles.subHeading}>Property Name:</Text>
          <Text style={styles.val}>{accountingInformation.propertyName}</Text>
        </View>
        <View style={styles.propertyId}>
          <Text style={styles.subHeading}>Property ID:</Text>
          <Text style={styles.val}>{accountingInformation.propId}</Text>
        </View>
        <View style={styles.instrumentTitle}>
          <Text style={styles.subHeading}>Instrument Title:</Text>
          <Text style={styles.val}>{accountingInformation.instrumentTitle}</Text>
        </View>
        <View style={styles.status}>
          <Text style={styles.subHeading}>Trans Type:</Text>
          <Text style={styles.val}>{accountingInformation.transactionType}</Text>
        </View>
        <View style={styles.statusDate}>
          <Text style={styles.subHeading}>Doc Type:</Text>
          <Text style={styles.val}>{accountingInformation.documentType}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.recordNo}>
          <Text style={styles.subHeading}>Serial No:</Text>
          <Text style={styles.val}>{accountingInformation.serialNumber}</Text>
        </View>
        <View style={styles.preparedBy}>
          <Text style={styles.subHeading}>Document No:</Text>
          <Text style={styles.val}>{accountingInformation.documentNo}</Text>
        </View>
        <View style={styles.recordedDate}></View>
        <View style={styles.agent}>
          <Text style={styles.subHeading}>Notification No:</Text>
          <Text style={styles.val}>{accountingInformation.rpFileNo}</Text>
        </View>
        <View style={styles.status}>
          <Text style={styles.subHeading}>Work Order No:</Text>
          <Text style={styles.val}>{accountingInformation.workOrderNumber}</Text>
        </View>
        <View style={styles.statusDate}></View>
      </View>
      <View style={styles.container}>
        <View style={styles.grantor}>
          <Text style={styles.subHeading}>Grantor:</Text>
          <Text style={styles.val}>{accountingInformation.grantor}</Text>
        </View>
        <View style={styles.grantee}>
          <Text style={styles.subHeading}>Grantee:</Text>
          <Text style={styles.val}>{accountingInformation.grantee}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.recordNo}>
          <Text style={styles.subHeading}>Executed Date:</Text>
          <Text style={styles.val}>{accountingInformation.executionDate
            ? formatDateForFormData(String(accountingInformation?.executionDate))
            : ''}</Text>
        </View>
        <View style={styles.preparedBy}></View>
        <View style={styles.recordedDate}>
          <Text style={styles.subHeading}>Recording Date:</Text>
          <Text style={styles.val}>{accountingInformation.recordedDate
            ? formatDateForFormData(String(accountingInformation.recordedDate))
            : ''}</Text>
        </View>
        <View style={styles.agent}>
          <Text style={styles.subHeading}>RIN:</Text>
          <Text style={styles.val}>{accountingInformation.recordingInstrumentNo}</Text>
        </View>
        <View style={styles.status}>
          <Text style={styles.subHeading}>County:</Text>
          <Text style={styles.val}>{accountingInformation.county}</Text>
        </View>
        <View style={styles.statusDate}></View>
      </View>
      <View style={styles.container}>
        <View style={styles.titleCompany}>
          <Text style={styles.subHeading}>Title Co.:</Text>
          <Text style={styles.val}>{accountingInformation.titleCompany}</Text>
        </View>
        <View style={styles.escrowCompany}>
          <Text style={styles.subHeading}>Escrow Co.:</Text>
          <Text style={styles.val}>{accountingInformation.escrowCompany}</Text>
        </View>
        <View style={styles.status}>
          <Text style={styles.subHeading}>Land Purch. No.:</Text>
          <Text style={styles.val}>{accountingInformation.purchaseNumber}</Text>
        </View>
        <View style={styles.statusDate}>
          <Text style={styles.subHeading}>Land Sale No.:</Text>
          <Text style={styles.val}>{accountingInformation.saleNumber}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.grantee1}>
          <Text style={styles.subHeading}>APN(s):</Text>
          <Text style={styles.val}>{reformatApnForPdf(accountingInformation.apn)}</Text>
        </View>
        <View style={styles.legalDescrption}>
          <Text style={styles.subHeading}>Legal Description:</Text>
          <Text style={styles.val}>{accountingInformation.legalDescription}</Text>
        </View>
        <View style={styles.grantee}>
          <Text style={styles.subHeading}>Comments:</Text>
          <Text style={styles.val}>{accountingInformation.comments}</Text>
        </View>
      </View>
      <View style={styles.row7Container}>
        <View style={styles.recordNo2}>
          <Text style={styles.subHeading1}>Total Acres:</Text>
          <Text style={styles.val1}>{setDecimalPrecision(propertyData?.totalAcres, 3)}</Text>
        </View>
        <View style={styles.titleCompany}></View>
        <View style={styles.agent1}>
          <Text style={styles.subHeading1}>Book Value:</Text>
          <Text style={styles.val1}>
            {accountingInformation.transactionType === VIEW_TRANSACTION_TYPE.RELINQUISH
              ? setDecimalPrecision(costInformation?.book, 2, MONEY)
              : setDecimalPrecision(calculateTotalCost(costInformation), 2, MONEY)}
          </Text>
        </View>
        <View style={styles.titleCompany}></View>
        <View style={styles.statusDate}></View>
      </View>
    </View>
  )
}

export default PropertyAccouting
