import {
  type IRoutingOrg
  , type IPeople
  , type ICompany
  , type IInstrumentTitle
  , type IDocumentType
  , type IGLAccount,
  type IFERCAccount,
  type IPlanLoc,
  type IOrderProject
} from '../../types/MaintenanceCategoryTypes'

export const peopleDataHeadings: Array<{ column: string, dataKey: keyof IPeople }> = [
  { column: 'Employee ID', dataKey: 'employeeId' },
  { column: 'Last Name', dataKey: 'lastName' },
  { column: 'First Name', dataKey: 'firstName' },
  { column: 'Initial', dataKey: 'initial' },
  { column: 'Title', dataKey: 'title' }
]

export const orgDataHeadings: Array<{ column: string, dataKey: keyof IRoutingOrg }> = [
  { column: 'Organization Name', dataKey: 'organizationName' }
]
export const companyDataHeadings: Array<{ column: string, dataKey: keyof ICompany }> = [
  { column: 'Company ID', dataKey: 'companyId' },
  { column: 'Company Name', dataKey: 'companyName' }
]
export const instrumentTitleDataHeadings: Array<{ column: string, dataKey: keyof IInstrumentTitle }> = [
  { column: 'Instrument Type', dataKey: 'instrumentType' },
  { column: 'Description', dataKey: 'instrumentDesc' },
  { column: 'CDM Reference No.', dataKey: 'instrumentRefNo' }
]

export const documentTypeDataHeadings: Array<{ column: string, dataKey: keyof IDocumentType }> = [
  { column: 'Doc Type', dataKey: 'docType' },
  { column: 'Description', dataKey: 'docTypeDescription' }
]

export const glAccountDataHeadings: Array<{ column: string, dataKey: keyof IGLAccount }> = [
  { column: 'GL Account', dataKey: 'glAccount' },
  { column: 'Description', dataKey: 'glDescription' }
]

export const fercAccountDataHeadings: Array<{ column: string, dataKey: keyof IFERCAccount }> = [
  { column: 'FERC No', dataKey: 'fercNo' },
  { column: 'FERC Description', dataKey: 'fercDescription' }
]

export const plDataHeadings: Array<{ column: string, dataKey: keyof IPlanLoc }> = [
  { column: 'Plant Location', dataKey: 'plantLocation' },
  { column: 'Description', dataKey: 'plantLocDescription' }
]

export const orderProjectDataHeading: Array<{ column: string, dataKey: keyof IOrderProject }> = [
  { column: 'Order', dataKey: 'orderNumber' },
  { column: 'Project Name', dataKey: 'projectName' }
]
