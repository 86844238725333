import { SORT_DIRECTION } from '../types/CommonTypes'

interface returnType {
  sortedData: any[]
  sortOption: SORT_DIRECTION
}

export const arraySort = (sortOption: SORT_DIRECTION, data: any, sortKey: string): returnType => {
  let sortedData = [...data]
  if (sortOption === SORT_DIRECTION.ASC) {
    sortedData = sortedData.sort((a, b) => {
      if (a[sortKey] > b[sortKey] || b[sortKey] === '' || b[sortKey] === null) return 1
      if (a[sortKey] < b[sortKey] || a[sortKey] === '' || a[sortKey] === null) return -1
      return 0
    })
  } else {
    sortedData = sortedData.sort((a, b) => {
      if (a[sortKey] < b[sortKey] || a[sortKey] === '' || a[sortKey] === null) return 1
      if (a[sortKey] > b[sortKey] || b[sortKey] === '' || b[sortKey] === null) return -1
      return 0
    })
  }
  return { sortedData, sortOption: sortOption === SORT_DIRECTION.ASC ? SORT_DIRECTION.DSC : SORT_DIRECTION.ASC }
}
