import React, { type ReactElement, useEffect, useState } from 'react'
import './InventoryReference.scss'
import Table from '../../sharedModules/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../store/store'
import TableRowCount from '../../sharedModules/tableRowCount/TableRowCount'
import { propertyInventoryHeadings } from '../../sharedModules/table/TableHeadingMapping'
import { type IInventoryRef, setSortedInventoryInfo, type InventoryRefSortOrderObjectType } from '../../../store/slices/viewPropertySlice'
import { type SORT_DIRECTION, navigationPath } from '../../../types/CommonTypes'
import { setDecimalPrecision } from '../../../util/setDecimalPrecision'
import { MONEY } from '../../../Constants'

const InventoryReference = (): ReactElement => {
  const [inventoryRefData, setData] = useState<IInventoryRef[]>([])

  const inventoryReferenceList = useSelector((state: any) => {
    return state.viewProperty.inventoryReferenceList
  })

  useEffect(() => {
    if (inventoryReferenceList.length > 0) {
      const data = [...inventoryReferenceList]
      const formattedData = data.map((item: IInventoryRef) => {
        const newItem = {
          ...item,
          totalAmount: setDecimalPrecision(item.totalAmount, 2, MONEY),
          totalAcres: setDecimalPrecision(item.totalAcres, 3)
        }
        return newItem
      })
      setData(formattedData)
    }
  }, [inventoryReferenceList])

  const sortOrderObj = useSelector((state: any) => state.viewProperty.inventoryRefSortingOrder)
  const dispatch = useDispatch<AppDispatch>()

  const doSort = (sortData: Record<string, SORT_DIRECTION>) => {
    dispatch(setSortedInventoryInfo(sortData as InventoryRefSortOrderObjectType))
  }

  return (
    <div className="transaction-container">
      <h2>Inventory References</h2>
      <TableRowCount count={inventoryReferenceList?.length} subText="select an acq doc number to view details or sort by column" />
      {
        inventoryReferenceList &&
        // <div className="table-container">
          <Table data={inventoryRefData}
            headings={propertyInventoryHeadings}
            onClick={doSort}
            navigationLinks={[{ linkIndex: 0, path: navigationPath.VIEW_PROPERTY_INVENTORY_DETAIL + '/', linkKey: 'refId' }]}
            sortingOrderObj={sortOrderObj}
            isAllSortingRequired = {true}
            alignRightIndexes={[8, 9]}
          />
        // </div>
      }
    </div>
  )
}

export default InventoryReference
