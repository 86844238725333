import { type IPropertyCombinedData } from '../types/TransactionTypes'
import { toNumber } from './convertValueToNumber'

export const calculatePropertyTotal = (
  fieldData: IPropertyCombinedData[]
): { totalAcres: number, totalAmount: number } => {
  let totalAcres = 0
  let totalAmount = 0
  for (const data of fieldData) {
    totalAcres += toNumber(data.acres)
    totalAmount += toNumber(data.amount)
  }
  return {
    totalAcres,
    totalAmount
  }
}
export const calculateTotalAmount = (
  fieldData: IPropertyCombinedData[]
): number => {
  let totalAmount = 0
  for (const data of fieldData) {
    totalAmount += toNumber(data.amount)
  }
  return totalAmount
}

export const calculateTotalAcres = (
  fieldData: IPropertyCombinedData[]
): number => {
  let totalAcres = 0
  for (const data of fieldData) {
    totalAcres += toNumber(data.acres)
  }
  return totalAcres
}

export const trimPropertyInfo = (inputField: string, value: any) => {
  switch (inputField) {
    case 'propertyName':
      value = String(value).substring(0, 100)
      break

    case 'propId':
      value = String(value).substring(0, 10)
      break

    case 'propertyAddress':
      value = String(value).substring(0, 50)
      break

    case 'propertyType':
      value = String(value).substring(0, 25)
      break

    case 'thomasPage':
      value = String(value).substring(0, 5)
      break

    case 'thomasGrid':
      value = String(value).substring(0, 10)
      break

    case 'totalAcres':
      value = String(value).substring(0, 15).replace(/,/g, '')
      break

    case 'zipCode':
      value = String(value).substring(0, 20)
      break

    case 'totalBookValue':
      value = String(value).replace(/[$,]/g, '')
      break

    // case 'comments':
    //   value = String(value).substring(0, 200)
    //   break
  }
  return value
}
