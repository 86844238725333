import React, { type ReactElement, useEffect, useState } from 'react'
import './Welcome.scss'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../sharedModules/modal/Modal'
import Button from '../sharedModules/button/Button'
import { type AppDispatch } from '../../store/store'
import { useDispatch } from 'react-redux'

const ErrorModal = (props: {
  show: boolean
  text: string[]
  defaultErr: string
  callBack?: () => void
  resetFn: (arg0: boolean) => any
}): ReactElement | null => {
  const [viewModal, setViewModal] = useState<boolean>(props.show)

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    setViewModal(props.show)
  }, [props.show])

  const modalViewHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setViewModal(false)
    dispatch(props.resetFn(false))
    if (props.callBack != null) props.callBack()
  }

  const renderErrorComponent = (item: any, index: number) => {
    return (
            <li key={index}>{item?.default ?? item}</li>
    )
  }

  if (!viewModal) return null
  return (
        <Modal className="modal top">
            <ModalHeader>
                <section className="welcome-header">
                    <h4>REQUIRED</h4>
                </section>
            </ModalHeader>
            <ModalBody>
                <>
                    <p>{props.defaultErr}</p>
                    {props.text.length > 0 &&
                        <div className="error-div">
                            {props.text.map((item, index) => (
                              renderErrorComponent(item, index)
                            ))
                            }
                        </div>
                    }
                </>
            </ModalBody>
            <ModalFooter>
                <div className="error-box-footer-container">
                    <Button type="confirm" onClick={modalViewHandler}>
                        OK
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
  )
}

export default ErrorModal
