import React, { type ChangeEvent, type ReactElement } from 'react'
import { ERROR_TEXTS } from '../../../Constants'

interface IInputprops {

  name: string
  placeholder?: string
  textDirection?: 'left' | 'right' | 'center'
  inputType?: 'string' | 'number' | 'date'
  value?: any
  className?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onPaste?: (e: ChangeEvent<any>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (e: ChangeEvent<any>) => void
  disabled?: boolean
  max?: string
  min?: string
  readOnly?: boolean
  errorDesc?: string
  pattern?: string
  errorLabelStyle?: string
}

const Input = (props: IInputprops): ReactElement => {
  return (
        <>
            <input
                name={props.name}
                className={`input-select-box  ${props.className ? props.className : ''}`}
                placeholder={props.placeholder}
                type={props.inputType ? props.inputType : 'string'}
                onChange={props.onChange}
                onPaste={props.onPaste}
                onKeyUp={props.onKeyUp}
                onBlur={props.onBlur}
                disabled={props.disabled}
                value={props.value}
                max={props.max}
                readOnly={props.readOnly ?? false}
                min={props.min}
                pattern={props.pattern}
                autoSave='off'
                autoComplete='off'
                aria-autocomplete="none"
                style={{ textAlign: props.textDirection }}
            />
            {
                props.errorDesc &&
                <p className={`error-message ${props.errorLabelStyle ?? ''}`}>
                    {props.errorDesc ?? ERROR_TEXTS.REQUIRED_LABEL}
                </p>
            }
        </>
  )
}

export default Input
