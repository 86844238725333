import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { MaintenanceOptionsEnum, maintanenceCategoryObject, maintenanceDataMaster } from '../../components/maintenance/maintenanceOptions'
import {
  type IRoutingOrg
  , type IPeople, type PeopleSortOrderObjectType, sortingOrderInitialData
  , type RoutingOrgSortOrderObjectType, type ICompany, type CompanySortingOrderObjectType
  , type InstrumentTitleSortingOrderObjectType, type IInstrumentTitle, type IDocumentType
  , type DocumentTypeSortingOrderObjectType, type IGLAccount, type GLAccountSortingOrderObjectType
  , type IFERCAccount, type FERCAccoutSortingObjectType, type IPlanLoc, type plSortingObjectType, type IOrderProject, type OrderSortingObjectType
} from '../../types/MaintenanceCategoryTypes'
import { getMaintenanceData } from '../middleWares/getMaintenanceData'
import { SORT_DIRECTION, StatusTypesEnum } from '../../types/CommonTypes'
import { deleteMaintenanceCategoryData, updateMaintenanceCategoryData } from '../middleWares/updateMaintainanceData'
import { getSortedMaintenanceData } from '../middleWares/getSortedMaintenanceData'
import { getMaintenanceSearchData } from '../middleWares/getMaintenanceSearchData'
import { getMaintenanceSearchResultData } from '../middleWares/getMaintenanceSearchResult'
import { getMaintenanceDataForExport } from '../middleWares/getMaintenanceDataForExport'

export interface IMaintenance {
  options: { selectedItem: string, id: MaintenanceOptionsEnum }
  maintenanceData: Array<IPeople | IRoutingOrg | ICompany | IInstrumentTitle | IDocumentType | IGLAccount | IFERCAccount | IPlanLoc | IOrderProject>
  // deletedData: Array<IPeople | IRoutingOrg | ICompany | IInstrumentTitle | IDocumentType | IGLAccount | IFERCAccount | IPlanLoc | IOrderProject>
  status: IStatusResponse
  pageCount: number
  totalRows: number
  resultPerPage: number
  currentlySortedColumnOrder: Record<string, SORT_DIRECTION>
  sortingOrder: PeopleSortOrderObjectType |
  RoutingOrgSortOrderObjectType
  | CompanySortingOrderObjectType
  | InstrumentTitleSortingOrderObjectType
  | DocumentTypeSortingOrderObjectType
  | GLAccountSortingOrderObjectType
  | FERCAccoutSortingObjectType
  | plSortingObjectType
  | OrderSortingObjectType
  searchType: { selectedCategory: MaintenanceOptionsEnum, columnName: string }
  searchToken: string
  searchResultSuggestions: Array<{ id: string, name: string }>
  deletedIndex: number | null
  isDeleted: boolean
}

export const initialData: IMaintenance = {
  options: { selectedItem: 'People', id: MaintenanceOptionsEnum.PEOPLE },
  maintenanceData: [],
  // deletedData: [],
  status: { type: undefined, message: undefined },
  pageCount: 0,
  totalRows: 0,
  resultPerPage: 0,
  currentlySortedColumnOrder: { [maintenanceDataMaster[MaintenanceOptionsEnum.PEOPLE].initialSortColumn]: SORT_DIRECTION.ASC },
  sortingOrder: sortingOrderInitialData,
  searchToken: '',
  searchType: { selectedCategory: MaintenanceOptionsEnum.PEOPLE, columnName: 'lastName' },
  searchResultSuggestions: [],
  deletedIndex: null,
  isDeleted: false
}

export const MaintenanceSlice = createSlice({
  name: 'maintenance',
  initialState: initialData,
  reducers: {
    setIsDeleted (state, action: PayloadAction<boolean>) {
      state.isDeleted = action.payload
    },
    setSearchToken (state, action: PayloadAction<string>) {
      state.searchToken = action.payload
    },
    resetsearchResultSuggestions (state) {
      state.searchResultSuggestions = initialData.searchResultSuggestions
      state.searchToken = ''
    },
    setMaintenanceOption (state, action: PayloadAction<{ selectedItem: string, id: MaintenanceOptionsEnum }>) {
      if (!action.payload.selectedItem) {
        const selectedItem = maintanenceCategoryObject.filter(item => item.id === action.payload.id)[0]
        state.options = { selectedItem: selectedItem?.label, id: action.payload.id }
      } else {
        state.options = action.payload
      }
      const column = maintenanceDataMaster[action.payload.id].initialSortColumn
      state.currentlySortedColumnOrder = { [column]: SORT_DIRECTION.ASC }
      state.searchToken = ''
      state.searchResultSuggestions = []
    },

    setMaintananceData (state: IMaintenance, action: PayloadAction<Array<(IPeople | IRoutingOrg | ICompany | IInstrumentTitle | IDocumentType | IGLAccount | IFERCAccount | IPlanLoc | IOrderProject)>>) {
      state.maintenanceData = action.payload
    },

    // setDeletedData (state: IMaintenance, action: PayloadAction<IPeople[] | IRoutingOrg[] | ICompany[] | IInstrumentTitle[] | IDocumentType[] | IGLAccount[] | IFERCAccount[] | IPlanLoc[] | IOrderProject[]>) {
    //   state.deletedData = [...state.deletedData, ...action.payload]
    // },
    updateMaintenanceData (state: IMaintenance, action: PayloadAction<{ data: any, index: number } >) {
      const { index, data } = action.payload
      const updatedData = [...state.maintenanceData]
      updatedData[index] = { ...updatedData[index], ...data }
      state.maintenanceData = updatedData
    },
    setCurrentSortOrder (state: IMaintenance, action: PayloadAction<Record<string, SORT_DIRECTION>>) {
      state.currentlySortedColumnOrder = action.payload
    },
    setDeleteItemIndex (state: IMaintenance, action: PayloadAction<number>) {
      state.deletedIndex = action.payload
    },
    clearResultData (state) {
      state.maintenanceData = initialData.maintenanceData
      // state.deletedData = initialData.deletedData
      state.options = initialData.options
      state.pageCount = initialData.pageCount
      state.sortingOrder = initialData.sortingOrder
      state.status = initialData.status
      state.totalRows = initialData.totalRows
      state.currentlySortedColumnOrder = initialData.currentlySortedColumnOrder
      state.searchToken = ''
      state.searchResultSuggestions = initialData.searchResultSuggestions
      state.deletedIndex = null
      state.isDeleted = false
    },
    clearResultDataAfterSuccesFail (state) {
      state.status = initialData.status
      // state.deletedData = initialData.deletedData
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMaintenanceData.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })
    builder.addCase(getMaintenanceData.fulfilled, (state, action) => {
      if (action.payload?.data) {
        const responseData = maintenanceDataMaster[state.options.id].updateData(action.payload?.data)
        state.maintenanceData = responseData
        state.resultPerPage = responseData.length
      } else {
        state.maintenanceData = []
        state.resultPerPage = 0
      }
      state.sortingOrder = { ...maintenanceDataMaster[state.options.id].sortingOrder, ...action.payload?.sortOption }
      state.pageCount = action.payload?.totalPages ?? 0
      state.totalRows = action.payload?.totalResult ?? 0
      state.status.type = StatusTypesEnum.SUCCESS
    })

    builder.addCase(getMaintenanceData.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
      state.maintenanceData = []
      state.resultPerPage = 0
      state.pageCount = 0
      state.totalRows = 0
    })
    builder.addCase(deleteMaintenanceCategoryData.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })
    builder.addCase(updateMaintenanceCategoryData.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })
    builder.addCase(deleteMaintenanceCategoryData.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload?.message
      state.isDeleted = true
    })
    builder.addCase(updateMaintenanceCategoryData.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload?.message
      if (action.payload?.data) {
        const responseData = maintenanceDataMaster[state.options.id].updateData(action.payload?.data)
        state.maintenanceData = responseData // sortedData
        state.resultPerPage = responseData.length
      } else {
        state.maintenanceData = []
        state.resultPerPage = 0
      }
      state.pageCount = action.payload?.totalPages ?? 0
      state.totalRows = action.payload?.totalResult ?? 0
      state.sortingOrder = { ...maintenanceDataMaster[state.options.id].sortingOrder, ...action.payload?.sortOption }
      state.searchResultSuggestions = []
      state.searchToken = ''
    })
    builder.addCase(updateMaintenanceCategoryData.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error?.message
    })
    builder.addCase(getSortedMaintenanceData.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })
    builder.addCase(getSortedMaintenanceData.fulfilled, (state, action) => {
      if (action.payload?.data) {
        const responseData = maintenanceDataMaster[state.options.id].updateData(action.payload?.data)
        state.maintenanceData = responseData
        state.resultPerPage = responseData.length
      }
      state.sortingOrder = { ...maintenanceDataMaster[state.options.id].sortingOrder, ...action.payload?.sortOption }
      state.pageCount = action.payload?.totalPages ?? 0
      state.totalRows = action.payload?.totalResult ?? 0
      state.status.type = StatusTypesEnum.SUCCESS
    })
    builder.addCase(getMaintenanceSearchData.fulfilled, (state, action) => {
      if (action.payload) {
        state.searchResultSuggestions = action.payload
      } else {
        state.searchResultSuggestions = []
      }
    })
    builder.addCase(getMaintenanceSearchResultData.fulfilled, (state, action) => {
      if (action.payload?.data) {
        const responseData = maintenanceDataMaster[state.options.id].updateData(action.payload?.data)
        state.maintenanceData = responseData
        state.resultPerPage = responseData.length
      } else {
        state.maintenanceData = []
        state.resultPerPage = 0
      }
      state.sortingOrder = { ...maintenanceDataMaster[state.options.id].sortingOrder, ...action.payload?.sortOption }
      state.pageCount = action.payload?.totalPages ?? 0
      state.totalRows = action.payload?.totalResult ?? 0
      state.status.type = StatusTypesEnum.SUCCESS
    })
    builder.addCase(getMaintenanceSearchResultData.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })

    builder.addCase(getMaintenanceDataForExport.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = 'Data exported successfully'
    })

    builder.addCase(getMaintenanceDataForExport.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = 'Failed to export data. Please try again!'
    })
  }
})

export const {
  setMaintenanceOption
  , clearResultData
  , setMaintananceData
  , updateMaintenanceData
  // , setDeletedData
  , clearResultDataAfterSuccesFail
  , setCurrentSortOrder
  , setSearchToken
  , resetsearchResultSuggestions
  , setDeleteItemIndex
  , setIsDeleted
} = MaintenanceSlice.actions
export default MaintenanceSlice.reducer
