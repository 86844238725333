import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { sendReport } from '../middleWares/sendReports'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { StatusTypesEnum } from '../../types/CommonTypes'

export interface IrouteEmailInitalData {
  isModalOpen: boolean
  isRouteSuccessFull: boolean | undefined
  receiptientList: string[]
  status: IStatusResponse
}

export const routeEmailInitialData: IrouteEmailInitalData = {
  isModalOpen: false,
  isRouteSuccessFull: undefined,
  receiptientList: [],
  status: { type: undefined, message: '' }
}

const routeEmailSlice = createSlice({
  name: 'routeEmail',
  initialState: routeEmailInitialData,

  reducers: {
    setRouteModalVisibility (state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload
      // state.status = routeEmailInitialData.status
    },
    setReceiptientList (state, action: PayloadAction<string[]>) {
      state.receiptientList = action.payload
    },
    setRouteSuccesfull (state, action: PayloadAction<boolean | undefined>) {
      state.isRouteSuccessFull = action.payload
    }
  },
  extraReducers (builder) {
    builder.addCase(sendReport.pending, (state, action) => {
      state.status.type = StatusTypesEnum.LOADING
    })

    builder.addCase(sendReport.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.isRouteSuccessFull = false
      state.isModalOpen = true
      state.status.message = action.payload as string
    })

    builder.addCase(sendReport.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.isRouteSuccessFull = true
      state.isModalOpen = true
    })
  }
})

export const {
  setRouteModalVisibility
  , setReceiptientList
  , setRouteSuccesfull
} = routeEmailSlice.actions
export default routeEmailSlice.reducer
