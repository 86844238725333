import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_MAINTENANCE_EXPORT } from '../../Constants'
import { type MaintenanceOptionsEnum } from '../../components/maintenance/maintenanceOptions'

export const getMaintenanceDataForExport = createAsyncThunk(
  'export-maintenance', async (selectedCategory: MaintenanceOptionsEnum) => {
    try {
      const responseData = await api.get(`/${GET_MAINTENANCE_EXPORT}/${selectedCategory}`, {
        responseType: 'blob'
      })
      if (!responseData.data) throw new Error('No response data')
      const url = window.URL.createObjectURL(new Blob([responseData.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = `${selectedCategory}.xlsx`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorData = error.response.data.message
        throw errorData.response
      }
    }
  })
