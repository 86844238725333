import { type ICostInformation } from '../types/TransactionTypes'
import { toNumber } from './convertValueToNumber'

export function calculateSumNetProceeds (cost: ICostInformation): number {
  return (
    toNumber(cost.totalConsideration) +
    toNumber(cost.promissoryNotes) +
    toNumber(cost.externalExpense) +
    toNumber(cost.adjustment1) +
    toNumber(cost.adjustment2)
  )
}

export function calculateSAPTotal (cost: ICostInformation): number {
  return (
    toNumber(cost.sapTotalLaborExpenses) +
    toNumber(cost.nonLaborExpenses) +
    toNumber(cost.overheadExpenses)
  )
}

export function calculateGrossProfit (cost: ICostInformation): number {
  return (
    calculateSumNetProceeds(cost) -
    toNumber(cost.structureValue) -
    toNumber(cost.book)
  )
}

export function calculateNetGainLoss (cost: ICostInformation): number {
  return calculateGrossProfit(cost) - calculateSAPTotal(cost)
}

export function calculatePropertyCost (cost: ICostInformation): number {
  return (
    toNumber(cost.totalConsideration) +
    toNumber(cost.externalExpense) +
    toNumber(cost.adjustment1) +
    toNumber(cost.adjustment2)
  )
}

export function calculateTotalCost (cost: ICostInformation): number {
  return calculatePropertyCost(cost) + calculateSAPTotal(cost)
}

export function calculateSaleOverAppraisedValue (
  cost: ICostInformation
): number {
  return toNumber(cost.appraisalValue) > 0
    ? (toNumber(cost.totalConsideration) / toNumber(cost.appraisalValue)) * 100
    : 0
}
