export function toNumber (value: any): number {
  if (typeof value === 'string') {
    value = value.replaceAll('$', '')
    value = value.replaceAll(',', '')
  }
  if (value === undefined || value === '' || isNaN(value)) {
    return 0
  }
  return Number(value)
}
