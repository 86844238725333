// /**
//  * Configuration object to be passed to MSAL instance on creation.
//  * For a full list of MSAL.js configuration parameters, visit:
//  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
//  */

import { type Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser'

const appID: string = process.env.REACT_APP_ENVIRONMENT === 'dev' ? `${process.env.REACT_APP_REIS_CLIENT_ID_DEV}` : `${process.env.REACT_APP_REIS_CLIENT_ID}`
export const msalConfig: Configuration = {
  auth: {
    clientId: appID, // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/5b2a8fee-4c95-4bdc-8aae-196f8aacb1b6', // 'https://edisonintl.onmicrosoft.com.b2clogin.com/edisonintl.onmicrosoft.com.onmicrosoft.com/b2c_1_susi', // Choose SUSI as your default authority.      //   knownAuthorities: ['<tenant-name>.b2clogin.com'], // Mark your B2C tenant's domain as trusted.
    knownAuthorities: ['edisonintl.onmicrosoft.com.b2clogin.com'], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.LOGOUT_URI, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: true // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            // return
          // case LogLevel.Info:
          //   console.info(message)
          //   return
          // case LogLevel.Verbose:
          //   console.debug(message)
          //   return
          // case LogLevel.Warning:
          //   console.warn(message)
        }
      },
      piiLoggingEnabled: false
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)
const API_READ = [`api://${appID}/reis.api`]
const MS_GRAPH_SCOPE = ['User.Read', 'Mail.Send', 'email', 'openid', 'profile']

// "User.Read" stands as shorthand for "graph.microsoft.com/User.Read"
export const graphRequest = {
  scopes: MS_GRAPH_SCOPE
}

export const tokenScope = {
  scopes: API_READ
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}

// // 'https://reis.sce.com'
