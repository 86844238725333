import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  acqContainer: {
    borderBottom: 3,
    borderTop: 3,
    MarginTop: 8,
    borderColor: '#707372',
    paddingBottom: 16,
    paddingTop: 8
  },
  acqTitle: {
    textAlign: 'left',
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    MarginTop: 4,
    width: '100%',
    borderBottom: 0.7,
    borderColor: '#707372',
    paddingBottom: 3
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'Helvetica'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 9,
    flex: 33.2
  },
  subHeading: {
    fontFamily: 'Helvetica',
    marginBottom: 3
  },
  val: {
    color: '#757575'
  }
})
