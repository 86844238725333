import React, { type ReactElement } from 'react'
import './Card.scss'

interface ICardProps {
  className: string
  children: JSX.Element | JSX.Element[]
}

const Card = (props: ICardProps): ReactElement => {
  return (
    <div className={`card-container ${props.className ? props.className : ''}`}>
        {props.children}
    </div>
  )
}

export default Card
