import { type TransactionListType } from '../../../store/slices/transactionSlice'
import { type IInventoryRef } from '../../../store/slices/viewPropertySlice'
import { type InventoryAccountingDataType } from '../../../store/slices/viewPropertyInventorySlice'
import {
  type IPropertyByGlAccount,
  type IPropertyActivityReport,
  type IPropertyListReport,
  type IRangeOfPlantLocationsReport,
  type IPropertyByOwershipReport,
  type IPropInventoryPLOnlyReport,
  type IPropInventoryByPLReport,
  type IWorkAllocationReport2,
  type IREO_RES_DetailYTDReport,
  type IFullPartialPLReport,
  type IVarianceByPlantLocationReport
} from '../../../types/reportsInterfaces'
import { type IPropertyListType } from '../../../store/slices/propertySlice'
import { type IAcqDocData } from '../../../store/slices/dropDownSlice'

export const propertyInventoryAcqDocHeadings: Array<{ column: string, dataKey: keyof IAcqDocData }> = [
  { column: 'Acq Doc', dataKey: 'acqDocNo' },
  { column: 'Parcel', dataKey: 'parcelNumber' },
  { column: 'Serial', dataKey: 'serialNumber' },
  { column: 'Dwg', dataKey: 'drawing' },
  { column: 'Acres', dataKey: 'totalAcres' },
  { column: 'Amount', dataKey: 'totalAmount' }
]
export const transactionListHeadings: Array<{ column: string, dataKey: keyof TransactionListType }> = [
  { column: 'Record No', dataKey: 'recordNo' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Type', dataKey: 'transactionType' },
  { column: 'APN', dataKey: 'apn' },
  { column: 'Serial #', dataKey: 'serialNumber' },
  { column: 'Doc #', dataKey: 'documentNo' },
  { column: 'Status', dataKey: 'transactionStatus' },
  { column: 'Work Order', dataKey: 'workOrderNumber' },
  { column: 'Job Order', dataKey: 'jobOrder' },
  { column: 'Prepared By', dataKey: 'preparedBy' }
]

export const propertyInventoryHeadings: Array<{ column: string, dataKey: keyof IInventoryRef }> = [
  { column: 'Acq Doc', dataKey: 'acqDocNo' },
  { column: 'Parcel', dataKey: 'parcelNo' },
  { column: 'Serial #', dataKey: 'serialNumber' },
  { column: 'Drawing', dataKey: 'drawing' },
  { column: 'Map Size', dataKey: 'mapSize' },
  { column: 'County', dataKey: 'propertyCounty' },
  { column: 'State', dataKey: 'propertyState' },
  { column: 'Fee/Ease', dataKey: 'ownership' },
  { column: 'Acres', dataKey: 'totalAcres' },
  { column: 'Amount', dataKey: 'totalAmount' }
]

export const propertyInventoryAccountingHeadings: Array<{ column: string, dataKey: keyof InventoryAccountingDataType }> = [
  { column: 'Xfer Date', dataKey: 'xferDate' },
  { column: 'DTN', dataKey: 'dtn' },
  { column: 'FERC', dataKey: 'ferc' },
  { column: 'Plant Loc', dataKey: 'plantLoc' },
  { column: 'Work Order', dataKey: 'workOrderNumber' },
  { column: 'GL Acct', dataKey: 'glAccount' },
  { column: 'Acreage', dataKey: 'acres' },
  { column: 'Amount', dataKey: 'amount' }
]

export const propertyActivityReportHeadings: Array<{ column: string, dataKey: keyof IPropertyActivityReport }> = [
  { column: 'Activity No', dataKey: 'recordNo' },
  { column: 'Status', dataKey: 'transactionStatus' },
  { column: 'Status Date', dataKey: 'statusDate' },
  { column: 'Property ID', dataKey: 'propId' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Job Order', dataKey: 'jobOrder' },
  { column: 'Work Order', dataKey: 'workOrderNumber' },
  { column: 'Acres', dataKey: 'totalAcres' }
]
export const propertyByGlAccountReportHeading: Array<{ column: string, dataKey: keyof IPropertyByGlAccount }> = [
  { column: 'Acct #', dataKey: 'glAccount' },
  { column: 'Property ID', dataKey: 'propId' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Ref_ACQ/Acq Doc', dataKey: 'acqDocNo' },
  { column: 'Parcel', dataKey: 'parcelNo' },
  { column: 'Acres', dataKey: 'totalAcres' },
  { column: 'Amount', dataKey: 'totalAmount' },
  { column: 'ACQ Doc Remarks', dataKey: 'previousComments' }
]

export const propertyListReportHeadings: Array<{ column: string, dataKey: keyof IPropertyListReport }> = [
  { column: 'Property ID', dataKey: 'propId' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Type', dataKey: 'propertyType' },
  { column: 'City', dataKey: 'propertyCity' },
  { column: 'County', dataKey: 'propertyCounty' },
  { column: 'State', dataKey: 'propertyState' },
  { column: 'Acres', dataKey: 'totalAcres' },
  { column: 'Amount', dataKey: 'totalBookValue' }
]

export const propertyListHeadings: Array<{ column: string, dataKey: keyof IPropertyListType }> = [
  { column: 'Prop #', dataKey: 'propNo' },
  { column: 'Prop ID', dataKey: 'propId' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Acq Document #', dataKey: 'acqDocNo' },
  { column: 'Serial #', dataKey: 'serialNumber' },
  { column: 'Parcel', dataKey: 'parcelNo' },
  { column: 'Type', dataKey: 'propertyType' },
  { column: 'Total Acres', dataKey: 'totalAcres' },
  { column: 'Book Value', dataKey: 'totalAmount' },
  { column: 'County', dataKey: 'propertyCounty' },
  { column: 'City', dataKey: 'propertyCity' },
  { column: 'Address', dataKey: 'propertyAddress' }
]

export const RangeOfPlantLocations: Array<{ column: string, dataKey: keyof IRangeOfPlantLocationsReport }> = [
  { column: 'Plant Loc', dataKey: 'plantLoc' },
  //  column: 'Property', dataKey: 'propId' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Acq Doc', dataKey: 'acqDocNo' },
  { column: 'Parcel', dataKey: 'parcelNo' },
  { column: 'Date', dataKey: 'xferDate' },
  // { column: 'DTN', dataKey: 'dtn' },
  { column: 'GL Account', dataKey: 'glAccount' },
  { column: 'WO', dataKey: 'workOrderNumber' },
  { column: 'Acres', dataKey: 'acres' },
  { column: 'Amount', dataKey: 'amount' }
]
export const propInventoryPLOnlyReportHeadings: Array<{ column: string, dataKey: keyof IPropInventoryPLOnlyReport }> = [
  { column: 'Property', dataKey: 'propertyName' },
  { column: 'Plant Loc', dataKey: 'plantLoc' },
  { column: 'GL Account', dataKey: 'glAccount' },
  { column: 'Amount', dataKey: 'amount' }
]

export const propertyByOwnershipHeadings: Array<{ column: string, dataKey: keyof IPropertyByOwershipReport }> = [
  { column: 'ACCT', dataKey: 'glAccount' },
  { column: 'Ownership', dataKey: 'ownership' },
  { column: 'Property Type', dataKey: 'propertyType' },
  { column: 'Acres', dataKey: 'totalAcres' },
  { column: 'Amount', dataKey: 'totalAmount' }
]

export const fullPartialPLHeadings: Array<{ column: string, dataKey: keyof IFullPartialPLReport }> = [
  { column: 'Plant Loc', dataKey: 'plantLoc' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Acq Doc', dataKey: 'acqDocNo' },
  { column: 'Parcel', dataKey: 'parcelNo' },
  { column: 'Date', dataKey: 'xferDate' },
  { column: 'GL Acct', dataKey: 'glAccount' },
  { column: 'WO', dataKey: 'workOrderNumber' },
  { column: 'Amount', dataKey: 'amount' }
]

export const propInventoryByPLReportHeadings: Array<{ column: string, dataKey: keyof IPropInventoryByPLReport }> = [
  { column: 'Plant Loc', dataKey: 'plantLoc' },
  { column: 'Property ID', dataKey: 'propId' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Acq Doc', dataKey: 'acqDocNo' },
  { column: 'Parcel', dataKey: 'parcelNo' },
  { column: 'Date', dataKey: 'xferDate' },
  { column: 'DTN', dataKey: 'dtn' },
  { column: 'GL Account', dataKey: 'glAccount' },
  { column: 'Plant Acct', dataKey: 'ferc' },
  { column: 'Work Order', dataKey: 'workOrderNumber' },
  { column: 'Acres', dataKey: 'totalAcres' },
  { column: 'Amount', dataKey: 'totalAmount' }
]

export const workOrderAllocationReport2Headings: Array<{ column: string, dataKey: keyof IWorkAllocationReport2 }> = [
  // { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Serial No.', dataKey: 'serialNumber' },
  { column: 'Document Type', dataKey: 'instrumentTitle' },
  { column: 'Document Number', dataKey: 'documentNo' },
  { column: 'Serial Acres', dataKey: 'totalAcres' },
  { column: 'Serial Consideration', dataKey: 'totalConsideration' },
  { column: 'Serial Percent', dataKey: 'considerationPercent' },
  { column: 'Serial Allocation', dataKey: 'allocation' },
  { column: 'Serial Total Costs', dataKey: 'totalCost' }
]

export const reoDetailYTDReportHeadings: Array<{ column: string, dataKey: keyof IREO_RES_DetailYTDReport }> = [
  { column: 'Month', dataKey: 'recordedDateByMonth' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Job Order', dataKey: 'jobOrder' },
  { column: 'Work Order', dataKey: 'workOrder' },
  { column: 'Acres', dataKey: 'acres' },
  { column: 'Appraised Value', dataKey: 'appraisedvalue' },
  { column: 'Sale Price', dataKey: 'salePrice' },
  { column: 'Sale App%', dataKey: 'saleAppPercent' },
  { column: 'External Expense', dataKey: 'externalExpense' },
  { column: 'Adjustments', dataKey: 'adjustments' },
  { column: 'Net Sales Proceeds', dataKey: 'netProceeds' },
  { column: 'Book Value', dataKey: 'bookValue' },
  { column: 'Gross Profit', dataKey: 'GrossProfit' },
  { column: 'Labor Expense', dataKey: 'labourExpense' },
  { column: 'Non Labor', dataKey: 'nonLabourExpense' },
  { column: 'Overhead', dataKey: 'overhead' },
  { column: 'Internal Expense', dataKey: 'internalExpense' },
  { column: 'Net Gain', dataKey: 'NetGain' },
  { column: 'RPAS Cmpl Date', dataKey: 'rpasCmplDate' },
  { column: 'Recorded Date', dataKey: 'recordedDate' },
  { column: 'Book Date', dataKey: 'bookDate' }
]

export const resDetailYTDReportHeadings: Array<{ column: string, dataKey: keyof IREO_RES_DetailYTDReport }> = [
  { column: 'Month', dataKey: 'recordedDateByMonth' },
  { column: 'Property Name', dataKey: 'propertyName' },
  { column: 'Work Order', dataKey: 'workOrder' },
  { column: 'Acres', dataKey: 'acres' },
  { column: 'Appraised Value', dataKey: 'appraisedvalue' },
  { column: 'Sale Price', dataKey: 'salePrice' },
  { column: 'Sale App%', dataKey: 'saleAppPercent' },
  { column: 'External Expense', dataKey: 'externalExpense' },
  { column: 'Adjustments', dataKey: 'adjustments' },
  { column: 'Net Sales Proceeds', dataKey: 'netProceeds' },
  { column: 'Book Value', dataKey: 'bookValue' },
  { column: 'Gross Profit', dataKey: 'GrossProfit' },
  { column: 'Internal Selling Expense', dataKey: 'internalExpense' },
  { column: 'Net Gain/Loss', dataKey: 'NetGain' },
  { column: 'CRE CMP Date', dataKey: 'woClosedDate' },
  { column: 'Recorded Date', dataKey: 'recordedDate' },
  { column: 'Book Date', dataKey: 'bookDate' }
]

export const workOrderAllocationReport1Headings: Array<{ column: string, dataKey: keyof IWorkAllocationReport2 }> = [
  { column: 'Serial No.', dataKey: 'serialNumber' },
  { column: 'Document Type', dataKey: 'instrumentTitle' },
  { column: 'Document Number', dataKey: 'documentNo' },
  { column: 'Serial Acres', dataKey: 'totalAcres' },
  { column: 'Serial Consideration', dataKey: 'totalConsideration' },
  { column: 'Serial Percent', dataKey: 'considerationPercent' },
  { column: 'Serial Allocation', dataKey: 'allocation' },
  { column: 'Serial Total Costs', dataKey: 'totalCost' }
]

export const varianceByPlantLocationHeadings: Array<{ column: string, dataKey: keyof IVarianceByPlantLocationReport }> = [
  { column: 'Property', dataKey: 'newProperty' },
  { column: 'Plant Loc', dataKey: 'plantLoc' },
  { column: 'Plant 101', dataKey: 'plant101' },
  { column: 'Plant 105', dataKey: 'plant105' },
  { column: 'Plant 121', dataKey: 'plant121' },
  { column: 'Property 101', dataKey: 'property101' },
  { column: 'Property 105', dataKey: 'property105' },
  { column: 'Property 121', dataKey: 'property121' },
  { column: 'Variance', dataKey: 'variance' }
]
