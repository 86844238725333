import React from 'react'
import './People.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { type IPeople } from '../../../../types/MaintenanceCategoryTypes'
import PeopleSub from './PeopleSub'
import Button from '../../../sharedModules/button/Button'
import { setMaintananceData } from '../../../../store/slices/maintenanceSlice'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  peopleData: IPeople[]
}

const People: React.FC<ChildProps> = ({ sort, onChange, onDelete, peopleData }) => {
  const dispatch = useDispatch<AppDispatch>()
  // const peopleData: IPeople[] = useSelector((state: any) => {
  //   return state.maintenance.maintenanceData
  // })

  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })

  const onAddButtonClickHandler = (): void => {
    const newData: IPeople = {
      employeeId: '',
      lastName: '',
      firstName: '',
      initial: '',
      title: ''
    }
    dispatch(setMaintananceData([...peopleData, newData]))
    onChange()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  return (
    <div className='container'>
      <div className='header-container'>
        <label className='heading-label'>People</label>
      </div>
        <MaintenanceCategortHeader sortFn= {sort} sortingOrderObj={sortingOrder}
        headerList={maintenanceDataMaster[MaintenanceOptionsEnum.PEOPLE].tableHeader}
        smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.PEOPLE].smallColumnIndexes}
        />
      <div onChange={handleInputChange}>
      {
        peopleData?.length > 0 &&
          peopleData.map((item, index) => {
            return (
            <div className='people-data-container' key={index}>
              <PeopleSub item={item} index={index} key={index} onDelete={onDelete} />
            </div>
            )
          })

      }
      </div>
      <div className="add-another-button-container">
        <Button
          buttonType="button"
          className="add-another-button"
          type="confirm"
          onClick={onAddButtonClickHandler}
        >
          +Add Another
        </Button>
      </div>
    </div>
  )
}

export default People
