import React, { useEffect } from 'react'
import '../../../../pages/maintenance/Maintenance.scss'
import Select from '../../../sharedModules/select/Select'
import { getDropdownData } from '../../../../util/getDropDownPropData'
import searchIcon from '../../../../assets/search.svg'
import { useDispatch, useSelector } from 'react-redux'
import { resetsearchResultSuggestions, setSearchToken } from '../../../../store/slices/maintenanceSlice'
import { type AppDispatch } from '../../../../store/store'
import useDebounce from '../../../../customHooks/useDebounce'
import { getMaintenanceSearchData } from '../../../../store/middleWares/getMaintenanceSearchData'
import { SEARCH_API_CALL_DELAY } from '../../../../Constants'
import closeIcon from '../../../../assets/close.svg'
import { MaintenanceOptionsEnum } from '../../maintenanceOptions'
import { MAINTENANCE_PLACEHOLDER } from '../../../../types/CommonTypes'

interface IProps {
  onClearAction: () => void
  selectedCategory: MaintenanceOptionsEnum
  onInputChange: (id: string) => void
}
const MaintenanceSearch: React.FC<IProps> = ({ onClearAction, selectedCategory, onInputChange }) => {
  const dispatch = useDispatch<AppDispatch>()
  // const { navigateTo } = useNavigationBasedOnMaintenanceCategory()
  const { searchToken, searchResultSuggestions } = useSelector((state: any) => state.maintenance)

  const debounceAPICall = useDebounce(() => {
    dispatch(getMaintenanceSearchData())
  }, SEARCH_API_CALL_DELAY)

  useEffect(() => {
    if (searchToken) debounceAPICall()
    else handleClear()
  }, [searchToken])

  const handleClear = () => {
    onClearAction()
    dispatch(resetsearchResultSuggestions())
  }
  /** id will be only available if user selects data from dropdown
   * Navigting to the page with the help of id returned
   */
  /** id will be only available if user selects data from dropdown
   * Navigting to the page with the help of id returned
   */
  const handleSearchTokenChange = (selectedItem: string, id?: string) => {
    if (id) {
      onInputChange(id)
    }
    dispatch(setSearchToken(selectedItem))
  }

  let placeholder = MAINTENANCE_PLACEHOLDER.DEFAULT
  switch (selectedCategory) {
    case MaintenanceOptionsEnum.COMPANIES:
      placeholder = MAINTENANCE_PLACEHOLDER.COMPANIES
      break
    case MaintenanceOptionsEnum.DOCUMENT_TYPE:
      placeholder = MAINTENANCE_PLACEHOLDER.DOCUMENT_TYPE
      break
    case MaintenanceOptionsEnum.FERC_ACCT:
      placeholder = MAINTENANCE_PLACEHOLDER.FERC_ACCOUNTS
      break
    case MaintenanceOptionsEnum.GL_ACCOUNT:
      placeholder = MAINTENANCE_PLACEHOLDER.GL_ACCOUNT
      break
    case MaintenanceOptionsEnum.INSTRUMENT_TYPE:
      placeholder = MAINTENANCE_PLACEHOLDER.INSTRUMENT_TYPE
      break
    case MaintenanceOptionsEnum.ORDER_PROJECT:
      placeholder = MAINTENANCE_PLACEHOLDER.ORDER_PROJECT
      break
    case MaintenanceOptionsEnum.PEOPLE:
      placeholder = MAINTENANCE_PLACEHOLDER.PEOPLE
      break
    case MaintenanceOptionsEnum.PLANT_LOCATION:
      placeholder = MAINTENANCE_PLACEHOLDER.PLANT_LOCATION
      break
    case MaintenanceOptionsEnum.ROUTING_ORGANIZATION:
      placeholder = MAINTENANCE_PLACEHOLDER.ROUTING_ORGANIZATIONS
      break
  }

  return (
        <section>
            <label className='label'>Search this Category</label>
            <div className='search-container'>
                <img src={searchIcon} alt="search-icon" />

                <Select
                    name='search-input'
                    className='search-input'
                    value={searchToken}
                    placeholder={placeholder}
                    data={getDropdownData(searchResultSuggestions)}
                    onChange={handleSearchTokenChange}
                    listClassName='search-result-list'
                />
                {searchToken && (
        <button onClick={handleClear} className='search-close-btn'>
          <img src={closeIcon} className='search-close-icon' alt="close-icon" />
        </button>
                )}
            </div>
        </section>
  )
}

export default MaintenanceSearch
