import { DEFAULT_SORTING_ORDER } from '../../Constants'
import { PREDEFINED_REPORTS_ENUM } from '../../components/reports/predefinedReportMapping'
import { type SORT_DIRECTION } from '../../types/CommonTypes'
import { type IFullPartialPLReport, type IPropInventoryByPLReport, type IPropInventoryPLOnlyReport, type IPropertyActivityReport, type IPropertyByGlAccount, type IPropertyByOwershipReport, type IPropertyListReport, type IREO_RES_DetailYTDReport, type IRangeOfPlantLocationsReport, type IVarianceByPlantLocationReport, type IWorkAllocationReport2 } from '../../types/reportsInterfaces'

type ReportsSortingOrderObjectType<T extends PREDEFINED_REPORTS_ENUM> =
    T extends PREDEFINED_REPORTS_ENUM.PROPERTY_LIST ? { initialSortColumn: string, column: Record<keyof IPropertyListReport, SORT_DIRECTION> } :
      T extends PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES ? { initialSortColumn: string, column: Record<keyof IPropertyActivityReport, SORT_DIRECTION> } :
        T extends PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT ? { initialSortColumn: string, column: Record<keyof IPropertyByGlAccount, SORT_DIRECTION> } :
          T extends PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION ? { initialSortColumn: string, column: Record<keyof IFullPartialPLReport, SORT_DIRECTION> } :
            T extends PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP ? { initialSortColumn: string, column: Record<keyof IPropertyByOwershipReport, SORT_DIRECTION> } :
              T extends PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2 ? { initialSortColumn: string, column: Record<keyof IWorkAllocationReport2, SORT_DIRECTION> } :
                T extends PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1 ? { initialSortColumn: string, column: Record<keyof IWorkAllocationReport2, SORT_DIRECTION> } :
                  T extends PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION ? { initialSortColumn: string, column: Record<keyof IVarianceByPlantLocationReport, SORT_DIRECTION> } :
                    T extends PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD ? { initialSortColumn: string, column: Record<keyof IREO_RES_DetailYTDReport, SORT_DIRECTION> } :
                      T extends PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD ? { initialSortColumn: string, column: Record<keyof IREO_RES_DetailYTDReport, SORT_DIRECTION> } :
                        T extends PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS ? { initialSortColumn: string, column: Record<keyof IRangeOfPlantLocationsReport, SORT_DIRECTION> } :
                          T extends PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION ? { initialSortColumn: string, column: Record<keyof IPropInventoryPLOnlyReport, SORT_DIRECTION> } :
                            T extends PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL ? { initialSortColumn: string, column: Record<keyof IPropInventoryByPLReport, SORT_DIRECTION> } :
                              T extends PREDEFINED_REPORTS_ENUM.IMPORT_PLANT_ACCOUNTING_DATA ? any :
                                never

export const reportsSortingOrder: Record<PREDEFINED_REPORTS_ENUM, ReportsSortingOrderObjectType<PREDEFINED_REPORTS_ENUM>> = {
  [PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT]: {
    initialSortColumn: 'propId',
    columns: {
      propId: DEFAULT_SORTING_ORDER,
      glAccount: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      acqDocNo: DEFAULT_SORTING_ORDER,
      parcelNo: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER,
      totalAmount: DEFAULT_SORTING_ORDER,
      previousComments: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION]: {
    initialSortColumn: 'propertyName',
    column: {
      plantLoc: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      acqDocNo: DEFAULT_SORTING_ORDER,
      parcelNo: DEFAULT_SORTING_ORDER,
      xferDate: DEFAULT_SORTING_ORDER,
      glAccount: DEFAULT_SORTING_ORDER,
      workOrderNumber: DEFAULT_SORTING_ORDER,
      amount: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES]: {
    initialSortColumn: 'recordNo',
    column: {
      transId: DEFAULT_SORTING_ORDER,
      recordNo: DEFAULT_SORTING_ORDER,
      transactionStatus: DEFAULT_SORTING_ORDER,
      statusDate: DEFAULT_SORTING_ORDER,
      propId: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      jobOrder: DEFAULT_SORTING_ORDER,
      workOrderNumber: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP]: {
    initialSortColumn: 'glAccount',
    column: {
      glAccount: DEFAULT_SORTING_ORDER,
      ownership: DEFAULT_SORTING_ORDER,
      propertyType: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER,
      totalAmount: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL]: {
    initialSortColumn: 'plantLoc',
    column: {
      plantLoc: DEFAULT_SORTING_ORDER,
      propId: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      acqDocNo: DEFAULT_SORTING_ORDER,
      parcelNo: DEFAULT_SORTING_ORDER,
      xferDate: DEFAULT_SORTING_ORDER,
      dtn: DEFAULT_SORTING_ORDER,
      glAccount: DEFAULT_SORTING_ORDER,
      ferc: DEFAULT_SORTING_ORDER,
      workOrderNumber: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER,
      totalAmount: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION]: {
    initialSortColumn: 'propertyName',
    column: {
      propertyName: DEFAULT_SORTING_ORDER,
      plantLoc: DEFAULT_SORTING_ORDER,
      glAccount: DEFAULT_SORTING_ORDER,
      amount: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_LIST]: {
    initialSortColumn: 'propertyName',
    column: {
      propId: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      propertyType: DEFAULT_SORTING_ORDER,
      acqDocNo: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER,
      totalBookValue: DEFAULT_SORTING_ORDER,
      propertyCounty: DEFAULT_SORTING_ORDER,
      propertyCity: DEFAULT_SORTING_ORDER,
      propertyAddress: DEFAULT_SORTING_ORDER,
      propertyState: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS]: {
    initialSortColumn: 'xferDate',
    column: {
      propAcctId: DEFAULT_SORTING_ORDER,
      xferDate: DEFAULT_SORTING_ORDER,
      dtn: DEFAULT_SORTING_ORDER,
      ferc: DEFAULT_SORTING_ORDER,
      plantLoc: DEFAULT_SORTING_ORDER,
      workOrderNumber: DEFAULT_SORTING_ORDER,
      glAccount: DEFAULT_SORTING_ORDER,
      acres: DEFAULT_SORTING_ORDER,
      amount: DEFAULT_SORTING_ORDER,
      propId: DEFAULT_SORTING_ORDER,
      propNo: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      acqDocNo: DEFAULT_SORTING_ORDER,
      parcelNo: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD]: {
    initialSortColumn: 'propId',
    column: {
      transId: DEFAULT_SORTING_ORDER,
      propId: DEFAULT_SORTING_ORDER,
      propNo: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      jobOrder: DEFAULT_SORTING_ORDER,
      workOrder: DEFAULT_SORTING_ORDER,
      recordedDate: DEFAULT_SORTING_ORDER,
      recordedDateByMonth: DEFAULT_SORTING_ORDER,
      acres: DEFAULT_SORTING_ORDER,
      bookValue: DEFAULT_SORTING_ORDER,
      appraisedvalue: DEFAULT_SORTING_ORDER,
      salePrice: DEFAULT_SORTING_ORDER,
      externalExpense: DEFAULT_SORTING_ORDER,
      internalExpense: DEFAULT_SORTING_ORDER,
      adj1: DEFAULT_SORTING_ORDER,
      adj2: DEFAULT_SORTING_ORDER,
      transOrg: DEFAULT_SORTING_ORDER,
      adjustments: DEFAULT_SORTING_ORDER,
      GrossProfit: DEFAULT_SORTING_ORDER,
      NetGain: DEFAULT_SORTING_ORDER,
      netProceeds: DEFAULT_SORTING_ORDER,
      bookDate: DEFAULT_SORTING_ORDER,
      saleAppPercent: DEFAULT_SORTING_ORDER,
      labourExpense: DEFAULT_SORTING_ORDER,
      nonLabourExpense: DEFAULT_SORTING_ORDER,
      overhead: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD]: {
    initialSortColumn: 'propId',
    column: {
      transId: DEFAULT_SORTING_ORDER,
      propId: DEFAULT_SORTING_ORDER,
      propNo: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      jobOrder: DEFAULT_SORTING_ORDER,
      workOrder: DEFAULT_SORTING_ORDER,
      recordedDate: DEFAULT_SORTING_ORDER,
      recordedDateByMonth: DEFAULT_SORTING_ORDER,
      acres: DEFAULT_SORTING_ORDER,
      bookValue: DEFAULT_SORTING_ORDER,
      appraisedvalue: DEFAULT_SORTING_ORDER,
      salePrice: DEFAULT_SORTING_ORDER,
      externalExpense: DEFAULT_SORTING_ORDER,
      internalExpense: DEFAULT_SORTING_ORDER,
      adj1: DEFAULT_SORTING_ORDER,
      adj2: DEFAULT_SORTING_ORDER,
      transOrg: DEFAULT_SORTING_ORDER,
      adjustments: DEFAULT_SORTING_ORDER,
      GrossProfit: DEFAULT_SORTING_ORDER,
      NetGain: DEFAULT_SORTING_ORDER,
      netProceeds: DEFAULT_SORTING_ORDER,
      bookDate: DEFAULT_SORTING_ORDER,
      saleAppPercent: DEFAULT_SORTING_ORDER,
      labourExpense: DEFAULT_SORTING_ORDER,
      nonLabourExpense: DEFAULT_SORTING_ORDER,
      overhead: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION]: {
    initialSortColumn: 'newProperty',
    column: {
      newProperty: DEFAULT_SORTING_ORDER,
      plantLoc: DEFAULT_SORTING_ORDER,
      plant101: DEFAULT_SORTING_ORDER,
      plant105: DEFAULT_SORTING_ORDER,
      plant121: DEFAULT_SORTING_ORDER,
      property101: DEFAULT_SORTING_ORDER,
      property105: DEFAULT_SORTING_ORDER,
      property121: DEFAULT_SORTING_ORDER,
      variance: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1]: {
    initialSortColumn: 'serialNumber',
    column: {
      serialNumber: DEFAULT_SORTING_ORDER,
      instrumentTitle: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER,
      totalConsideration: DEFAULT_SORTING_ORDER,
      sapTotalInternalExpense: DEFAULT_SORTING_ORDER,
      book: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      considerationPercent: DEFAULT_SORTING_ORDER,
      workOrderNumber: DEFAULT_SORTING_ORDER,
      documentNo: DEFAULT_SORTING_ORDER,
      allocation: DEFAULT_SORTING_ORDER,
      totalCost: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2]: {
    initialSortColumn: 'serialNumber',
    column: {
      serialNumber: DEFAULT_SORTING_ORDER,
      instrumentTitle: DEFAULT_SORTING_ORDER,
      totalAcres: DEFAULT_SORTING_ORDER,
      totalConsideration: DEFAULT_SORTING_ORDER,
      sapTotalInternalExpense: DEFAULT_SORTING_ORDER,
      book: DEFAULT_SORTING_ORDER,
      propertyName: DEFAULT_SORTING_ORDER,
      considerationPercent: DEFAULT_SORTING_ORDER,
      workOrderNumber: DEFAULT_SORTING_ORDER,
      documentNo: DEFAULT_SORTING_ORDER,
      allocation: DEFAULT_SORTING_ORDER,
      totalCost: DEFAULT_SORTING_ORDER
    }
  },
  [PREDEFINED_REPORTS_ENUM.IMPORT_PLANT_ACCOUNTING_DATA]: {}
}
