/* eslint-disable no-case-declarations */
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import './Select.scss'
import { ERROR_TEXTS } from '../../../Constants'
import { useSelector } from 'react-redux'
import { StatusTypesEnum } from '../../../types/CommonTypes'
import { propertyInventoryAcqDocHeadings } from '../table/TableHeadingMapping'

interface ISelectprops {
  name: string
  optionalArg: string
  data: () => { id: string[], options: any[] }
  onChange: (selectedItem: string, valueToFill?: string, id?: string) => void
  triggerOnFocus?: (value: string) => void
  ignoreValidation?: boolean
  className?: string
  value?: any
  errorDesc?: string
  readonly?: boolean
  placeholder?: string
  listClassName?: string
}

const SelectTableList = (props: ISelectprops): ReactElement => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false)
  const dropdownThreshold = useSelector((state: any) => state.dropDown.listThreshold)
  const status: StatusTypesEnum = useSelector((state: any) => state.dropDown.status)
  const dropdownRef = useRef<HTMLTableElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const statusRef = useRef<StatusTypesEnum | undefined>(undefined)
  const optionsRef = useRef<string[]>([])
  const { id, options } = props.data()
  let selectedIndex = -1
  const elementName = props.name.split(/(\d+)/)[0]
  function myFunction (event: any) {
    if (props.readonly) return
    const children = Array.from(
      dropdownRef.current != null ? dropdownRef.current.children : []
    )

    const selectOption = (index: number) => {
      children[selectedIndex]?.classList.remove('dropbtn-selected')
      selectedIndex = index
      children[selectedIndex]?.classList.add('dropbtn-selected')
    }

    function scrollToSelectedElement (container: any) {
      if (!container) return
      const selectedElement: any = children[selectedIndex]
      const containerHeight = container.offsetHeight
      const selectedTop: number = selectedElement.offsetTop
      const selectedHeight = selectedElement.offsetHeight
      const scrollBottom: number = container.scrollTop + containerHeight
      if (selectedTop < container.scrollTop) {
        container.scrollTop = selectedTop
      } else if (selectedTop + selectedHeight > scrollBottom) {
        container.scrollTop = selectedTop + selectedHeight - containerHeight
      }
    }
    const container = dropdownRef.current

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        selectOption((selectedIndex + 1) % children.length)
        scrollToSelectedElement(container)
        break
      case 'ArrowUp':
        event.preventDefault()
        selectOption((selectedIndex - 1 + children.length) % children.length)
        scrollToSelectedElement(container)
        break
      case 'Enter':
        event.preventDefault()
        if (selectedIndex >= 0) {
          const selectedOption = children[selectedIndex].innerHTML.trim()
          const selectedOptionId =
          children[selectedIndex].getAttribute('data-id') ?? ''
          if (!options.includes(selectedOption)) return
          props.onChange(selectedOption, selectedOptionId)
          setIsListOpen(false)
        }
        break
      case 'Tab':
        const filteredItems = optionsRef.current.filter((item: any) => {
          return item[elementName].includes(inputRef.current?.value ?? '')
        })
        const index = filteredItems.findIndex((item: any) => { return item[elementName]?.includes(inputRef.current?.value ?? '') })
        if (filteredItems?.length > 0 && index !== -1) {
          handleTableRowClick(optionsRef.current[index], index)
          break
        }
        if (selectedIndex >= 0 && index === -1) {
          const selectedOption = children[selectedIndex].innerHTML.trim()
          const selectedOptionId =
            children[selectedIndex].getAttribute('data-id') ?? ''
          if (!options.includes(selectedOption)) return
          props.onChange(selectedOption, selectedOptionId)
          setIsListOpen(false)
          break
        }
        if (!props.ignoreValidation) {
          if (inputRef.current && filteredItems?.length > 0 && status !== StatusTypesEnum.LOADING) props.onChange('', '')
          setIsListOpen(false)
          break
        }
        break
    }
  }

  function catchMissedFilter () {
    if (!inputRef.current || !props.triggerOnFocus) return
    if (optionsRef.current.length > 0) {
      if (inputRef.current.value === '' && optionsRef.current.length === dropdownThreshold) {
        return
      }
      // eslint-disable-next-line eqeqeq
      if (optionsRef.current.filter((item: any) => item[elementName] === inputRef.current?.value).length === optionsRef.current.length) {
        return
      }
      props.triggerOnFocus(inputRef.current.value)
    }
  }

  useEffect(() => {
    optionsRef.current = options
  }, [options])

  useEffect(() => {
    statusRef.current = status
  }, [status])

  useEffect(() => {
    const handleclickOutside = (event: MouseEvent) => {
      if (props.readonly) {
        if (isListOpen) setIsListOpen(false)
      }

      if (
        dropdownRef.current != null &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current != null &&
        !inputRef.current.contains(event.target as Node)
      ) {
        // if (!props.ignoreValidation) {
        //   if (optionsRef.current.length < 0) props.onChange('', '')
        //   if (inputRef.current.value && !optionsRef.current.includes(inputRef.current.value) && statusRef.current !== StatusTypesEnum.LOADING) {
        //     props.onChange('', '')
        //   }
        // }

        // const index = optionsRef.current.findIndex((item: any) => item[elementName].toLowerCase() === inputRef.current?.value.toLowerCase())
        // if (!optionsRef.current.includes(inputRef.current.value) && index !== -1) {
        // handleTableRowClick(optionsRef.current[index])
        // }
        setIsListOpen(false)
      }
    }
    document.addEventListener('mousedown', handleclickOutside)
    return () => {
      document.removeEventListener('mousedown', handleclickOutside)
    }
  }, [])

  const handleTableRowClick = (option: any, index?: number | string) => {
    props.onChange(option[elementName], option[props.optionalArg], id[Number(index)])
    setIsListOpen(false)
  }

  return (
    <>
      <input
        key={props.name}
        name={props.name}
        className={`input-select-box select-dropdown ${props.className ? props.className : ''
          }`}
        ref={inputRef}
        value={props.value ? props.value : ''}
        placeholder={props.placeholder ?? 'Select an option'}
        onChange={(e) => {
          if (props.readonly) return
          if (!isListOpen) setIsListOpen(true)
          props.onChange(
            e.target.value,
            e.target.getAttribute('data-id') ?? ''
          )
        }}
        onFocus={(e) => {
          if (props.readonly) return
          catchMissedFilter()
          setIsListOpen(true)
        }}
        onMouseDown={(e) => {
          if (props.readonly) return
          setIsListOpen(true)
        }}
        onKeyDown={myFunction}
        readOnly={props.readonly}
        autoComplete="off"
        list="autocompleteOff"
        aria-autocomplete="none"
      />
      {props.errorDesc &&
        <p className="error-message">
          {props.errorDesc ?? ERROR_TEXTS.REQUIRED_LABEL}
        </p>
      }

      {isListOpen && options?.length > 0 && (
        <div className='dropdown-conatiner'>
        <table ref={dropdownRef}>
          <thead>
            <tr>
                {propertyInventoryAcqDocHeadings?.map(({ column, dataKey }, index) => (
                  <th
                    key={index}
                  >
                    {/* <div className='th-container'> */}
                    <label>{column}</label>
                    {/* </div> */}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {options?.length > 0 &&
                (
                  options.map((value, index: number) => (
                    <tr key={index}
                      onClick={() => {
                        handleTableRowClick(value, index)
                      }}>
                      {propertyInventoryAcqDocHeadings?.map(({ dataKey }, index) => {
                        return (
                          <td key={index} data-id={index}
                          >
                            {value[dataKey]}
                          </td>
                        )
                      })}
                    </tr>
                  ))
                )
              }
        </tbody>
        </table>
        </div>
      )}
    </>
  )
}

export default SelectTableList
