import { DEFAULT_SORTING_ORDER } from '../Constants'
import { type SORT_DIRECTION } from './CommonTypes'

export interface IPeople {
  employeeId: number | ''
  lastName: string
  firstName: string
  initial: string
  title: string
}
export type PeopleSortOrderObjectType = {
  [k in keyof IPeople]: SORT_DIRECTION
}
export const sortingOrderInitialData: PeopleSortOrderObjectType = {
  employeeId: DEFAULT_SORTING_ORDER,
  lastName: DEFAULT_SORTING_ORDER,
  firstName: DEFAULT_SORTING_ORDER,
  initial: DEFAULT_SORTING_ORDER,
  title: DEFAULT_SORTING_ORDER
}
export interface IRoutingOrg {
  organizationId: number | ''
  organizationName: string
}

export type RoutingOrgSortOrderObjectType = {
  [k in keyof IRoutingOrg]: SORT_DIRECTION
}
export const routingOrgSortingOrderInitialData: RoutingOrgSortOrderObjectType = {
  organizationId: DEFAULT_SORTING_ORDER,
  organizationName: DEFAULT_SORTING_ORDER
}

export interface ICompany {
  companyId: number | ''
  companyName: string
}

export type CompanySortingOrderObjectType = {
  [k in keyof ICompany]: SORT_DIRECTION
}

export const companySortingOrderInitialData: CompanySortingOrderObjectType = {
  companyId: DEFAULT_SORTING_ORDER,
  companyName: DEFAULT_SORTING_ORDER
}

export interface IInstrumentTitle {
  instrumentType: number | ''
  instrumentDesc: string
  instrumentRefNo: string
}

export type InstrumentTitleSortingOrderObjectType = {
  [k in keyof IInstrumentTitle]: SORT_DIRECTION
}
export const InstrumentTitleSortingOrderInitialData: InstrumentTitleSortingOrderObjectType = {
  instrumentType: DEFAULT_SORTING_ORDER,
  instrumentDesc: DEFAULT_SORTING_ORDER,
  instrumentRefNo: DEFAULT_SORTING_ORDER
}

export interface IDocumentType {
  docTypeId: number | ''
  docType: string
  docTypeDescription: string
}

export type DocumentTypeSortingOrderObjectType = {
  [k in keyof IDocumentType]: SORT_DIRECTION
}
export const documentTypeSortingOrderInitialData: DocumentTypeSortingOrderObjectType = {
  docTypeId: DEFAULT_SORTING_ORDER,
  docType: DEFAULT_SORTING_ORDER,
  docTypeDescription: DEFAULT_SORTING_ORDER
}

export interface IGLAccount {
  glAccountId: number | ''
  glAccount: string
  glDescription: string
}

export type GLAccountSortingOrderObjectType = {
  [k in keyof IGLAccount]: SORT_DIRECTION
}

export const GLAccountSortingInitialData: GLAccountSortingOrderObjectType = {
  glAccountId: DEFAULT_SORTING_ORDER,
  glAccount: DEFAULT_SORTING_ORDER,
  glDescription: DEFAULT_SORTING_ORDER
}

export interface IFERCAccount {
  fercId: number | ''
  fercNo: string
  fercDescription: string
}
export type FERCAccoutSortingObjectType = {
  [k in keyof IFERCAccount]: SORT_DIRECTION
}

export const FERCAccountSortingInitialData: FERCAccoutSortingObjectType = {
  fercId: DEFAULT_SORTING_ORDER,
  fercNo: DEFAULT_SORTING_ORDER,
  fercDescription: DEFAULT_SORTING_ORDER
}
export interface IPlanLoc {
  plantLocationId: number | ''
  plantLocation: string
  plantLocDescription: string
}

export type plSortingObjectType = {
  [k in keyof IPlanLoc]: SORT_DIRECTION
}

export const plSortingInitialData: plSortingObjectType = {
  plantLocationId: DEFAULT_SORTING_ORDER,
  plantLocation: DEFAULT_SORTING_ORDER,
  plantLocDescription: DEFAULT_SORTING_ORDER
}

export interface IOrderProject {
  orderId: number | ''
  orderNumber: string
  projectName: string
}

export type OrderSortingObjectType = {
  [k in keyof IOrderProject]: SORT_DIRECTION
}
export const orderSortingInitialData: OrderSortingObjectType = {
  orderId: DEFAULT_SORTING_ORDER,
  orderNumber: DEFAULT_SORTING_ORDER,
  projectName: DEFAULT_SORTING_ORDER
}
