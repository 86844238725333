import React, { type ReactElement, useEffect, useState } from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../sharedModules/modal/Modal'
import { ADD_TRANSACTION_MODAL_HEADER } from '../../../../Constants'
import { ADD_TRANSACTION_TYPE as TTYPE } from '../../../../types/CommonTypes'
import { TRANSACTION_TYPE_MAP as TMAP } from '../../transactionTypeMapping'
import RadioButton from '../../../sharedModules/RadioButton/RadioButton'
import './AddTransactionModal.scss'
import Button from '../../../sharedModules/button/Button'
import { setTransactionType, updateStateBasedOnTransactionSection } from '../../../../store/slices/addTransactionSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addTransactionTypeInitialData } from '../../../../store/slices/addTransactionInitialData'

const AddTransactionModal = (): ReactElement | null => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState<boolean>(true)
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TTYPE | null>(null)

  useEffect(() => {
    dispatch(setTransactionType(addTransactionTypeInitialData))
  }, [])

  const onCloseHandler = (): void => {
    setShowModal(false)
    navigate(-1)
  }

  const transactionTypeChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value in TTYPE) {
      const transactionType: TTYPE = e.target.value as TTYPE
      setSelectedTransactionType(transactionType)
    }
  }

  const onConfirmHandler = (): void => {
    if (!selectedTransactionType) return
    const TSection = TMAP[selectedTransactionType].section
    dispatch(
      setTransactionType({
        type: selectedTransactionType,
        section: TSection
      })
    )
    dispatch(updateStateBasedOnTransactionSection(TSection))
    setShowModal(false)
  }

  if (!showModal) return null

  return (
    <Modal className="add-transaction-modal">
      <ModalHeader>
        <section className="add-transaction-modal-header">
          <h4>{ADD_TRANSACTION_MODAL_HEADER}</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div
          className="add-transaction-modal-content"
          onChange={transactionTypeChangeHandler}
        >
          <div className="modal-left-content">
            <label className="heading">Disposition:</label>
            <RadioButton
              name="transaction_type"
              label="Quit Claim"
              id="quit-claim"
              value={TTYPE.QUITCLAIM}
            />
            <RadioButton
              name="transaction_type"
              label="Grant Out"
              id="grant-out"
              value={TTYPE.GRANTOUT}
            />
            <RadioButton
              name="transaction_type"
              label="Sale"
              id="sale"
              value={TTYPE.SALE}
            />
          </div>
          <div className="modal-right-content">
            <label className="heading">Acquisition:</label>
            <RadioButton
              name="transaction_type"
              label="Land Purchase"
              id="land-purchase"
              value={TTYPE.LANDPURCHASE}
            />
            <RadioButton
              name="transaction_type"
              label="Easement"
              id="easement"
              value={TTYPE.EASEMENT}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="add-transaction-modal-footer">
          <Button type="cancel" onClick={onCloseHandler}>
            Cancel
          </Button>
          <Button type="confirm" onClick={onConfirmHandler}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default AddTransactionModal
