import {
  RangeOfPlantLocations,
  propInventoryByPLReportHeadings,
  propInventoryPLOnlyReportHeadings,
  propertyActivityReportHeadings,
  propertyByGlAccountReportHeading,
  propertyByOwnershipHeadings,
  propertyListReportHeadings,
  reoDetailYTDReportHeadings,
  resDetailYTDReportHeadings,
  workOrderAllocationReport2Headings,
  workOrderAllocationReport1Headings,
  fullPartialPLHeadings,
  varianceByPlantLocationHeadings
} from '../sharedModules/table/TableHeadingMapping'
import {
  type IPropInventoryPLOnlyReport,
  type IRangeOfPlantLocationsReport,
  type IPropertyActivityReport,
  type IPropertyByGlAccount,
  type IPropertyListReport,
  type IPropertyByOwershipReport,
  type IFullPartialPLReport,
  type IPropInventoryByPLReport,
  type IWorkAllocationReport2,
  type IREO_RES_DetailYTDReport,
  type IVarianceByPlantLocationReport
} from '../../types/reportsInterfaces'
import { setDecimalPrecision } from '../../util/setDecimalPrecision'
import { formatDate } from '../../util/getCurrentDate'
import { MONEY } from '../../Constants'

export enum PREDEFINED_REPORTS_ENUM {
  REIS_DETAIL_YTD = 'res_detail_ytd',
  PROPERTY_LIST = 'property-list',
  PROPERTY_ACTIVITIES = 'property-activities',
  PROPERTY_BY_OWNERSHIP = 'property-by-ownership',
  FULL_PARTIAL_LAND_LOCATION = 'full_partial-land-location',
  IMPORT_PLANT_ACCOUNTING_DATA = 'import-plant-accounting-data',
  PROPERTY_INVENTORY_DETAIL = 'property-inventory-detail',
  REO_DETAIL_YTD = 'reo_detail_ytd',
  WO_ALLOCATION_REPORT1 = 'wo-allocation-by-serial-number',
  WO_ALLOCATION_REPORT2 = 'wo-allocation-by-document-type',
  RANGE_OF_PLANT_LOCATIONS = 'range-of-plant-locations',
  VARIANCE_BY_PLANT_LOCATION = 'variance-of-plant-locations',
  PROPERTY_INVENTORY_ONLY_PLANT_LOCATION = 'property-inventory-only-plant-location',
  ENTRIES_BY_GL_ACCOUNT = 'entries-by-gl-account',
}

export enum REPORT_EXPORT_TYPE {
  excel = 'xlsx',
  pdf = 'pdf'
}

type reportDataMasterType<T extends string> = { [key in T]: {
  label: string
  header: string
  updateData: (data: any) => any
  sortIndex: number[]
  navLinkIndex: number
  tableHeader: any
  alignRightIndices?: number[]
} }

export const reportDataMaster: reportDataMasterType<PREDEFINED_REPORTS_ENUM> = {
  [PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD]: {
    label: 'RES Detail YTD',
    header: 'Real Estate Sales - Detail Year to Date',
    updateData: (data: IREO_RES_DetailYTDReport[]): any => {
      if (!data) return []
      return data.map((item: IREO_RES_DetailYTDReport) => {
        return {
          ...item,
          acres: setDecimalPrecision(item.acres, 3),
          appraisedvalue: setDecimalPrecision(item.appraisedvalue, 2, MONEY),
          salePrice: setDecimalPrecision(item.salePrice),
          adjustments: setDecimalPrecision(item.adjustments, 2, MONEY),
          externalExpense: setDecimalPrecision(item.externalExpense, 2, MONEY),
          recordedDate: formatDate(item.recordedDate),
          bookDate: formatDate(item.bookDate),
          woClosedDate: formatDate(item.woClosedDate),
          netProceeds: setDecimalPrecision(item.netProceeds, 2, MONEY),
          GrossProfit: setDecimalPrecision(item.GrossProfit, 2, MONEY),
          NetGain: setDecimalPrecision(item.NetGain, 2, MONEY),
          saleAppPercent: `${item.saleAppPercent ?? 0}%`,
          bookValue: setDecimalPrecision(item.bookValue, 2, MONEY),
          internalExpense: setDecimalPrecision(item.internalExpense, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: resDetailYTDReportHeadings,
    alignRightIndices: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_LIST]: {
    label: 'Property List',
    header: 'Reports - Property List',
    updateData: (data: IPropertyListReport[]): any => {
      if (!data) return []
      return data.map((item: IPropertyListReport) => {
        return {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalBookValue: setDecimalPrecision(item.totalBookValue, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: propertyListReportHeadings,
    alignRightIndices: [6, 7]
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES]: {
    label: 'Property Activities',
    header: 'Reports - Property Activities',
    updateData: (data: IPropertyActivityReport[]): any => {
      if (!data) return []
      return data.map((item: IPropertyActivityReport) => {
        return {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: propertyActivityReportHeadings,
    alignRightIndices: [7]
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP]: {
    label: 'Property by Ownership',
    header: 'Reports - Property by Ownership',
    updateData: (data: IPropertyByOwershipReport[]): any => {
      if (!data) return []
      return data.map((item: IPropertyByOwershipReport) => {
        return {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalAmount: setDecimalPrecision(item.totalAmount, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: propertyByOwnershipHeadings,
    alignRightIndices: [3, 4]
  },
  [PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION]: {
    label: 'Full/Partial Plant Location',
    header: 'Reports - Full/Partial Plant Location',
    updateData: (data: IFullPartialPLReport[]): any => {
      if (!data) return []
      return data.map((item: IFullPartialPLReport) => {
        return {
          ...item,
          amount: setDecimalPrecision(item.amount, 2, MONEY),
          xferDate: formatDate(item.xferDate)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: fullPartialPLHeadings,
    alignRightIndices: [7]
  },
  [PREDEFINED_REPORTS_ENUM.IMPORT_PLANT_ACCOUNTING_DATA]: {
    label: 'Import Plant Accounting Data',
    header: '',
    updateData: (): any => {},
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: null
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL]: {
    label: 'Property Inventory Detail (by Plant Location)',
    header: 'Reports - Property Inventory Details By Plant Location',
    updateData: (data: IPropInventoryByPLReport[]): any => {
      if (!data) return []
      return data.map((item: IPropInventoryByPLReport) => {
        return {
          ...item,
          xferDate: formatDate(item.xferDate),
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalAmount: setDecimalPrecision(item.totalAmount, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: propInventoryByPLReportHeadings,
    alignRightIndices: [10, 11]
  },
  [PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD]: {
    label: 'REO Detail YTD',
    header: 'Real Estate Operations - Detail Year to Date',
    updateData: (data: IREO_RES_DetailYTDReport[]): any => {
      if (!data) return []
      return data.map((item: IREO_RES_DetailYTDReport) => {
        return {
          ...item,
          acres: setDecimalPrecision(item.acres, 3),
          appraisedvalue: setDecimalPrecision(item.appraisedvalue, 2, MONEY),
          salePrice: setDecimalPrecision(item.salePrice, 2, MONEY),
          adjustments: setDecimalPrecision(item.adjustments, 2, MONEY),
          externalExpense: setDecimalPrecision(item.externalExpense, 2, MONEY),
          recordedDate: formatDate(item.recordedDate),
          bookDate: formatDate(item.bookDate),
          rpasCmplDate: formatDate(item.rpasCmplDate),
          netProceeds: setDecimalPrecision(item.netProceeds, 2, MONEY),
          GrossProfit: setDecimalPrecision(item.GrossProfit, 2, MONEY),
          NetGain: setDecimalPrecision(item.NetGain, 2, MONEY),
          saleAppPercent: `${item.saleAppPercent ?? 0}%`,
          bookValue: setDecimalPrecision(item.bookValue, 2, MONEY),
          internalExpense: setDecimalPrecision(item.internalExpense, 2, MONEY),
          labourExpense: setDecimalPrecision(item.labourExpense, 2, MONEY),
          nonLabourExpense: setDecimalPrecision(item.nonLabourExpense, 2, MONEY),
          overhead: setDecimalPrecision(item.overhead, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: reoDetailYTDReportHeadings,
    alignRightIndices: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
  },
  [PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1]: {
    label: 'Work Order Cost Allocations By Serial Number', // 'WO Allocation Report1',
    header: 'Work Order Cost Allocations By Serial Number',
    updateData: (data: IWorkAllocationReport2[]): any => {
      if (!data) return []
      return data.map((item: IWorkAllocationReport2) => {
        return {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalConsideration: setDecimalPrecision(item.totalConsideration, 2, MONEY),
          considerationPercent: (item.considerationPercent).toFixed(3),
          allocation: setDecimalPrecision(item.allocation, 2, MONEY),
          totalCost: setDecimalPrecision(item.totalCost, 2, MONEY)
        // book: setDecimalPrecision(item.book),
        // sapTotalInternalExpense: setDecimalPrecision(item.sapTotalInternalExpense)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: workOrderAllocationReport1Headings,
    alignRightIndices: [3, 4, 5, 6, 7, 8]
  },
  [PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2]: {
    label: 'Work Order Cost Allocations By Document Type', // 'WO Allocation Report2',
    header: 'Work Order Cost Allocations By Document Type',
    updateData: (data: IWorkAllocationReport2[]): any => {
      if (!data) return []
      return data.map((item: IWorkAllocationReport2) => {
        return {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalConsideration: setDecimalPrecision(item.totalConsideration, 2, MONEY),
          considerationPercent: (item.considerationPercent).toFixed(3),
          allocation: setDecimalPrecision(item.allocation, 2, MONEY),
          totalCost: setDecimalPrecision(item.totalCost, 2, MONEY)
        // book: setDecimalPrecision(item.book),
        // sapTotalInternalExpense: setDecimalPrecision(item.sapTotalInternalExpense)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: workOrderAllocationReport2Headings,
    alignRightIndices: [3, 4, 5, 6, 7, 8]
  },
  [PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS]: {
    label: 'Range of Plant Locations',
    header: 'Reports - Range of Plant Locations',
    updateData: (data: IRangeOfPlantLocationsReport[]): any => {
      if (!data) return []
      return data.map((item: IRangeOfPlantLocationsReport) => {
        return {
          ...item,
          acres: setDecimalPrecision(item.acres, 3),
          amount: setDecimalPrecision(item.amount, 2, MONEY),
          xferDate: formatDate(item.xferDate)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: RangeOfPlantLocations,
    alignRightIndices: [7, 8]
  },
  [PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION]: {
    label: 'Variance by Plant Location',
    header: 'Reports - Variance by Plant Location',
    updateData: (data: IVarianceByPlantLocationReport[]): any => {
      if (!data) return []
      return data.map((item: IVarianceByPlantLocationReport) => {
        return {
          ...item,
          plant101: setDecimalPrecision(item.plant101, 2, MONEY),
          plant105: setDecimalPrecision(item.plant105, 2, MONEY),
          plant121: setDecimalPrecision(item.plant121, 2, MONEY),
          property101: setDecimalPrecision(item.property101, 2, MONEY),
          property105: setDecimalPrecision(item.property105, 2, MONEY),
          property121: setDecimalPrecision(item.property121, 2, MONEY),
          variance: setDecimalPrecision(item.variance, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: varianceByPlantLocationHeadings,
    alignRightIndices: [2, 3, 4, 5, 6, 7, 8]
  },
  [PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION]: {
    label: 'Property Inventory Only Plant Locations',
    header: 'Reports - Property Inventory Only Plant Locations',
    updateData: (data: IPropInventoryPLOnlyReport[]): any => {
      if (!data) return []
      return data.map((item: IPropInventoryPLOnlyReport) => {
        return {
          ...item,
          amount: setDecimalPrecision(item.amount, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: propInventoryPLOnlyReportHeadings,
    alignRightIndices: [3]
  },
  [PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT]: {
    label: 'Entries by GL Account',
    header: 'Reports - Entries by GL Account',
    updateData: (data: IPropertyByGlAccount[]): any => {
      if (!data) return []
      return data.map((item: IPropertyByGlAccount) => {
        return {
          ...item,
          totalAcres: setDecimalPrecision(item.totalAcres, 3),
          totalAmount: setDecimalPrecision(item.totalAmount, 2, MONEY)
        }
      })
    },
    sortIndex: [],
    navLinkIndex: 0,
    tableHeader: propertyByGlAccountReportHeading,
    alignRightIndices: [5, 6]
  }
}
