import React, { type ChangeEvent, type ForwardedRef, forwardRef } from 'react'
import { ERROR_TEXTS } from '../../../Constants'

interface ITextAreaProps {
  name: string
  placeholder?: string
  className?: string
  value?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  readonly?: boolean
  errorDesc?: string
  errorLabelStyle?: string
}

// eslint-disable-next-line react/display-name
const TextArea = forwardRef((props: ITextAreaProps, textAreaRef: ForwardedRef<HTMLTextAreaElement>) => {
  return (
    <>
    <textarea readOnly={props.readonly ? props.readonly : false}
    ref={textAreaRef}
    name={props.name}
    value={props.value}
    onChange={props.onChange}
    placeholder={props.placeholder}
    className={`input-select-box ${props.className ? props.className : ''}`}
    />
    {
      props.errorDesc &&
      <p className={`error-message ${props.errorLabelStyle ?? ''}`}>
          {props.errorDesc ?? ERROR_TEXTS.REQUIRED_LABEL}
      </p>
  }
  </>
  )
})

export default TextArea
