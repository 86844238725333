import { ERROR_TEXTS } from '../Constants'

export const resDateValidator = (MonthYear: string) => {
  let text = ''
  let isError = false
  const dateSplitted = MonthYear?.split('/')
  if (!MonthYear) {
    text = ERROR_TEXTS.REQUIRED_LABEL
    isError = true
  } else if (MonthYear) {
    if (!MonthYear.includes('/')) {
      text = 'Invalid format. Please use MM/YYYY format'
      isError = true
    } else {
      const month = Number(dateSplitted[0])
      const yr = Number(dateSplitted[1])
      if (isNaN(month) || month < 1 || month > 12) {
        text = 'Invalid month number'
        isError = true
      } else if (isNaN(yr)) {
        text = 'Invalid year. Please use MM/YYYY format'
        isError = true
      } else if (yr < 1000 || yr > new Date().getFullYear()) {
        text = 'Invalid year.'
        isError = true
      }
    }
  }
  return { text, isError }
}
