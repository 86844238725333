import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGraph } from '../../config/axios.config'
import { PREDEFINED_REPORTS_ENUM } from '../../components/reports/predefinedReportMapping'
import { setReceiptientList, type IrouteEmailInitalData } from '../slices/routeEmailSlice'
import { type IReports } from '../slices/reportsSlice'
import { SEND_REPORT } from '../../Constants'

export const getRouteUrl = (reports: IReports): string => {
  const {
    selectedReport, varianceByPlantLocation, glAccount, fullPartialPL, propertyInventoryDetails,
    rangeOfPlantLocation, workAllocationReport2, workAllocationReport1, resReport, reoReport
  } = reports

  switch (selectedReport) {
    case PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP

    case PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION:
      return `${PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION}?month=${varianceByPlantLocation.month}&year=${varianceByPlantLocation.year}`

    case PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT:
      return `${PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT}?glAccount=${glAccount}`

    case PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION:
      return `${PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION}?plantLoc=${fullPartialPL}`

    case PREDEFINED_REPORTS_ENUM.PROPERTY_LIST:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_LIST

    case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL:
      return `${PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL}?plantLoc=${propertyInventoryDetails}`

    case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION

    case PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES:
      return PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES

    case PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS:
    {
      const { first, last } = rangeOfPlantLocation
      const secondArg = last || first
      return `${PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS}?plantLoc1=${first}&plantLoc2=${secondArg}`
    }

    case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2:
      return `${PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2}?workOrderNumber=${workAllocationReport2?.woNumber2}&cost=${workAllocationReport2?.cost2}`

    case PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD:
      return `${PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD}?org=res&startMonthYear=${resReport.startMonthYear}&endMonthYear=${resReport.endMonthYear}`

    case PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD:
      return `${PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD}?org=reo&startMonthYear=${reoReport.startMonthYear}&endMonthYear=${reoReport.endMonthYear}`
    case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1:
      return `${PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1}?workOrderNumber=${workAllocationReport1?.woNumber1}&cost=${workAllocationReport1?.cost}`
    default:
      return ''
  }
}

const getReceiptants = (state: any) => state.routeEmail
const getSelectedReport = (state: any) => state.report

export const sendReport = createAsyncThunk('reports/route', async (mailList: string[], { getState, dispatch, rejectWithValue }) => {
  try {
    // if (validEmail?.length === 0) {
    //   return rejectWithValue('Some of the email Ids are not valid')
    // }
    dispatch(setReceiptientList(mailList))

    const routeInfo: IrouteEmailInitalData = getReceiptants(getState())
    const reportInfo: IReports = getSelectedReport(getState())
    const url = getRouteUrl(reportInfo)
    const responseData = await apiGraph.post(`/${SEND_REPORT}/${url}`,
      {
        receiptants: routeInfo.receiptientList
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    return responseData.data
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      const errorData = error.response.data.message
      return rejectWithValue(Array.isArray(errorData) ? errorData[0] : errorData)
    }
  }
})
