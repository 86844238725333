import React, { type ReactElement } from 'react'
import './TransactionInformation.scss'
import Table from '../../sharedModules/table/Table'
import { type TransactionInfoSortOrderObjectType, setSortedTransactionInfo } from '../../../store/slices/viewPropertySlice'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../store/store'
import TableRowCount from '../../sharedModules/tableRowCount/TableRowCount'
import { transactionListHeadings } from '../../sharedModules/table/TableHeadingMapping'
import { type SORT_DIRECTION, navigationPath } from '../../../types/CommonTypes'

const TransactionInformation = (): ReactElement => {
  const transactionInformation = useSelector((state: any) => state.viewProperty.transactionInformation)
  const transactionInfoSortingOrder = useSelector((state: any) => state.viewProperty.transactionInfoSortingOrder)
  const dispatch = useDispatch<AppDispatch>()

  const doSort = (sortData: Record<string, SORT_DIRECTION>) => {
    dispatch(setSortedTransactionInfo(sortData as TransactionInfoSortOrderObjectType))
  }

  return (
        <div className="transaction-container">
            <h2>Transaction Information</h2>
            <TableRowCount count={transactionInformation?.length} subText="select a record number to view details or sort by column" />
            {
                transactionInformation &&
                // <div className="table-container">
                    <Table data={transactionInformation}
                        headings={transactionListHeadings}
                        onClick={doSort}
                        navigationLinks={[{ path: navigationPath.VIEW_TRANSACTION + '/', linkIndex: 0, linkKey: null }]}
                        sortingOrderObj={transactionInfoSortingOrder}
                        isAllSortingRequired = {true}
                    />
                // </div>
            }

        </div>

  )
}

export default TransactionInformation
