/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { MaintenanceOptionsEnum } from '../components/maintenance/maintenanceOptions'
import { navigationPath } from '../types/CommonTypes'

const useNavigationBasedOnMaintenanceCategory = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams(location.search)
  const dispatch = useDispatch()

  const getDefaultNavigationURL = (defaultParams: any) => {
    let url = ''
    if (defaultParams.page) url += `page=${defaultParams.page}`
    if (defaultParams.limit) url += `&limit=${defaultParams.limit}`
    if (defaultParams.sort) url += `&sort=${defaultParams.sort}`
    if (defaultParams.dir) url += `&dir=${defaultParams.dir}`
    return url
  }

  const navigateTo = (selectedCategory: MaintenanceOptionsEnum, param: Record<string, any>, defaultParams: Record<string, any>) => {
    const defaultNavigationURL = getDefaultNavigationURL(defaultParams)
    switch (selectedCategory) {
      case MaintenanceOptionsEnum.PEOPLE:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.PEOPLE}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.DOCUMENT_TYPE:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.DOCUMENT_TYPE}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.COMPANIES:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.COMPANIES}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.FERC_ACCT:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.FERC_ACCT}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.GL_ACCOUNT:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.GL_ACCOUNT}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.INSTRUMENT_TYPE:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.INSTRUMENT_TYPE}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.ORDER_PROJECT:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.ORDER_PROJECT}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.PLANT_LOCATION:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.PLANT_LOCATION}?${defaultNavigationURL}`)
        return
      case MaintenanceOptionsEnum.ROUTING_ORGANIZATION:
        navigate(`${navigationPath.MAINTENANCE}/${MaintenanceOptionsEnum.ROUTING_ORGANIZATION}?${defaultNavigationURL}`)
        return
      default:
        return ''
    }
  }
  return { navigateTo }
}

export default useNavigationBasedOnMaintenanceCategory
