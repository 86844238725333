import { type IAccountingInformation, type IAddTransaction, type IAddTransactionType, type IErrorData, type IPropertyData } from '../types/TransactionTypes'
import { ERROR_TEXTS, RELINQUISH } from '../Constants'
// import { TRANSACTION_TYPE_SECTION } from '../types/CommonTypes'
import { type IViewTransactionType, type ITransactionView } from '../store/slices/viewTransactionSlice'
import getCurrentDate from './getCurrentDate'
import { TRANSACTION_TYPE_SECTION, VIEW_TRANSACTION_STATUS } from '../types/CommonTypes'
import { getApnsError } from './validateApn'
import { countyMapping } from './countyApnMapping'

// const costValidation = (
//   costInformation: ICostInformation,
//   transactionType: IAddTransactionType | IViewTransactionType
// ) => {
//   const errData: IErrorData = {
//     isError: false,
//     errorDesc: {},
//     propertyDataError: {},
//     defaultErr: ''
//   }
//   // cost validation
//   if (!costInformation.totalConsideration) {
//     errData.errorDesc.totalConsideration = ERROR_TEXTS.TOTAL_CONSIDERATION_IS_REQUIRED
//   }

//   if ('section' in transactionType) {
//     if (transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION && !costInformation.book) {
//       errData.isError = true
//       errData.errorDesc.book = ERROR_TEXTS.BOOK_IS_REQUIRED
//     }
//   }

//   if (!costInformation.sapTotalLaborExpenses) {
//     errData.errorDesc.sapTotalLaborExpenses = ERROR_TEXTS.SAP_ACTUAL_TOTAL_IS_REQUIRED
//   }

//   return errData
// }

const accountingValidation = (
  accountingInformation: IAccountingInformation,
  isPostButtonSubmitted: boolean
) => {
  const errData: IErrorData = {
    isError: false,
    errorDesc: {},
    propertyDataError: [],
    defaultErr: ''
  }
  if (!accountingInformation.recordNo) {
    errData.errorDesc.recordNo = ERROR_TEXTS.RECORD_NO_IS_REQUIRED
  }

  if (!accountingInformation.preparedBy) {
    errData.errorDesc.preparedBy = ERROR_TEXTS.PREPARED_BY_IS_REQUIRED
  }
  if (!accountingInformation.preparedDate) {
    errData.errorDesc.preparedDate = ERROR_TEXTS.PREPARED_DATE_IS_REQUIRED
  }

  if (!accountingInformation.statusDate) {
    errData.errorDesc.statusDate = ERROR_TEXTS.STATUS_DATE_IS_REQUIRED
  }

  if (!accountingInformation.propertyName) {
    errData.errorDesc.propertyName = ERROR_TEXTS.PROPERTY_IS_REQUIRED
  }

  if (!accountingInformation.propId) {
    errData.errorDesc.propId = ERROR_TEXTS.PROPERTY_ID_IS_REQUIRED
  }
  // trans_type is required
  const transType = accountingInformation.transactionType
  if (!transType) {
    errData.errorDesc.transactionType = ERROR_TEXTS.TRANSACTION_TYPE_IS_REQUIRED
  }
  if (!accountingInformation.serialNumber) {
    errData.errorDesc.serialNumber = ERROR_TEXTS.SERIAL_NUMBER_IS_REQUIRED
  }
  if (!accountingInformation.documentNo) {
    errData.errorDesc.documentNo = ERROR_TEXTS.DOCUMENT_NO_IS_REQUIRED
  }

  // validation on date fields
  if (accountingInformation.preparedDate && accountingInformation.preparedDate > getCurrentDate()) {
    errData.errorDesc.preparedDate = { default: 'The prepared date should not have a future date' }
  }

  if (accountingInformation.statusDate && accountingInformation.statusDate > getCurrentDate()) {
    errData.errorDesc.statusDate = { default: 'The status date should not have a future date' }
  }

  if (accountingInformation.executionDate && accountingInformation.executionDate > getCurrentDate()) {
    errData.errorDesc.executionDate = { default: 'The execution date should not have a future date' }
  }
  if (
    accountingInformation.workOrderClosedDate &&
    accountingInformation.workOrderClosedDate > getCurrentDate()
  ) {
    errData.errorDesc.workOrderClosedDate = { default: 'The WO closed date should not have a future date' }
  }
  if (accountingInformation.recordedDate && accountingInformation.recordedDate > getCurrentDate()) {
    errData.errorDesc.recordedDate = { default: 'The recorded date should not have a future date' }
  }
  if (accountingInformation.transactionStatus === VIEW_TRANSACTION_STATUS.POST ||
  accountingInformation.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED || isPostButtonSubmitted) {
    if (!accountingInformation.grantee) {
      errData.errorDesc.grantee = ERROR_TEXTS.GRANTEE_IS_REQUIRED
    }

    if (!accountingInformation.grantor) {
      errData.errorDesc.grantor = ERROR_TEXTS.GRANTOR_IS_REQUIRED
    }

    if (!accountingInformation.instrumentTitle) {
      errData.errorDesc.instrumentTitle = ERROR_TEXTS.INSTRUMENT_TITLE_IS_REQUIRED
    }
    if (!accountingInformation.recordedDate) {
      errData.errorDesc.recordedDate = ERROR_TEXTS.RECORDED_DATE_IS_REQUIRED
    }
    if (!accountingInformation.executionDate) {
      errData.errorDesc.executionDate = ERROR_TEXTS.EXECUTION_DATE_IS_REQUIRED
    }
    if (!accountingInformation.documentType) {
      errData.errorDesc.documentType = ERROR_TEXTS.DOCUMENT_TYPE_IS_REQUIRED
    }
    if (!accountingInformation.agent) {
      errData.errorDesc.agent = ERROR_TEXTS.AGENT_IS_REQUIRED
    }
    if (!accountingInformation.recordingInstrumentNo) {
      errData.errorDesc.recordingInstrumentNo = ERROR_TEXTS.RECORDERS_NUMBER_IS_REQUIRED
    }
    if (!accountingInformation.county) {
      errData.errorDesc.county = ERROR_TEXTS.COUNTY_IS_REQUIRED
    }
    if (!accountingInformation.apn) {
      errData.errorDesc.apn = ERROR_TEXTS.APN_IS_REQUIRED
    } else if (accountingInformation.county) {
      const apnError: string | undefined = getApnsError(accountingInformation.apn, countyMapping(accountingInformation))
      if (apnError) {
        errData.errorDesc.apn = { default: apnError }
      }
    }
    if (!accountingInformation.workOrderNumber) {
      errData.errorDesc.workOrderNumber = ERROR_TEXTS.WO_IS_REQUIRED
    }
  } else {
    if (accountingInformation.county && accountingInformation.apn?.length > 0) {
      const apnError: string | undefined = getApnsError(accountingInformation.apn, countyMapping(accountingInformation))
      if (apnError) {
        errData.errorDesc.apn = { default: apnError }
      }
    }
  }

  // 20.Execution date < Recorded date < WO closed date
  const { executionDate, workOrderClosedDate, recordedDate } = accountingInformation
  if (executionDate && workOrderClosedDate && recordedDate) {
    if (executionDate > recordedDate) {
      errData.errorDesc.executionDate = { default: 'The execution date should not exceed the recorded date' }
    }
    if (recordedDate > workOrderClosedDate) {
      errData.errorDesc.recordedDate = { default: 'The recorded date should not exceed the WO closed date' }
    }
  }
  if (executionDate && recordedDate && !workOrderClosedDate) {
    if (executionDate > recordedDate) {
      errData.errorDesc.executionDate = { default: 'The execution date should not exceed the recorded date' }
    }
  }
  if (executionDate && !recordedDate && workOrderClosedDate) {
    if (executionDate > workOrderClosedDate) {
      errData.errorDesc.executionDate = { default: 'The execution date should not exceed the WO closed date' }
    }
  }
  if (!executionDate && recordedDate && workOrderClosedDate) {
    if (recordedDate > workOrderClosedDate) {
      errData.errorDesc.recordedDate = { default: 'The recorded date should not exceed the WO closed date' }
    }
  }

  return errData
}

const propertyDataValidationOnSave = (
  propertyData: IPropertyData,
  transactionType: IAddTransactionType | IViewTransactionType,
  accountingInformation: IAccountingInformation
) => {
  const errData: IErrorData = {
    isError: false,
    errorDesc: {},
    propertyDataError: [],
    defaultErr: ''
  }
  const pDataError: any[] = []
  if (propertyData.fieldData) {
    propertyData.fieldData.forEach((value, index) => {
      if ((transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION) || (accountingInformation.transactionType === RELINQUISH)) {
        if (value.acqDocNo.length > 6) {
          errData.errorDesc.acqDocNo = ERROR_TEXTS.ACQ_DOC_LENGTH_CHECK
          pDataError[index] = { ...pDataError[index], acqDocNo: ERROR_TEXTS.ACQ_DOC_LENGTH_CHECK }
        }
      }
      if ((!value.acres || Number(value.acres) === 0) &&
        (transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION || accountingInformation.transactionType !== RELINQUISH)) {
        errData.errorDesc.acres = ERROR_TEXTS.ACRES_IS_REQUIRED
        pDataError[index] = { ...pDataError[index], acres: ERROR_TEXTS.ACRES_IS_REQUIRED }
      }
      // if (transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION || accountingInformation.transactionType !== RELINQUISH) {
      //   if (Number(value.amount) <= 0) {
      //     errData.errorDesc.amount = ERROR_TEXTS.AMOUNT_IS_REQUIRED
      //     pDataError[index] = { ...pDataError[index], amount: ERROR_TEXTS.AMOUNT_IS_REQUIRED }
      //   }
      // }
    })
    errData.propertyDataError = pDataError
  }
  return errData
}
const propertyDataValidation = (
  propertyData: IPropertyData,
  transactionType: IAddTransactionType | IViewTransactionType,
  accountingInformation: IAccountingInformation
) => {
  const errData: IErrorData = {
    isError: false,
    errorDesc: {},
    propertyDataError: [],
    defaultErr: ''
  }
  const pDataError: any[] = []
  if (propertyData.fieldData) {
    propertyData.fieldData.forEach((value, index) => {
      if ('section' in transactionType || accountingInformation.transactionType) {
        if ((transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION) || (accountingInformation.transactionType === RELINQUISH)) {
          if (!value.acqDocNo) {
            errData.errorDesc.acqDocNo = ERROR_TEXTS.ACQ_DOC_IS_REQUIRED
            pDataError[index] = { ...pDataError[index], acqDocNo: ERROR_TEXTS.ACQ_DOC_IS_REQUIRED }
          }
          if (value.acqDocNo.length > 6) {
            errData.errorDesc.acqDocNo = ERROR_TEXTS.ACQ_DOC_LENGTH_CHECK
            pDataError[index] = { ...pDataError[index], acqDocNo: ERROR_TEXTS.ACQ_DOC_LENGTH_CHECK }
          }
        }
        if (transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION || (accountingInformation.transactionType !== RELINQUISH)) {
          if (!value.ownership) {
            errData.errorDesc.ownership = ERROR_TEXTS.FEE_EASE_IS_REQUIRED
            pDataError[index] = { ...pDataError[index], ownership: ERROR_TEXTS.FEE_EASE_IS_REQUIRED }
          }
          if (!value.acres || Number(value.acres) === 0) {
            errData.errorDesc.acres = ERROR_TEXTS.ACRES_IS_REQUIRED
            pDataError[index] = { ...pDataError[index], acres: ERROR_TEXTS.ACRES_IS_REQUIRED }
          }
          // if (transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION || accountingInformation.transactionType !== RELINQUISH) {
          //   if (Number(value.amount) <= 0) {
          //     errData.errorDesc.amount = ERROR_TEXTS.AMOUNT_IS_REQUIRED
          //     pDataError[index] = { ...pDataError[index], amount: ERROR_TEXTS.AMOUNT_IS_REQUIRED }
          //   }
          // }
        }
      }
      if (!value.parcelNumber) {
        errData.errorDesc.parcelNumber = ERROR_TEXTS.PARCEL_IS_REQUIRED
        pDataError[index] = { ...pDataError[index], parcelNumber: ERROR_TEXTS.PARCEL_IS_REQUIRED }
      }

      if (!value.plantLoc) {
        errData.errorDesc.plantLoc = ERROR_TEXTS.PLANT_LOC_IS_REQUIRED
        pDataError[index] = { ...pDataError[index], plantLoc: ERROR_TEXTS.PLANT_LOC_IS_REQUIRED }
      }
      if (!value.glAcct) {
        errData.errorDesc.glAcct = ERROR_TEXTS.GL_ACCT_REQUIRED
        pDataError[index] = { ...pDataError[index], glAcct: ERROR_TEXTS.GL_ACCT_REQUIRED }
      }
      if (!value.fercAcct) {
        errData.errorDesc.fercAcct = ERROR_TEXTS.FERC_ACCT_IS_REQUIRED
        pDataError[index] = { ...pDataError[index], fercAcct: ERROR_TEXTS.FERC_ACCT_IS_REQUIRED }
      }
    })
    errData.propertyDataError = pDataError
  }

  return errData
}

const formValidation = (
  transactionInfo: IAddTransaction | ITransactionView,
  isPostButtonSubmitted: boolean
) => {
  const onSaveValidation: IErrorData = {
    isError: false,
    errorDesc: {},
    propertyDataError: [],
    defaultErr: ''
  }

  const {
    accountingInformation,
    // costInformation,
    transactionType,
    propertyData
  } = transactionInfo
  const accountingError: IErrorData = accountingValidation(accountingInformation, isPostButtonSubmitted).errorDesc
  const propertyDataErrorOnSave: IErrorData = propertyDataValidationOnSave(propertyData, transactionType, accountingInformation)

  if (accountingInformation.transactionStatus === VIEW_TRANSACTION_STATUS.POST ||
    accountingInformation.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED || isPostButtonSubmitted) {
    const propertyDataError: IErrorData = propertyDataValidation(propertyData, transactionType, accountingInformation)
    onSaveValidation.errorDesc = {
      ...accountingError,
      ...propertyDataError.errorDesc
    }
    onSaveValidation.propertyDataError = [
      ...onSaveValidation.propertyDataError,
      ...propertyDataError.propertyDataError
    ]
  } else {
    onSaveValidation.errorDesc = {
      ...accountingError,
      ...propertyDataErrorOnSave.errorDesc
    }
    onSaveValidation.propertyDataError = [
      ...onSaveValidation.propertyDataError,
      ...propertyDataErrorOnSave.propertyDataError
    ]
  }

  console.log(JSON.stringify(onSaveValidation))
  if (Object.values(onSaveValidation.errorDesc).length > 0 ||
   onSaveValidation.propertyDataError.length > 0
  ) {
    onSaveValidation.isError = true
    onSaveValidation.defaultErr = ERROR_TEXTS.DEFAULT_ERROR
  }
  return onSaveValidation
}

export default formValidation
