/**
 * Creates APN exception message for given parameters.
 * @param input Single APN value to check
 * @param format Format to validate.
 * @param index Index of illegal character.
 * @returns Exception message with input and format.
 */
const apnExceptionMessageDigit = (input: string, format: string, index: number): string => {
  return 'APN "' + input + '" does not match format "' + format + '". Digit is expected at character index ' + (index + 1)
}

const apnExceptionMessageLetter = (input: string, format: string, index: number): string => {
  return 'APN "' + input + '" does not match format "' + format + '". Letter is expected at character index ' + (index + 1)
}

const apnExceptionMessageMix = (input: string, format: string, index: number): string => {
  return 'APN "' + input + '" does not match format "' + format + '". Digit/Letter is expected at character index ' + (index + 1)
}

const apnExceptionMessageHyphen = (input: string, format: string, index: number): string => {
  return 'APN "' + input + '" does not match format "' + format + '". Hyphen is expected at character index ' + (index + 1)
}

/**
 * Makes sure that given input is a valid single APN string in a given '###-##-###' format.
 * @param input Single APN value to check
 * @param format Format to validate.
 * @returns input value if all APNs are valid.
 * @throws exception if one of APNs are invalid.
 */
export const validateApn = (input: string, format: string): string => {
  const allowedApnDigitsRegex = /[0-9]+/
  const allowedApnAlphaRegex = /[a-zA-Z]+/

  if (input.length !== format.length) {
    throw Error('APN "' + input + '" does not match format "' + format + '"')
  }

  for (let i = 0; i < input.length; i++) {
    if (format.charAt(i) === '#') {
      if (!allowedApnDigitsRegex.test(input.charAt(i))) {
        throw Error(apnExceptionMessageDigit(input, format, i))
      }
    } else if (format.charAt(i) === 'X') {
      if (!allowedApnAlphaRegex.test(input.charAt(i))) {
        throw Error(apnExceptionMessageLetter(input, format, i))
      }
    } else if (format.charAt(i) === 'Y') {
      if (
        !allowedApnAlphaRegex.test(input.charAt(i)) &&
        !allowedApnDigitsRegex.test(input.charAt(i))
      ) {
        throw Error(apnExceptionMessageMix(input, format, i))
      }
    } else if (format.charAt(i) === '-') {
      if (input.charAt(i) !== '-') {
        throw Error(apnExceptionMessageHyphen(input, format, i))
      }
    } else {
      throw Error('Unexpected format character at character index ' + i + ' in "' + format + '"')
    }
  }
  return input
}

/**
 * Parses given input as a string consisting from comma-separated values in a given '###-##-###' format.
 * @param input Comma-separated APNs string.
 * @param format Format, example: '###-##-###'.
 * @returns input value if all APNs are valid.
 * @throws exception if one of APNs are invalid.
 */
export const validateApns = (input: string, format: string): string => {
  input = input.trim()
  if (input.length === 0) {
    return ''
  }
  const values: string[] = input.split(',').map((value) => value.trim())
  const output: string[] = []
  for (let i = 0; i < values.length; i++) {
    const value = values[i]
    if (value === '') {
      throw Error('Enter APN number in "' + format + '" format or remove ","')
    }
    output.push(validateApn(value, format))
  }
  return output.join(',')
}

/**
 * Validates that the given input as a string consisting from comma-separated values in a given '###-##-###' format.
 * @param input Comma-separated APNs string.
 * @param format Format, example: '###-##-###'.
 * @returns Undefined if APNs string is correct, error description if the APNs string is invalid.
 */
export const getApnsError = (input: string, format: string): string | undefined => {
  try {
    validateApns(input, format)
    return undefined
  } catch (e) {
    return (e as any as Error).message
  }
}

export const reformatApnForPdf = (apn: string) => {
  const words = apn.split(',')
  const threeWords: any = []
  const length = words.length
  for (let ind = 2; ind < length; ind += 3) {
    threeWords.push(words[ind - 2] + ', ' + words[ind - 1] + ', ' + words[ind] + ', ')
  }
  if (length % 3 === 1) {
    threeWords.push(words[length - 1] + '. ')
  } else if (length % 3 === 2) {
    threeWords.push(words[length - 2] + ', ' + words[length - 1] + ',')
  }

  return threeWords
  // return apn.split(/[\s,]+/).join('\n')
}
