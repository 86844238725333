/* eslint-disable no-case-declarations */
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { PREDEFINED_REPORTS_ENUM } from '../components/reports/predefinedReportMapping'
import { navigationPath } from '../types/CommonTypes'
import { useDispatch } from 'react-redux'
import { setFullPartialPL, setGLAccount, setPropInventoryDetails, setRangeOfPlantLocation, setReoReportParameter, setResReportParameter, setVarianceByPlantLocation, setWorkAllocationReport1, setWorkAllocationReport2 } from '../store/slices/reportsSlice'

const useNavigateBasedOnReports = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams(location.search)
  const dispatch = useDispatch()

  const getDefaultNavigationURL = (defaultParams: any) => {
    let url = ''
    if (defaultParams.page) url += `page=${defaultParams.page}`
    if (defaultParams.limit) url += `&limit=${defaultParams.limit}`
    if (defaultParams.sort) url += `&sort=${defaultParams.sort}`
    if (defaultParams.dir) url += `&dir=${defaultParams.dir}`
    return url
  }

  const navigateTo = (selectedReport: PREDEFINED_REPORTS_ENUM, param: Record<string, any>, defaultParams: Record<string, any>) => {
    const defaultNavigationURL = getDefaultNavigationURL(defaultParams)
    switch (selectedReport) {
      case PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP}?${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION}?month=${param.varianceByPlantLocation.month}&year=${param.varianceByPlantLocation.year}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT}?glAccount=${param.glAccount}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION}?plantLoc=${param.fullPartialPL}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.PROPERTY_LIST:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.PROPERTY_LIST}?${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL}?plantLoc=${param.propertyInventoryDetails}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION}?${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES}?${defaultNavigationURL}`)
        return
      case PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS:
      {
        const { first, last } = param.rangeOfPlantLocation
        const secondArg = last || first
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS}?plantLoc1=${first}&plantLoc2=${secondArg}&${defaultNavigationURL}`)
        return
      }

      case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2}?workOrderNumber=${param.workAllocationReport2.woNumber2}&cost=${param.workAllocationReport2.cost2}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD}?org=res&startMonthYear=${param.resReport.startMonthYear}&endMonthYear=${param.resReport.endMonthYear}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD}?org=reo&startMonthYear=${param.reoReport.startMonthYear}&endMonthYear=${param.reoReport.endMonthYear}&${defaultNavigationURL}`)
        return

      case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1:
        navigate(`${navigationPath.REPORTS}/${PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1}?workOrderNumber=${param.workAllocationReport1.woNumber1}&cost=${param.workAllocationReport1.cost}&${defaultNavigationURL}`)
        return
      default:
        return ''
    }
  }

  const updateParamsBasedOnURL = (params: any, selectedReport: PREDEFINED_REPORTS_ENUM): any => {
    const updatedParams = { ...params }
    switch (selectedReport) {
      case PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION:
        const month = searchParams.get('month')
        const year = searchParams.get('year')
        if (month && year && params.varianceByPlantLocation.month !== month && params.varianceByPlantLocation.year !== year) {
          updatedParams.varianceByPlantLocation = { month, year }
          dispatch(setVarianceByPlantLocation({ month, year }))
        }
        break

      case PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT:
        const glAccount = searchParams.get('glAccount')
        if (glAccount && params.glAccount !== glAccount) {
          updatedParams.glAccount = glAccount
          dispatch(setGLAccount(glAccount))
        }
        break

      case PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS:
        const firstPl = searchParams.get('plantLoc1')
        const lastPl = searchParams.get('plantLoc2')
        if (firstPl && lastPl && params.rangeOfPlantLocation.first !== firstPl && params.rangeOfPlantLocation.last !== lastPl) {
          updatedParams.rangeOfPlantLocation = { first: firstPl, last: lastPl }
          dispatch(setRangeOfPlantLocation({ first: firstPl, last: lastPl }))
        }
        break

      case PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION:
        const plantLoc = searchParams.get('plantLoc')
        if (plantLoc && params.fullPartialPL !== plantLoc) {
          updatedParams.fullPartialPL = plantLoc
          dispatch(setFullPartialPL(plantLoc))
        }
        break

      case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL:
        const prPlantLoc = searchParams.get('plantLoc')
        if (prPlantLoc && params.propertyInventoryDetails !== prPlantLoc) {
          updatedParams.propertyInventoryDetails = prPlantLoc
          dispatch(setPropInventoryDetails(prPlantLoc))
        }
        break

      case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1:
        const workOrderNumber1 = searchParams.get('workOrderNumber')
        const cost1 = searchParams.get('cost')
        if (workOrderNumber1 && cost1 && params.workAllocationReport1.woNumber1 !== workOrderNumber1 && params.workAllocationReport1.cost !== cost1) {
          updatedParams.workAllocationReport1 = { woNumber1: workOrderNumber1, cost: cost1 }
          dispatch(setWorkAllocationReport1({ woNumber1: workOrderNumber1, cost: cost1 }))
        }
        break

      case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2:
        const workOrderNumber = searchParams.get('workOrderNumber')
        const cost2 = searchParams.get('cost')
        if (workOrderNumber && cost2 && params.workAllocationReport2.woNumber2 !== workOrderNumber && params.workAllocationReport2.cost2 !== cost2) {
          updatedParams.workAllocationReport2 = { woNumber2: workOrderNumber, cost2 }
          dispatch(setWorkAllocationReport2({ woNumber2: workOrderNumber, cost2 }))
        }
        break

      case PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD:
        const startMonthYear1 = searchParams.get('startMonthYear')
        const endMonthYear1 = searchParams.get('endMonthYear')
        if (startMonthYear1 && endMonthYear1 && params.resReport.startMonthYear !== startMonthYear1 && params.resReport.endMonthYear !== endMonthYear1) {
          updatedParams.resReport = { startMonthYear: startMonthYear1, endMonthYear: endMonthYear1 }
          dispatch(setResReportParameter({ startMonthYear: startMonthYear1, endMonthYear: endMonthYear1 }))
        }
        break

      case PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD:
        const startMonthYear = searchParams.get('startMonthYear')
        const endMonthYear = searchParams.get('endMonthYear')
        if (startMonthYear && endMonthYear && params.reoReport.startMonthYear !== startMonthYear && params.reoReport.endMonthYear !== endMonthYear) {
          updatedParams.reoReport = { startMonthYear, endMonthYear }
          dispatch(setReoReportParameter({ startMonthYear, endMonthYear }))
        }
        break

      default:
    }

    return updatedParams
  }

  return { navigateTo, updateParamsBasedOnURL }
}

export default useNavigateBasedOnReports
