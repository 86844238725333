import React, { useRef, useState, useEffect } from 'react'
import { ReactComponent as RightArrow } from '../../../assets/rightArrowPagination.svg'
import { ReactComponent as LeftArrow } from '../../../assets/leftArrowPagination.svg'
import './Pagination.scss'
import { RESULT_PER_PAGE_PAGINATION } from '../../../Constants'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getParamsBasedonURL } from '../../../util/getTableParamsBasedonURL'
import Input from '../input/Input'

const ONLY_NUMBERS_REGEX = /^[0-9]{0,}$/ // new RegExp('^[0-9]{0,}$')

interface PaginationProps {
  totalPages: number
  resetPages?: any
  params?: Record<any, any>
}
const Pagination: React.FC<PaginationProps> = ({ resetPages, totalPages }) => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(location.search)
  const params = getParamsBasedonURL(searchParams)
  const [currentPage, setCurrentPage] = useState(1)
  const maxPageToShow = 3
  const startPage = useRef(0)
  const endPage = useRef(0)
  const [editPageNumberDraft, setEditPageNumberDraft] = useState(String(currentPage))

  useEffect(() => {
    if (params.page !== currentPage) {
      setCurrentPage(params.page)
      updateParams(params.page)
      setEditPageNumberDraft(String(params.page))
    }
  }, [resetPages])

  const updateParams = (page: number) => {
    searchParams.set('page', String(page))
    searchParams.set('limit', params.limit ?? String(RESULT_PER_PAGE_PAGINATION))
    searchParams.delete('forceRefresh')
    setSearchParams(searchParams)
  }

  // This function detects page 'unload' event due to refresh action, resets pagination to 'page=1' and
  // remembers the forced refresh page with 'page=1' value that a user was on.
  const onBeforePageUnload = (e: any) => {
    const p = document.location.pathname
    const isPaginationPage = (p === '/transactions') || (p === '/properties') || (p === '/reports') || (p.indexOf('/reports/') === 0) || (p.indexOf('/maintenance/') === 0)
    if (!isPaginationPage) {
      console.log('Pages: not a pagination page, do nothing')
      return
    }
    const uriWithPage1 = rememberLastPageState(1)
    localStorage.pageRefreshNewUrlDetected = uriWithPage1
  }

  // Checks forced refresh page, redirects if needed or
  // sets up 'beforeunload' handler which will remember if someone pressed a refresh button.
  // Note: down side of this approach is that it is impossible to modify page HTTP param in browser URL by hand
  useEffect(() => {
    const newForceRefreshPage = localStorage.pageRefreshNewUrlDetected
    if (newForceRefreshPage) {
      localStorage.pageRefreshNewUrlDetected = ''
      window.location.href = newForceRefreshPage
      return
    }
    // The onunload handler is not needed if we detected page=1 redirect above
    window.addEventListener('beforeunload', onBeforePageUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforePageUnload)
    }
  }, [])

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
      updateParams(newPage)
      setEditPageNumberDraft(String(newPage))
    }
  }

  function rememberLastPageState (forcePage?: any) {
    const urlParams = new URLSearchParams(document.location.search)
    const state: any = {}
    const redirectUri = [document.location.pathname + '?']
    urlParams.forEach((v, k) => {
      state[k] = v
      if (k !== 'page' && k !== 'dir' && k !== 'sort' && k !== 'limit' && k !== 'forceRefresh') {
        redirectUri.push(k)
        redirectUri.push('=')
        redirectUri.push(encodeURIComponent(v))
        redirectUri.push('&')
      }
    })
    state.____pathname = document.location.pathname
    state.page = forcePage ? ('' + forcePage) : (urlParams.get('page') ?? '1')
    state.limit = urlParams.get('limit') ?? '100'
    state.sort = urlParams.get('sort') ?? ''
    state.dir = urlParams.get('dir') ?? 'ASC'
    localStorage.lastPageState = JSON.stringify(state)
    redirectUri.push('page=1&forceRefresh=1')
    return redirectUri.join('')
  }
  rememberLastPageState()

  const dotsPageLeft = () => {
    let step = Math.ceil(totalPages * 10 / 100)
    step = step < 1 ? 1 : step
    let newPage
    if ((currentPage - step) < 1) {
      newPage = 2
    } else {
      newPage = currentPage - step
    }
    handlePageChange(newPage)
  }

  const dotsPageRight = () => {
    let step = Math.ceil(totalPages * 10 / 100)
    step = step < 1 ? 1 : step
    let newPage
    if ((currentPage + step) > totalPages) {
      newPage = totalPages - 1
    } else {
      newPage = currentPage + step
    }
    handlePageChange(newPage)
  }

  const renderPrevIcon = () => {
    if (currentPage === 1) {
      return null // Hide prev icon when on first page
    }
    return (
      <button className='prev-btn' onClick={() => { handlePageChange(currentPage - 1) }}><LeftArrow /></button>
    )
  }

  const renderNextIcon = () => {
    if (currentPage === totalPages) {
      return null // Hide next icon when on last page
    }
    return (
      <button className="next-btn" onClick={() => { handlePageChange(currentPage + 1) }}><RightArrow /></button>
    )
  }

  const renderFirstPage = () => {
    const pagination = []
    if (currentPage < maxPageToShow || totalPages <= maxPageToShow) {
      return null
    }
    pagination.push(<button className={(currentPage === 1) ? 'pagination-btn pagination-btn-active' : 'pagination-btn'} key={1} onClick={() => { handlePageChange(1) }}>{1}</button>)
    // if (startPage.current > 2) {
    if (currentPage > 3) {
      pagination.push(<button className={'pagination-btn pagination-ellipse'} key={'ellipse-first-page'} onClick={() => { dotsPageLeft() }} >...</button>)
    }
    return pagination
  }

  const renderLastPage = () => {
    const pagination = []
    if (currentPage > totalPages - 2 || totalPages <= maxPageToShow) {
      return null
    }
    // if (endPage.current < totalPages - 1) {
    if (currentPage < totalPages - 2) {
      pagination.push(<button className={'pagination-btn pagination-ellipse'} key={totalPages} onClick={() => { dotsPageRight() }} >...</button>)
    }
    pagination.push(<button className={(totalPages === currentPage) ? 'pagination-btn pagination-btn-active' : 'pagination-btn'} key={'ellipse-last-page'} onClick={() => { handlePageChange(totalPages) }}>{totalPages}</button>)
    return pagination
  }

  const renderPaginationOptions = () => {
    const pagesToShow = Math.min(totalPages, maxPageToShow)
    startPage.current = Math.max(1, Math.min(currentPage - Math.floor(pagesToShow / 2), totalPages - pagesToShow + 1))
    endPage.current = Math.min(totalPages, startPage.current + pagesToShow - 1)
    const paginationOptions = []
    for (let i = startPage.current; i <= endPage.current; i++) {
      paginationOptions.push(<button className={(i === currentPage) ? 'pagination-btn pagination-btn-active' : 'pagination-btn'} key={i} onClick={() => { handlePageChange(i) }}>{i}</button>)
    }
    return paginationOptions
  }

  const inputPageField = () => {
    return (
      <>
        <label className="input-label">Page: </label>
        <Input
          name="page-number"
          value={editPageNumberDraft}
          onChange={(e) => {
            if (ONLY_NUMBERS_REGEX.test(e.target.value)) {
              setEditPageNumberDraft(e.target.value)
            }
          }}
          onKeyUp={(e: React.KeyboardEvent) => {
            /* eslint-disable eqeqeq */
            if (e.key != 'Enter') {
              return
            }
            e.preventDefault()
            e.cancelable = true
            if (editPageNumberDraft.length > 0) {
              handlePageChange(Number(editPageNumberDraft))
            }
          }}
          inputType="string"
          placeholder=""
          className="input"
        />
      </>
    )
  }

  return (
    <section className='pagination-container'>
      {renderPrevIcon()}
      {renderFirstPage()}
      {renderPaginationOptions()}
      {renderLastPage()}
      {renderNextIcon()}
      {inputPageField()}
    </section>
  )
}

export default Pagination
