import { useMsal } from '@azure/msal-react'

const useGetLoggedInUser = () => {
  const { accounts } = useMsal()
  const nameSplitted = accounts[0]?.name?.split(' ')
  let firstName = ''
  let lastName = ''
  if (nameSplitted) {
    for (let i = 0; i < nameSplitted?.length; i++) {
      lastName = i === (nameSplitted?.length - 1) ? nameSplitted[i] : ''
      firstName += i < (nameSplitted?.length - 1) ? `${nameSplitted[i]} ` : ''
    }
  }
  const name = `${lastName}, ${firstName}`
  return name ?? ''
}

export default useGetLoggedInUser
