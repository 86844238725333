import { createAsyncThunk } from '@reduxjs/toolkit'
import { type MaintenanceOptionsEnum } from '../../components/maintenance/maintenanceOptions'
import api from '../../config/axios.config'
import { GET_MAINTENANCE } from '../../Constants'

const getMaintenanceState = (state: any) => {
  // const { searchResultSuggestions, searchToken } = state.maintenance
  // // eslint-disable-next-line eqeqeq
  // const result = searchResultSuggestions.filter((item: Record<string, string>) => item.name == searchToken)
  // console.log('search token ---', searchToken, result[0].id)
  return state.maintenance
}
export const getSortedMaintenanceData = createAsyncThunk(
  'get-sorted', async ({ selectedCategory, defaultParams }:
  { selectedCategory: MaintenanceOptionsEnum, defaultParams: Record<string, any> }, { rejectWithValue, getState }) => {
    try {
      const stateData = getMaintenanceState(getState())
      const { searchResultSuggestions, searchToken } = stateData
      // eslint-disable-next-line eqeqeq
      const result = searchResultSuggestions.filter((item: Record<string, string>) => item.name == searchToken)
      const params = {
        ...defaultParams,
        id: result[0]?.id
      }

      const responseData = await api.get(`/${GET_MAINTENANCE}/sort/${selectedCategory}`, {
        params
      })
      if (!responseData.data) throw new Error('No response data')
      return responseData.data
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorData = error.response.data.message
        return rejectWithValue(Array.isArray(errorData) ? errorData[0] : errorData)
      }
    }
  })
