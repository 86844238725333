import React, { useEffect, useState } from 'react'
import './PredefinedReports.scss'
import Card from '../sharedModules/card/Card'
import RadioButton from '../sharedModules/RadioButton/RadioButton'
import Input from '../sharedModules/input/Input'
import Button from '../sharedModules/button/Button'
import {
  PREDEFINED_REPORTS_ENUM,
  reportDataMaster
} from './predefinedReportMapping'
import { useDispatch, useSelector } from 'react-redux'
import {
  setErrorData,
  resetReportInputFields,
  setGLAccount,
  setPredefinedReport,
  setPropInventoryDetails,
  setRangeOfPlantLocation,
  setWorkAllocationReport1,
  setWorkAllocationReport2,
  setVarianceByPlantLocation,
  setFullPartialPL,
  setResReportParameter,
  setReoReportParameter
} from '../../store/slices/reportsSlice'
import { type AppDispatch } from '../../store/store'
import { ERROR_TEXTS } from '../../Constants'
import { useLocation } from 'react-router-dom'
import { getParamsBasedonURL } from '../../util/getTableParamsBasedonURL'
import useNavigateBasedOnReports from '../../customHooks/useNavigateBasedOnReports'
import { replaceAlphabets } from '../../util/sanitizeString'
import { resDateValidator } from '../../util/reportParamValidation'
import { NumericFormat } from 'react-number-format'

const PredefinedReports = (props: { onClose: () => any }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const dispatch = useDispatch<AppDispatch>()
  const { navigateTo } = useNavigateBasedOnReports()
  const {
    selectedReport,
    workAllocationReport1,
    workAllocationReport2,
    glAccount,
    rangeOfPlantLocation,
    propertyInventoryDetails,
    varianceByPlantLocation,
    fullPartialPL,
    errorData,
    resReport,
    reoReport
  } = useSelector((state: any) => state.report)
  const [selectedItem, setSelectedItem] = useState<PREDEFINED_REPORTS_ENUM>(selectedReport)
  const params = { glAccount, workAllocationReport1, workAllocationReport2, rangeOfPlantLocation, propertyInventoryDetails, fullPartialPL, varianceByPlantLocation, reoReport, resReport }

  useEffect(() => {
    validateBeforeSubmit()
  }, [workAllocationReport1,
    workAllocationReport2,
    glAccount,
    rangeOfPlantLocation,
    propertyInventoryDetails,
    fullPartialPL, varianceByPlantLocation, resReport, reoReport])

  const onFormSubmitHandler = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const onSelectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItem(event?.target.value as PREDEFINED_REPORTS_ENUM)
    dispatch(resetReportInputFields())
  }

  const onCloseHandler = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    props.onClose()
  }

  const setErrorStyleAndMessage = (fieldName: string) => {
    const { errorDesc } = errorData
    const StyleAndText = {
      errorStyle: 'read-input',
      errorText: ''
    }
    if (errorDesc && errorDesc[fieldName]) {
      StyleAndText.errorStyle = 'error-input'
      StyleAndText.errorText = errorDesc[fieldName]
    }
    return StyleAndText
  }

  const validateBeforeSubmit = () => {
    let { errorDesc, isError } = errorData
    switch (selectedItem) {
      case PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS:
      case PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION:
      case PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL:
        {
          const { first } = params.rangeOfPlantLocation
          const param = first || fullPartialPL || propertyInventoryDetails
          if (!param) {
            errorDesc = { plantLoc: ERROR_TEXTS.REQUIRED_LABEL }
            isError = true
          } else {
            errorDesc = { plantLoc: '' }
            isError = false
          }
        }
        break
      case PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION:
        {
          const { month, year } = params.varianceByPlantLocation
          if (isNaN(year) && year !== '') {
            errorDesc = { year: ERROR_TEXTS.INVALID_YEAR }
            isError = true
          } else if (isNaN(month) && year !== '') {
            errorDesc = { month: ERROR_TEXTS.INVALID_MONTH }
            isError = true
          } else if ((month > 12 || month < 1) && month !== '') {
            errorDesc = { month: ERROR_TEXTS.INVALID_MONTH }
            isError = true
          } else if (year < 1000 && year !== '') {
            errorDesc = { year: ERROR_TEXTS.INVALID_YEAR }
            isError = true
          } else if (year > new Date().getFullYear() && year !== '') {
            errorDesc = { year: ERROR_TEXTS.INVALID_YEAR }
            isError = true
          } else {
            errorDesc = { month: '', year: '' }
            isError = false
          }
        }
        break
      case PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT:
        {
          const { glAccount } = params
          if (!glAccount) {
            errorDesc = { glAccount: ERROR_TEXTS.REQUIRED_LABEL }
            isError = true
          } else {
            errorDesc = { glAccount: '' }
            isError = false
          }
        }
        break
      case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1:
        {
          const workOrder = params.workAllocationReport1.woNumber1
          const cost = params.workAllocationReport1.cost
          if (!workOrder) {
            errorDesc = { woNumber1: ERROR_TEXTS.REQUIRED_LABEL }
            isError = true
          } else if (!cost) {
            if (!isNaN(cost)) errorDesc = { cost: 'Invalid number' }
            else errorDesc = { cost: ERROR_TEXTS.REQUIRED_LABEL }
            isError = true
          } else {
            errorDesc = { woNumber1: '', cost: '' }
            isError = false
          }
        }
        break
      case PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2:
        {
          const workOrder = params.workAllocationReport2.woNumber2
          const cost = params.workAllocationReport2.cost2
          if (!workOrder) {
            errorDesc = { woNumber2: ERROR_TEXTS.REQUIRED_LABEL }
            isError = true
          } else if (!cost) {
            if (!isNaN(cost)) errorDesc = { cost2: 'Invalid number' }
            else errorDesc = { cost2: ERROR_TEXTS.REQUIRED_LABEL }
            isError = true
          } else {
            errorDesc = { woNumber2: '', cost2: '' }
            isError = false
          }
        }
        break
      case PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD:
        {
          errorDesc = {}
          isError = false
          const { startMonthYear, endMonthYear } = params.resReport
          const text1 = resDateValidator(startMonthYear).text
          const isFirstParamError = resDateValidator(startMonthYear).isError
          if (isFirstParamError) {
            errorDesc = { startMonthYear: text1 }
            isError = isFirstParamError
          } else {
            const text = resDateValidator(endMonthYear).text
            isError = resDateValidator(endMonthYear).isError
            errorDesc = { endMonthYear: text }
          }
        }
        break
      case PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD:
        {
          errorDesc = {}
          isError = false
          const { startMonthYear, endMonthYear } = params.reoReport
          const text1 = resDateValidator(startMonthYear).text
          const isFirstParamError = resDateValidator(startMonthYear).isError
          if (isFirstParamError) {
            errorDesc = { startMonthYear: text1 }
            isError = isFirstParamError
          } else {
            const text = resDateValidator(endMonthYear).text
            isError = resDateValidator(endMonthYear).isError
            errorDesc = { endMonthYear: text }
          }
        }
        break
      default: {
        errorDesc = {}
        isError = false
      }
    }
    const err = { ...errorData, errorDesc, isError }
    dispatch(setErrorData(err))
    return isError
  }

  const handleReportFormSubmit = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    const isError = validateBeforeSubmit()
    if (!isError) {
      dispatch(setPredefinedReport(selectedItem))
      const selectedReport: PREDEFINED_REPORTS_ENUM = selectedItem
      navigateTo(selectedReport, params, { ...getParamsBasedonURL(searchParams, true) })
      props.onClose()
    }
  }

  const setWONumber2 = (value: string) => {
    const isValid = replaceAlphabets(value)
    if (isValid) dispatch(setWorkAllocationReport2({ woNumber2: value }))
  }

  const setWONumber1 = (value: string) => {
    const isValid = replaceAlphabets(value)
    if (isValid) dispatch(setWorkAllocationReport1({ woNumber1: value }))
  }

  const handleChangeForPropertyInventory = (value: string) => {
    let text = value
    // Here we are checking if the length is equal to 4
    if (value.length > 4) {
      text = value?.substring(0, 4)
    }
    dispatch(setPropInventoryDetails(text))
  }
  return (
    <Card className="predefined-report-card">
      <label className="report-card-label">Preset Reports (select one)</label>
      <form onSubmit={onFormSubmitHandler} autoSave='off'>
        <div className="reports-radio-button-container">
          <div className="first-column-container">
            <section className="predefined-report-section">
              <RadioButton
                id="res_detail_ytd"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD}
                label={
                  reportDataMaster[PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD]
                    .label
                }
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem === PREDEFINED_REPORTS_ENUM.REIS_DETAIL_YTD && (
                <div className='predefined-report-two-inputs'>
                  <div>
                <Input
                  name="res-start-month-year-input"
                  placeholder="Start MM/YYYY"
                  value={resReport.startMonthYear}
                  onChange={(e) => {
                    dispatch(setResReportParameter({ startMonthYear: e.target.value }))
                  }
                  }
                  onPaste={(e) => {
                    dispatch(setResReportParameter({ startMonthYear: e.target.value }))
                  }
                  }
                  errorDesc={setErrorStyleAndMessage('startMonthYear').errorText}
                  className={`${setErrorStyleAndMessage('startMonthYear').errorStyle} input-select`}
                  errorLabelStyle = {'error-style'}

                />
                </div>
                <div>
                <Input
                  name="res-end-month-year-input"
                  placeholder="End MM/YYYY"
                  value={resReport.endMonthYear}
                  onChange={(e) => {
                    dispatch(setResReportParameter({ endMonthYear: e.target.value }))
                  }
                  }
                  onPaste={(e) => {
                    dispatch(setResReportParameter({ endMonthYear: e.target.value }))
                  }
                  }
                  errorDesc={setErrorStyleAndMessage('endMonthYear').errorText}
                  className={`${setErrorStyleAndMessage('endMonthYear').errorStyle} input-select`}
                  errorLabelStyle = {'error-style'}
                />
                </div>
                </div>
              )}

            </section>
            <section className="predefined-report-section">
              <RadioButton
                id="property-list"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.PROPERTY_LIST}
                label={
                  reportDataMaster[PREDEFINED_REPORTS_ENUM.PROPERTY_LIST].label
                }
                checked={selectedItem === PREDEFINED_REPORTS_ENUM.PROPERTY_LIST}
                onChange={onSelectHandler}
              ></RadioButton>
            </section>
            <section className="predefined-report-section">
              <RadioButton
                id="property-activities"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES}
                label={
                  reportDataMaster[PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES]
                    .label
                }
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.PROPERTY_ACTIVITIES
                }
                onChange={onSelectHandler}
              ></RadioButton>
            </section>
            <section className="predefined-report-section">
              <RadioButton
                id="property-by-ownership"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP}
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP
                  ].label
                }
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.PROPERTY_BY_OWNERSHIP
                }
                onChange={onSelectHandler}
              ></RadioButton>
            </section>
            <section className="predefined-report-section">
              <RadioButton
                id="full/partial-plant-location"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION}
                checked={
                  selectedItem ===
                  PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem === PREDEFINED_REPORTS_ENUM.FULL_PARTIAL_LAND_LOCATION && (
                <Input
                  name="full/partial-plant-location-input"
                  inputType="number"
                  placeholder="Enter Plant Location here"
                  value={fullPartialPL}
                  onChange={(e) => {
                    dispatch(setFullPartialPL(e.target.value))
                  }
                  }
                  onPaste={(e) => {
                    dispatch(setFullPartialPL(e.target.value))
                  }
                  }
                  errorDesc={setErrorStyleAndMessage('plantLoc').errorText}
                  className={setErrorStyleAndMessage('plantLoc').errorStyle}

                />
              )}
            </section>
          </div>
          <div className="second-column-container">
            <section className="predefined-report-section">
              <RadioButton
                id="property-inventory-detail"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL}
                checked={
                  selectedItem ===
                  PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem ===
                PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_DETAIL && (
                  <Input
                    name="property-inventory-detail-input"
                    inputType="number"
                    placeholder="Enter Plant Location (xxxx)"
                    value={propertyInventoryDetails}
                    onChange={(e) => {
                      // dispatch(setPropInventoryDetails(e.target.value))
                      handleChangeForPropertyInventory(e.target.value)
                    }}
                    onPaste={(e) => {
                      dispatch(setPropInventoryDetails(e.target.value))
                    }}
                    errorDesc={setErrorStyleAndMessage('plantLoc').errorText}
                    className={setErrorStyleAndMessage('plantLoc').errorStyle}
                  />
              )}
            </section>
            <section className="predefined-report-section">
              <RadioButton
                id="reo-detail-ytd"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD}
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD
                }
                label={
                  reportDataMaster[PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem === PREDEFINED_REPORTS_ENUM.REO_DETAIL_YTD && (
                <div className='predefined-report-two-inputs'>
                  <div>
                <Input
                  name="reo-startMonth-year-input"
                  placeholder="Start MM/YYYY"
                  value={reoReport.startMonthYear}
                  onChange={(e) => {
                    dispatch(setReoReportParameter({ startMonthYear: e.target.value }))
                  }
                  }
                  onPaste={(e) => {
                    dispatch(setReoReportParameter({ startMonthYear: e.target.value }))
                  }
                  }
                  errorDesc={setErrorStyleAndMessage('startMonthYear').errorText}
                  className={`${setErrorStyleAndMessage('startMonthYear').errorStyle} input-select`}
                  errorLabelStyle = {'error-style'}
                />
                </div>
                <div>
                <Input
                  name="reo-end-month-year-input"
                  placeholder="End MM/YYYY"
                  value={reoReport.endMonthYear}
                  onChange={(e) => {
                    dispatch(setReoReportParameter({ endMonthYear: e.target.value }))
                  }
                  }
                  onPaste={(e) => {
                    dispatch(setReoReportParameter({ endMonthYear: e.target.value }))
                  }
                  }
                  errorDesc={setErrorStyleAndMessage('endMonthYear').errorText}
                  className={`${setErrorStyleAndMessage('endMonthYear').errorStyle} input-select`}
                  errorLabelStyle = {'error-style'}
                />
                </div>
                </div>
              )}
            </section>
            <section className={'predefined-report-section'}>
              <RadioButton
                id="wo-allocation-by-serial-number"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1}
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem ===
                PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT1 && (
                  <div className="predefined-report-two-inputs">
                    <div>
                      <Input
                        name="wo-allocation-report-1"
                        // inputType="number"
                        placeholder="Enter Work Order # here"
                        value={workAllocationReport1.woNumber1}
                        onChange={(e) => {
                          // dispatch(setWorkAllocationReport1({ woNumber1: e.target.value }))
                          setWONumber1(e.target.value)
                        }}
                        onPaste={(e) => {
                          // dispatch(setWorkAllocationReport1({ woNumber1: e.target.value }))
                          setWONumber1(e.target.value)
                        }}
                        errorDesc={setErrorStyleAndMessage('woNumber1').errorText}
                        className={`${setErrorStyleAndMessage('woNumber1').errorStyle} input-select`}
                      />
                    </div>
                    <div>
                      <NumericFormat
                        name="wo-allocation-report-1-cost"
                        placeholder="SAP Total Cost"
                        value={workAllocationReport1.cost}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={(e) => {
                          dispatch(setWorkAllocationReport1({ cost: e.target.value }))
                        }}
                        autoComplete='off'
                        aria-autocomplete='none'

                        // onPaste={(e) => {
                        //   dispatch(setWorkAllocationReport1({ cost: e.target.value }))
                        // }}
                        // errorDesc={setErrorStyleAndMessage('cost').errorText}
                        className={`${setErrorStyleAndMessage('cost').errorStyle} input-select-box input-box input-select`}
                      />
                      <p className="error-message">{setErrorStyleAndMessage('cost').errorText}</p>
                    </div>
                  </div>
              )}
            </section>
            <section className={'predefined-report-section'}>
              <RadioButton
                id="wo-allocation-by-document-type"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2}
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem ===
                PREDEFINED_REPORTS_ENUM.WO_ALLOCATION_REPORT2 && (
                  <div className="predefined-report-two-inputs">
                    <div>
                      <Input
                        name="wo-allocation-report-2"
                        // inputType="number"
                        placeholder="Enter Work Order # here"
                        value={workAllocationReport2.woNumber2}
                        onChange={(e) => {
                          // dispatch(setWorkAllocationReport2({ woNumber2: e.target.value }))
                          setWONumber2(e.target.value)
                        }}
                        onPaste={(e) => {
                          // dispatch(setWorkAllocationReport2({ woNumber2: e.target.value }))
                          setWONumber2(e.target.value)
                        }}
                        errorDesc={setErrorStyleAndMessage('woNumber2').errorText}
                        className={`${setErrorStyleAndMessage('woNumber2').errorStyle} input-select`}
                      />
                    </div>
                    <div>
                      <NumericFormat
                        name="wo-allocation-report-2-cost"
                        placeholder="SAP Total Cost"
                        value={workAllocationReport2.cost2}
                        decimalScale={2}
                        onChange={(e) => {
                          dispatch(setWorkAllocationReport2({ cost2: e.target.value }))
                        }}
                        autoComplete='off'
                        aria-autocomplete='none'

                        // onPaste={(e) => {
                        //   dispatch(setWorkAllocationReport2({ cost2: e.target.value }))
                        // }}
                        // errorDesc={setErrorStyleAndMessage('cost2').errorText}
                        className={`${setErrorStyleAndMessage('cost2').errorStyle} input-select-box input-box input-select`}
                      />
                      <p className="error-message">{setErrorStyleAndMessage('cost').errorText}</p>
                    </div>
                  </div>
              )}
            </section>
          </div>
          <div className="third-column-container">
            <section className={'predefined-report-section'}>
              <RadioButton
                id="range-of-plant-locations"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS}
                checked={
                  selectedItem ===
                  PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem ===
                PREDEFINED_REPORTS_ENUM.RANGE_OF_PLANT_LOCATIONS && (
                  <div className="predefined-report-two-inputs">
                    <div>
                      <Input
                        name="first-plant-location"
                        inputType="number"
                        placeholder="First Plant Loc"
                        value={rangeOfPlantLocation.first}
                        onChange={(e) => {
                          dispatch(
                            setRangeOfPlantLocation({ first: e.target.value })
                          )
                        }}
                        onPaste={(e) => {
                          dispatch(
                            setRangeOfPlantLocation({ first: e.target.value })
                          )
                        }}
                        errorDesc={setErrorStyleAndMessage('plantLoc').errorText}
                        className={`${setErrorStyleAndMessage('plantLoc').errorStyle} input-select`}
                      />
                    </div>
                    <div>
                      <Input
                        name="last-plant-location"
                        inputType="number"
                        placeholder="Last Plant Loc"
                        value={rangeOfPlantLocation.last}
                        onChange={(e) => {
                          dispatch(
                            setRangeOfPlantLocation({ last: e.target.value })
                          )
                        }}
                        onPaste={(e) => {
                          dispatch(
                            setRangeOfPlantLocation({ last: e.target.value })
                          )
                        }}
                        className='input-select'
                      />
                    </div>
                  </div>
              )}
            </section>
            <section className="predefined-report-section">
              <RadioButton
                id="variance-by-plant-location"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION}
                checked={
                  selectedItem ===
                  PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem ===
                PREDEFINED_REPORTS_ENUM.VARIANCE_BY_PLANT_LOCATION && (
                  <div className="predefined-report-two-inputs">
                    <div>
                      <Input
                        name="variance-by-plant-location-month"
                        placeholder="MM (month)"
                        value={varianceByPlantLocation.month}
                        pattern="[0-9]{2}"
                        onChange={(e) => {
                          dispatch(
                            setVarianceByPlantLocation({ month: e.target.value })
                          )
                        }}
                        onPaste={(e) => {
                          dispatch(
                            setVarianceByPlantLocation({ month: e.target.value })
                          )
                        }}
                        errorDesc={setErrorStyleAndMessage('month').errorText}
                        className={`${setErrorStyleAndMessage('month').errorStyle} input-select`}
                      />
                    </div>
                    <div>
                      <Input
                        name="variance-by-plant-location-year"
                        placeholder="YYYY (year)"
                        value={varianceByPlantLocation.year}
                        pattern="[0-9]{4}"
                        onChange={(e) => {
                          dispatch(
                            setVarianceByPlantLocation({ year: e.target.value })
                          )
                        }}
                        errorDesc={setErrorStyleAndMessage('year').errorText}
                        className={`${setErrorStyleAndMessage('year').errorStyle} input-select`}
                      />
                    </div>
                  </div>
              )}
            </section>

            <section className="predefined-report-section">
              <RadioButton
                id="property-inventory-only-plant-locations"
                name="preset-reports"
                className="predefined-report"
                value={
                  PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION
                }
                checked={
                  selectedItem ===
                  PREDEFINED_REPORTS_ENUM.PROPERTY_INVENTORY_ONLY_PLANT_LOCATION
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM
                      .PROPERTY_INVENTORY_ONLY_PLANT_LOCATION
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
            </section>

            <section className={'predefined-report-section'}>
              <RadioButton
                id="entries-by-gl-account"
                name="preset-reports"
                className="predefined-report"
                value={PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT}
                checked={
                  selectedItem === PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT
                }
                label={
                  reportDataMaster[
                    PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT
                  ].label
                }
                onChange={onSelectHandler}
              ></RadioButton>
              {selectedItem ===
                PREDEFINED_REPORTS_ENUM.ENTRIES_BY_GL_ACCOUNT && (
                  <Input
                    name="gl-account-number-input"
                    inputType="number"
                    placeholder="Enter GL Account (xxx.xxx)"
                    value={glAccount}
                    onChange={(e) => {
                      dispatch(setGLAccount(e.target.value))
                    }}
                    onPaste={(e) => {
                      dispatch(setGLAccount(e.target.value))
                    }}
                    errorDesc={setErrorStyleAndMessage('glAccount').errorText}
                    className={setErrorStyleAndMessage('glAccount').errorStyle}
                  />
              )}
            </section>
          </div>
        </div>
        <div className="predefined-reports-button-section">
          <Button type="cancel" onClick={onCloseHandler}>
            Cancel
          </Button>
          <Button
            buttonType="submit"
            className="apply-button"
            type="confirm"
            onClick={handleReportFormSubmit}
          >
            Apply
          </Button>
        </div>
      </form>
    </Card>
  )
}

export default PredefinedReports
