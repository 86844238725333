import { type PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  type IAccountingInformation,
  type ICostInformation,
  type ICrossInformation,
  type IErrorData,
  type IPropertyCombinedData
  // type IAttachmentsDataFromDb
} from '../../types/TransactionTypes'
import {
  accountingInformationInitialData,
  costInformationInitialData,
  errorInitialData,
  propertyDataAcountingInitialData,
  propertyDataAcquisitionInitialData
} from './addTransactionInitialData'
import { getTransactionByRecordNo } from '../middleWares/getTransactionByRecordNo'
import { StatusTypesEnum, VIEW_TRANSACTION_STATUS, type VIEW_TRANSACTION_TYPE } from '../../types/CommonTypes'
import { calculatePropertyTotal } from '../../util/propertyCalculations'
import formValidation from '../../util/formValidation'
import { postTransaction } from '../middleWares/postTransaction'
import { updateTransaction } from '../middleWares/updateTransaction'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { getPropertyIdFromProperty } from '../middleWares/getPropertyIdFromProperty'
import { calculateGrossProfit, calculateNetGainLoss, calculatePropertyCost, calculateSAPTotal, calculateSaleOverAppraisedValue, calculateSumNetProceeds, calculateTotalCost } from '../../util/costCalculations'
import { deleteTransaction } from '../middleWares/deleteTransaction'

export interface IErroResponse {
  statusCode: string | undefined
  message: string | undefined
}

export interface IViewTransactionType {
  section: VIEW_TRANSACTION_TYPE | ''
}

export interface IViewPropertyCombinedData extends IPropertyCombinedData {
  refId: number | ''
  transactionAcctNumber: number | ''
}

const viewPropertyFieldInitialData: (IViewPropertyCombinedData) = {
  ...propertyDataAcquisitionInitialData,
  ...propertyDataAcountingInitialData,
  refId: '',
  transactionAcctNumber: ''
}

export interface IViewCrossReference extends ICrossInformation {
  refId: number | ''
}

export interface IViewPropertyData {
  fieldData: IViewPropertyCombinedData[]
  totalAcres: number | ''
  totalAmount: number | ''
}

const viewPropertyDataInitalData: IViewPropertyData = {
  fieldData: [viewPropertyFieldInitialData],
  totalAcres: 0,
  totalAmount: 0
}

const viewCrossReferenceInitialData: IViewCrossReference = {
  refId: '',
  documentType: '',
  documentRef: ''
}

export interface ITransactionView {
  transactionType: IViewTransactionType
  accountingInformation: IAccountingInformation
  propertyActivityInformation: IViewPropertyCombinedData[]
  costInformation: ICostInformation
  crossReference: IViewCrossReference[]
  propertyData: IViewPropertyData
  // attachmentsFromDb: IAttachmentsDataFromDb[]
  errorData: IErrorData
  isPostSubmitted: boolean
  status: IStatusResponse
  isBookedTransaction: boolean
  isStatusPost: boolean
  isDeletedSuccessfully: boolean
  isStatusPostSaved: boolean
}

export const viewTransactionTypeInitialData: IViewTransactionType = {
  section: ''
}

export const viewTransactionInitialData: ITransactionView = {
  transactionType: viewTransactionTypeInitialData,
  accountingInformation: accountingInformationInitialData,
  propertyActivityInformation: [],
  costInformation: costInformationInitialData,
  crossReference: [viewCrossReferenceInitialData],
  propertyData: viewPropertyDataInitalData,
  // attachmentsFromDb: [],
  errorData: errorInitialData,
  isPostSubmitted: false,
  status: { type: undefined, message: '' },
  isBookedTransaction: false,
  isStatusPost: false,
  isDeletedSuccessfully: false,
  isStatusPostSaved: false
}

const viewTransactionSlice = createSlice({
  name: 'viewTransaction',
  initialState: viewTransactionInitialData,
  reducers: {

    addNewPropertyDataToView (state,
      action: PayloadAction<IViewPropertyCombinedData>) {
      state.propertyData.fieldData.push(action.payload)
    },

    resetViewTransactionFields (state: ITransactionView) {
      state.transactionType = viewTransactionTypeInitialData
      state.accountingInformation = accountingInformationInitialData
      state.propertyActivityInformation = []
      state.costInformation = { ...costInformationInitialData }
      state.crossReference = [{ ...viewCrossReferenceInitialData }]
      // state.attachmentsFromDb = viewTransactionInitialData.attachmentsFromDb
      state.propertyData = viewPropertyDataInitalData
      state.errorData = errorInitialData
      state.status.type = undefined
      state.status.message = ''
      state.isPostSubmitted = false
      state.isBookedTransaction = false
      state.isDeletedSuccessfully = false
      state.isStatusPost = false
      state.isStatusPostSaved = false
    },

    resetViewTransactionStatus (state) {
      state.status = { type: undefined, message: '' }
    },

    setPropertyDataView (state, action: PayloadAction<{ data: (IViewPropertyCombinedData) | null, index: number }>) {
      const existingState = [...state.propertyData.fieldData]
      if (action.payload.data === null) {
        existingState.splice(action.payload.index, 1)
      } else {
        existingState[action.payload.index] = action.payload.data
      }
      state.propertyData.fieldData = existingState
    },

    setCrossReferenceDataView (state, action: PayloadAction<{ data: IViewCrossReference | null, index: number }>) {
      const existingState = [...state.crossReference]
      if (action.payload.data === null) {
        existingState.splice(action.payload.index, 1)
      } else {
        existingState[action.payload.index] = action.payload.data
      }
      state.crossReference = existingState
    },

    setTotalPropertyDataView (state, action: PayloadAction<{ totalAcres: number, totalAmount: number }>) {
      state.propertyData.totalAcres = action.payload.totalAcres
      state.propertyData.totalAmount = action.payload.totalAmount
    },

    setAccountingInformationDataView<T extends keyof IAccountingInformation>(state: ITransactionView, action: PayloadAction<Pick<IAccountingInformation, T>>) {
      state.accountingInformation = { ...state.accountingInformation, ...action.payload }
      const value = Object.values(action.payload)[0]
      const key = Object.keys(action.payload)[0]
      if (value === VIEW_TRANSACTION_STATUS.POST && key === 'transactionStatus') {
        state.isStatusPost = true
      } else if (key === 'transactionStatus' && value !== VIEW_TRANSACTION_STATUS.POST) {
        state.isStatusPost = false
      }
    },

    setCostInformationDataView (state, action: PayloadAction<ICostInformation>) {
      state.costInformation = { ...state.costInformation, ...action.payload }
    },

    addNewIndexToPropertyDataView (state) {
      state.propertyData.fieldData.push(viewPropertyFieldInitialData)
    },

    addNewIndexToCrossReferrenceDataView (state) {
      state.crossReference.push(viewCrossReferenceInitialData)
    },

    updateErrorDescriptionView (state, action: PayloadAction<IErrorData>) {
      state.errorData = { ...state.errorData, errorDesc: action.payload }
    },

    updateViewTransactionPropertyDataError (state, action: PayloadAction<any>) {
      state.errorData = { ...state.errorData, propertyDataError: action.payload }
    },
    doValidationOnSave (state: ITransactionView, action: PayloadAction<boolean>) {
      /// TBD - once record is saved , does nt allow to change the transaction type
      const errdata = formValidation(state, action.payload)
      state.errorData = errdata

      if (state.accountingInformation.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED) {
        if (!errdata.isError) {
          state.isPostSubmitted = true
        }
      } else {
        state.isPostSubmitted = false
      }
    },

    setIsPostSubmiitedValue (state, action: PayloadAction<boolean>) {
      state.isPostSubmitted = action.payload
    },
    resetErrorValueInView (state, action: PayloadAction<boolean>) {
      state.errorData.isError = action.payload
      state.errorData.defaultErr = ''
      state.isPostSubmitted = false
    },
    clearParcelNumberView (state, action: PayloadAction<{ id: number }>) {
      state.propertyData.fieldData[action.payload.id].parcelNumber = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactionByRecordNo.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.accountingInformation = action.payload.accountingInfo
      state.propertyActivityInformation = action.payload.propertyActivityData
      state.costInformation = {
        ...action.payload.costInformation,
        total: calculateSAPTotal(action.payload.costInformation),
        netProceeds: calculateSumNetProceeds(action.payload.costInformation),
        grossProfit: calculateGrossProfit(action.payload.costInformation),
        propertyCost: calculatePropertyCost(action.payload.costInformation),
        sapTotalInternalExpense: calculateSAPTotal(action.payload.costInformation),
        totalCost: calculateTotalCost(action.payload.costInformation),
        bookValue: calculateTotalCost(action.payload.costInformation),
        netGainLoss: calculateNetGainLoss(action.payload.costInformation),
        percentageSaleOverAppraisedValue: calculateSaleOverAppraisedValue(action.payload.costInformation)
      }
      state.crossReference = action.payload.crossRef
      // state.attachmentsFromDb = action.payload.attachments
      state.propertyData.fieldData = action.payload.propertyActivityData
      const calculated = calculatePropertyTotal(action.payload.propertyActivityData)
      state.propertyData.totalAcres = calculated.totalAcres
      state.propertyData.totalAmount = calculated.totalAmount
      state.isBookedTransaction = action.payload.accountingInfo.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED
      state.isStatusPostSaved = action.payload.accountingInfo.transactionStatus === VIEW_TRANSACTION_STATUS.POST
      state.isStatusPost = false
    })

    // TODO: Error handling
    builder.addCase(getTransactionByRecordNo.rejected, (state, action) => {
      const { error } = action
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = error.message
      state.isBookedTransaction = false
    })
    builder.addCase(postTransaction.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.isBookedTransaction = true
      state.isStatusPost = false
      state.accountingInformation.statusDate = action.payload.statusDate
      state.accountingInformation.transactionStatus = action.payload.transactionStatus
      state.isStatusPostSaved = false
    })
    builder.addCase(postTransaction.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error?.message
      state.isBookedTransaction = false
    })
    builder.addCase(getPropertyIdFromProperty.fulfilled, (state, action) => {
      state.accountingInformation.propId = action.payload
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
    })

    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      state.accountingInformation.transId = action.payload.transId
      state.accountingInformation.statusDate = action.payload.statusDate
      state.accountingInformation.transactionStatus = action.payload.transactionStatus
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.isStatusPost = false
      state.isBookedTransaction = action.payload.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED
      state.isStatusPostSaved = action.payload.transactionStatus === VIEW_TRANSACTION_STATUS.POST
    })

    builder.addCase(updateTransaction.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
      // state.isBookedTransaction = false
    })

    builder.addCase(deleteTransaction.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
      state.isDeletedSuccessfully = false
    })
    builder.addCase(deleteTransaction.fulfilled, (state, action) => {
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.isDeletedSuccessfully = true
    })

    builder.addMatcher(isAnyOf(getTransactionByRecordNo.pending,
      postTransaction.pending, updateTransaction.pending, getPropertyIdFromProperty.pending), (state) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = viewTransactionInitialData.status.message
    })
  }
})

export const {
  addNewPropertyDataToView,
  addNewIndexToPropertyDataView,
  addNewIndexToCrossReferrenceDataView,
  setCostInformationDataView
  , setAccountingInformationDataView
  , setCrossReferenceDataView
  , resetViewTransactionFields
  , setPropertyDataView
  , setTotalPropertyDataView
  , updateErrorDescriptionView
  , doValidationOnSave
  , setIsPostSubmiitedValue
  , updateViewTransactionPropertyDataError
  , resetViewTransactionStatus
  , resetErrorValueInView
  , clearParcelNumberView
} = viewTransactionSlice.actions
export default viewTransactionSlice.reducer
