export const getDropdownData = (field: Array<{ id: number | string, name: string }>) => {
  const id: string[] = []
  const options: any[] = []

  field?.forEach((item) => {
    if (item.name) {
      options.push(item.name)
      id.push(String(item.id))
    }
  })
  return () => ({
    id, options
  })
}
