import React, { type ChangeEvent, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import CheckBox from '../../../components/sharedModules/checkbox/CheckBox'
import Input from '../../../components/sharedModules/input/Input'
import TextArea from '../../../components/sharedModules/textArea/TextArea'
import ResponseModal from '../../../components/welcome/ResponseModal'
import Button from '../../../components/sharedModules/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { DropDownFiledsEnum, navigationPath } from '../../../types/CommonTypes'
import { useDispatch, useSelector } from 'react-redux'
import { type IInventoryInformation } from '../../../store/slices/viewPropertySlice'
import { type AppDispatch } from '../../../store/store'
import Select from '../../../components/sharedModules/select/Select'
import { getDropdownData } from '../../../util/getDropDownPropData'
import { DROPDOWN_API_CALL_DELAY } from '../../../Constants'
import useDebounce from '../../../customHooks/useDebounce'
import { getDropdownFilteredValues, getDropdownValues } from '../../../store/middleWares/getDropdownValues'
import { updateAddPropertyInfoDropdowns, updatePropertyDetails, resetPropertyInfo, setPropertyISO } from '../../../store/slices/addPropertySlice'
import { trimPropertyInfo } from '../../../util/propertyCalculations'
import { addProperty } from '../../../store/middleWares/addProperty'
import './AddProperty.scss'
import scrollToTop from '../../../util/scrollToTop'
import { restoreLastPageUrl } from '../../../util/restoreLastPageUrl'

const AddProperty: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const fieldData: IInventoryInformation = useSelector(
    (state: any) => {
      return state.addProperty.inventoryInformation
    }
  )

  useEffect(() => {
    dispatch(resetPropertyInfo())
    dispatch(getDropdownValues([DropDownFiledsEnum.COUNTY, DropDownFiledsEnum.CITY, DropDownFiledsEnum.STATE, DropDownFiledsEnum.PROPERTY_TYPE]))
  }, [])

  const [isSaveBtnActive, setisSaveBtnActive] = useState<boolean>(true)
  const [isFilled, setIsFilled] = useState({ propertyName: true, propId: true })

  const county = useSelector((state: any) => state.dropDown.county)
  const city = useSelector((state: any) => state.dropDown.city)
  const state = useSelector((state: any) => state.dropDown.state)
  const propertyType = useSelector((state: any) => state.dropDown.propertyType)

  const debounceAPICall = useDebounce((field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }, DROPDOWN_API_CALL_DELAY)

  const onDropdownValueChange = (data: any, field: string) => {
    setisSaveBtnActive(true)
    dispatch(updateAddPropertyInfoDropdowns(data))
    const value: string = Object.values(data)[0] as string
    debounceAPICall(field, value)
  }

  const getDropdownValuesWithoutDelay = (field: string, value: string) => {
    dispatch(getDropdownFilteredValues({ field, token: value }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    const inputField = e.target.name
    let { value } = e.target
    value = trimPropertyInfo(inputField, value)
    dispatch(updatePropertyDetails({ [inputField]: value }))
  }

  const onCancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(restoreLastPageUrl())
    dispatch(resetPropertyInfo())
  }

  const onSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const obj = {
      propertyName: !!fieldData.propertyName,
      propId: !!fieldData.propId
    }
    setIsFilled(obj)
    if (fieldData.propertyName && fieldData.propId) {
      dispatch(addProperty())
      setisSaveBtnActive(false)
      setIsFilled({ propertyName: true, propId: true })
    } else {
      scrollToTop()
    }
  }

  const formChangeHandler = () => {
    setisSaveBtnActive(true)
  }
  return (
    <>
      <ResponseModal />
      <div className="inventory-container">
        <h2 className='heading'>Add Property</h2>
        <h2 className="sub-heading">Inventory Information</h2>

        <div className="inventory-body-container" onChange={formChangeHandler}>
          <section>
            <label className="input-label">Property Number</label>
            <Input
              name="propNo"
              value={fieldData?.propNo ?? ''}
              className="property-input property-number"
              readOnly={true}
              onChange={handleInputChange}
            />
          </section>

          <section>
            <label className="input-label">Property</label>
            <Input
              name="propertyName"
              value={fieldData?.propertyName}
              className={isFilled.propertyName ? 'property-input' : 'property-input err'}
              readOnly={false}
              onChange={(e) => {
                handleInputChange(e)
                setIsFilled({ ...isFilled, propertyName: true })
              }}
            />
            {!isFilled.propertyName && <p className="error-message ">This field is required</p>}
          </section>
          <section>
            <label className="input-label">Property ID</label>
            <Input
              name="propId"
              value={fieldData?.propId ?? ''}
              className={isFilled.propId ? 'property-input' : 'property-input err'}
              readOnly={false}
              onChange={(e) => {
                handleInputChange(e)
                setIsFilled({ ...isFilled, propId: true })
              }}
            />
            {!isFilled.propId && <p className="error-message ">This field is required</p>}
          </section>
          <section>
            <label className="input-label">Address</label>
            <Input
              name="propertyAddress"
              value={fieldData?.propertyAddress ?? ''}
              className="property-input"
              readOnly={false}
              onChange={handleInputChange}
            />
          </section>
          <section className='county-section'>
            <label className="input-label">County</label>
            < Select
              name="propertyCounty"
              key="county"
              value={fieldData?.propertyCounty ?? ''}
              ignoreValidation={false}
              className="property-input-select"
              data={getDropdownData(county)}
              onChange={(selectedItem: string) => {
                onDropdownValueChange(
                  { propertyCounty: selectedItem },
                  DropDownFiledsEnum.COUNTY
                )
              }}
              triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.COUNTY, value) }}
            />
          </section>
          <section className='county-section'>
            <label className="input-label">Type</label>
            < Select
              name="propertyType"
              key="propertyType"
              value={fieldData?.propertyType ?? ''}
              ignoreValidation={false}
              className="property-input-select"
              data={getDropdownData(propertyType)}
              onChange={(selectedItem: string) => {
                onDropdownValueChange(
                  { propertyType: selectedItem },
                  DropDownFiledsEnum.PROPERTY_TYPE
                )
              }}
              triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.PROPERTY_TYPE, value) }}
            />
          </section>

          <div className="divided-section">
            <div className="divided-left-section">
              <section className='property-city'>
                <label className="input-label">City</label>
                < Select
                  name="propertyCity"
                  key="city"
                  value={fieldData?.propertyCity ?? ''}
                  ignoreValidation={false}
                  className="property-input-select"
                  data={getDropdownData(city)}
                  onChange={(selectedItem: string) => {
                    onDropdownValueChange(
                      { propertyCity: selectedItem },
                      DropDownFiledsEnum.CITY
                    )
                  }}
                  triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.CITY, value) }}
                />
              </section>
              <section>
                <label className="input-label">Thomas Page / Grid</label>
                <span className="thomas-page-grid-section">
                  <Input
                    name="thomasPage"
                    value={fieldData?.thomasPage ?? ''}
                    className="property-input"
                    readOnly={false}
                    onChange={handleInputChange}
                  />
                  <Input
                    name="thomasGrid"
                    value={fieldData?.thomasGrid ?? ''}
                    className="property-input"
                    readOnly={false}
                    onChange={handleInputChange}
                  />
                </span>

              </section>
            </div>
            <div className="divided-right-section">
              <label className="select-if-applicable">Select If Applicable</label>
              <CheckBox
                id="iso"
                name="iso"
                value="propertyIso"
                checked={fieldData?.iso === 1}
                onChange={(e: any) =>
                  dispatch(setPropertyISO(e.target.checked))
                }
                label="ISO"
              />
            </div>
          </div>

          <div className="divided-section">
            <div className="divided-left-section">
              <section className='property-state'>
                <label className="input-label">State</label>
                < Select
                  name="propertyState"
                  key="state"
                  value={fieldData?.propertyState ?? ''}
                  ignoreValidation={false}
                  className="property-input-select"
                  data={getDropdownData(state)}
                  onChange={(selectedItem: string) => {
                    onDropdownValueChange(
                      { propertyState: selectedItem },
                      DropDownFiledsEnum.STATE
                    )
                  }}
                  triggerOnFocus={(value: string) => { getDropdownValuesWithoutDelay(DropDownFiledsEnum.STATE, value) }}
                />
              </section>

              <section>
                <label className="input-label">Total Acres</label>
                <NumericFormat
                  name="totalAcres"
                  value={fieldData?.totalAcres ?? ''}
                  className="input-select-box property-input property-number"
                  decimalScale={3}
                  thousandSeparator
                  fixedDecimalScale
                  readOnly={true}
                  // onChange={handleInputChange}
                />
              </section>
              <section>
                <label className="input-label">Zip Code</label>
                <Input
                  name="zipCode"
                  value={fieldData?.zipCode ?? ''}
                  className="property-input"
                  readOnly={false}
                  onChange={handleInputChange}
                />
              </section>

              <section>
                <label className="input-label">Book Value</label>
                <NumericFormat
                  name="totalBookValue"
                  value={fieldData?.totalBookValue ?? ''}
                  className="input-select-box property-input property-number"
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  readOnly={true}
                  // onChange={handleInputChange}
                />
              </section>
            </div >
            <div className="divided-right-section">
              <section>
                <label className="input-label">Comments</label>
                <TextArea
                  name="comments"
                  value={fieldData?.comments ?? ''}
                  className="property-input comments"
                  onChange={handleInputChange}
                />
              </section>
            </div>
          </div >
        </div >
      </div >
      <div className="add-transaction-footer">
        <span>
          <Button onClick={onCancelHandler} type="cancel">
            Cancel
          </Button>
        </span>
        <span className="add-transaction-button-group">
          <Link to={navigationPath.ADD_TRANSACTION}>
            <Button
              className="route-button"
              type="confirm"
            >
              Add Transaction
            </Button>
          </Link>
          <Button
            onClick={onSave}
            className={isSaveBtnActive ? 'save-create-pdf-button' : 'print-button'}
            type="confirm"
            disabled={!isSaveBtnActive}

          >
            Save
          </Button>
        </span>
      </div>
    </>
  )
}

export default AddProperty
