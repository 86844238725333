import React, { useState, type ReactElement } from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../sharedModules/modal/Modal'
import './ExportReport.scss'
import Button from '../sharedModules/button/Button'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetExportReport,
  setExportReportModalVisible,
  setExportReportType
} from '../../store/slices/reportsSlice'
import { REPORT_EXPORT_TYPE } from '../reports/predefinedReportMapping'
import RadioButton from '../sharedModules/RadioButton/RadioButton'
import { type AppDispatch } from '../../store/store'
import { downloadReport } from '../../store/middleWares/downloadReport'

export const ExportReport = (): ReactElement | null => {
  const exportReport = useSelector(
    (state: any) => state.report.exportReport
  )
  const dispatch = useDispatch<AppDispatch>()
  const [exportType, setExportType] = useState<REPORT_EXPORT_TYPE | undefined>(
    undefined
  )

  if (!exportReport.isExportModalVisible) return null

  const onCloseHandler = () => {
    setExportType(undefined)
    dispatch(setExportReportModalVisible(false))
  }

  const onSelectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportType(event?.target.value as REPORT_EXPORT_TYPE)
  }

  const onConfirmExportType = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (exportType) {
      setExportType(undefined)
      dispatch(setExportReportType(exportType))
      dispatch(resetExportReport())
      dispatch(downloadReport({ exportType }))
    }
    // onCloseHandler()
  }
  return (
    <Modal className="export-report-modal">
      <ModalHeader>
        <section className="export-report-modal-header">
          <h4>{'Export Information'}</h4>
        </section>
      </ModalHeader>
      <ModalBody>
        <div className="export-report-modal-body">
          <label>Export this report in the following format(s):</label>
          <section className='export-report-radio-button-container'>
            <RadioButton
              id={'pdf'}
              name={'pdf'}
              value={REPORT_EXPORT_TYPE.pdf}
              label="PDF"
              checked={exportType === REPORT_EXPORT_TYPE.pdf}
              onChange={onSelectHandler}
            />
            <RadioButton
              id={'excel'}
              name={'excel'}
              value={REPORT_EXPORT_TYPE.excel}
              label="Excel"
              checked={exportType === REPORT_EXPORT_TYPE.excel}
              onChange={onSelectHandler}
            />
          </section>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="export-report-modal-footer">
          <Button type="cancel" onClick={onCloseHandler}>
            Cancel
          </Button>
          <Button type="confirm" onClick={onConfirmExportType}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ExportReport
