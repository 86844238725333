import { type PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  type IAddTransaction,
  type IAccountingInformation,
  type ICrossInformation,
  type IAddTransactionType,
  type ICostInformation,
  type IPropertyCombinedData,
  type IErrorData
} from '../../types/TransactionTypes'
import {
  accountingInformationInitialData, addTransactionTypeInitialData,
  costInformationInitialData,
  crossReferenceInitialData, errorInitialData,
  propertyDataInitalData,
  propertyFieldInitialData
} from './addTransactionInitialData'
import { StatusTypesEnum, TRANSACTION_TYPE_SECTION, VIEW_TRANSACTION_STATUS } from '../../types/CommonTypes'
import {
  DTYPE_ACQUISITION_PREPOPULATE_DATA,
  DTYPE_DISPOSITION_PREPOPULATE_DATA,
  TTYPE_ACQUISITION_PREPOPULATE_DATA,
  TTYPE_DISPOSITION_PREPOPULATE_DATA
} from '../../Constants'
import { getRecordNo } from '../middleWares/getRecordNo'
import formValidation from '../../util/formValidation'
import { postTransaction } from '../middleWares/postTransaction'
import { saveTransaction } from '../middleWares/saveTransaction'
import { getPropertyIdFromProperty } from '../middleWares/getPropertyIdFromProperty'
import { autoSaveTransactionIfNeeded } from '../middleWares/autoSaveTransactionIfNeeded'
import { updateTransaction } from '../middleWares/updateTransaction'
// import { uploadPINDocumentToEDMRM } from '../middleWares/edmrm'

export const addTransactionInitialData: IAddTransaction = {
  transactionType: addTransactionTypeInitialData,
  accountingInformation: accountingInformationInitialData,
  costInformation: costInformationInitialData,
  crossReference: [crossReferenceInitialData],
  propertyData: propertyDataInitalData,
  errorData: errorInitialData,
  isPostSubmitted: false,
  status: { type: undefined, message: '' },
  // edmrmStatus: { type: undefined, message: '' },
  isBookedTransaction: false,
  isStatusPost: false,
  isStatusPostSaved: false

}

const addTransactionSlice = createSlice({
  name: 'addTransaction',
  initialState: addTransactionInitialData,
  reducers: {
    setTransactionType (state, action: PayloadAction<IAddTransactionType>) {
      state.transactionType.type = action.payload.type
      state.transactionType.section = action.payload.section
    },

    setAccountingInformationData<T extends keyof IAccountingInformation>(state: IAddTransaction, action: PayloadAction<Pick<IAccountingInformation, T>>) {
      state.accountingInformation = { ...state.accountingInformation, ...action.payload }
      const value = Object.values(action.payload)[0]
      const key = Object.keys(action.payload)[0]
      if (value === VIEW_TRANSACTION_STATUS.POST && key === 'transactionStatus') {
        state.isStatusPost = true
      } else if (key === 'transactionStatus' && value !== VIEW_TRANSACTION_STATUS.POST) {
        state.isStatusPost = false
      }
    },

    setCostInformationData (state, action: PayloadAction<ICostInformation>) {
      state.costInformation = { ...state.costInformation, ...action.payload }
    },

    setCrossReferenceData (state, action: PayloadAction<{ data: ICrossInformation | null, index: number }>) {
      const existingState = [...state.crossReference]
      if (action.payload.data === null) {
        existingState.splice(action.payload.index, 1)
      } else {
        existingState[action.payload.index] = action.payload.data
      }
      state.crossReference = existingState
    },

    setPropertyData (state, action: PayloadAction<{ data: (IPropertyCombinedData) | null, index: number }>) {
      const existingState = [...state.propertyData.fieldData]
      if (action.payload.data === null) {
        existingState.splice(action.payload.index, 1)
      } else {
        existingState[action.payload.index] = action.payload.data
      }
      state.propertyData.fieldData = existingState
    },

    setTotalPropertyData (state, action: PayloadAction<{ totalAcres: number, totalAmount: number }>) {
      state.propertyData.totalAcres = action.payload.totalAcres
      state.propertyData.totalAmount = action.payload.totalAmount
    },

    addNewIndexToPropertyData (state) {
      state.propertyData.fieldData.push(propertyFieldInitialData)
    },

    addNewIndexToCrossReferrenceData (state) {
      state.crossReference.push(crossReferenceInitialData)
    },

    resetAddTransactionFields (state: IAddTransaction) {
      const { preparedBy, ...rest } = accountingInformationInitialData
      state.accountingInformation = { ...state.accountingInformation, ...rest }
      state.costInformation = { ...costInformationInitialData }
      state.crossReference = [{ ...crossReferenceInitialData }]
      state.propertyData = propertyDataInitalData
      state.errorData = errorInitialData
      state.isBookedTransaction = false
      state.isPostSubmitted = false
      state.status.type = undefined
      state.status.message = ''
      // state.edmrmStatus.type = undefined
      // state.edmrmStatus.message = ''
      state.transactionType = addTransactionTypeInitialData
      state.isStatusPostSaved = false
    },

    updateStateBasedOnTransactionSection (state: IAddTransaction, action: PayloadAction<string>) {
      if (action.payload === TRANSACTION_TYPE_SECTION.ACQUISITION) {
        state.accountingInformation.documentType = DTYPE_ACQUISITION_PREPOPULATE_DATA
        state.accountingInformation.transactionType = TTYPE_ACQUISITION_PREPOPULATE_DATA
      } else {
        state.accountingInformation.documentType = DTYPE_DISPOSITION_PREPOPULATE_DATA
        state.accountingInformation.transactionType = TTYPE_DISPOSITION_PREPOPULATE_DATA
      }

      /* const documentType = action.payload === TRANSACTION_TYPE_SECTION.ACQUISITION
        ? DTYPE_ACQUISITION_PREPOPULATE_DATA
        : DTYPE_DISPOSITION_PREPOPULATE_DATA
      state.accountingInformation.documentType = documentType */
    },

    doValidationOnSave (state: IAddTransaction, action: PayloadAction<boolean>) {
      /// TBD - once record is saved , does nt allow to change the transaction type
      const errdata = formValidation(state, action.payload)
      state.errorData = errdata

      if (state.accountingInformation.transactionStatus === VIEW_TRANSACTION_STATUS.BOOKED || state.isPostSubmitted) {
        if (!errdata.isError) {
          state.isPostSubmitted = true
        }
      }
    },
    setIsPostSubmiitedValue (state, action: PayloadAction<boolean>) {
      state.isPostSubmitted = action.payload
    },

    updateErrorDescription (state, action: PayloadAction<IErrorData>) {
      state.errorData = { ...state.errorData, errorDesc: action.payload }
    },

    updatePropertyDataError (state, action: PayloadAction<any>) {
      state.errorData = { ...state.errorData, propertyDataError: action.payload }
    },

    resetErrorValue (state, action: PayloadAction<boolean>) {
      state.errorData.isError = action.payload
      state.errorData.defaultErr = ''
      state.isPostSubmitted = false
    },
    resetAddTransactionStatus (state) {
      state.status = { type: undefined, message: '' }
    },
    /* resetAddTransactionEdmrmStatus (state) {
      state.edmrmStatus = { type: undefined, message: '' }
    }, */
    clearParcelNumber (state, action: PayloadAction<{ id: number }>) {
      state.propertyData.fieldData[action.payload.id].parcelNumber = ''
    }
  },

  extraReducers: (builder) => {
    /* builder.addCase(uploadPINDocumentToEDMRM.fulfilled, (state) => {
      state.edmrmStatus.type = StatusTypesEnum.SUCCESS
    })

    builder.addCase(uploadPINDocumentToEDMRM.rejected, (state, action) => {
      state.edmrmStatus.type = StatusTypesEnum.ERROR
      state.edmrmStatus.message = action.payload as string
    }) */

    builder.addCase(getRecordNo.fulfilled, (state, action) => {
      state.accountingInformation.recordNo = action.payload.recordNo
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
    })
    builder.addCase(getRecordNo.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
    })

    builder.addCase(saveTransaction.fulfilled, (state, action) => {
      state.accountingInformation = { ...state.accountingInformation, transId: action.payload.transId }
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.isStatusPost = false
      state.isStatusPostSaved = action.payload.transactionStatus === VIEW_TRANSACTION_STATUS.POST
    })

    builder.addCase(saveTransaction.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error?.message
    })
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      state.accountingInformation.transId = action.payload.transId
      state.accountingInformation.transactionStatus = action.payload.transactionStatus
      state.accountingInformation.statusDate = action.payload.statusDate
      state.isPostSubmitted = false
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.isStatusPost = false
      state.isStatusPostSaved = action.payload.transactionStatus === VIEW_TRANSACTION_STATUS.POST
    })
    builder.addCase(updateTransaction.rejected, (state, action) => {
      state.isPostSubmitted = false
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error?.message
    })

    builder.addCase(postTransaction.fulfilled, (state, action) => {
      state.isPostSubmitted = false
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.isBookedTransaction = true
      state.isStatusPost = false
      state.isStatusPostSaved = false
    })

    builder.addCase(postTransaction.rejected, (state, action) => {
      state.isPostSubmitted = false
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error?.message
      state.isBookedTransaction = false
    })

    builder.addCase(getPropertyIdFromProperty.fulfilled, (state, action) => {
      state.accountingInformation.propId = action.payload
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
    })

    builder.addCase(autoSaveTransactionIfNeeded.fulfilled, (state, action) => {
      if (!action.payload?.transId) return
      state.accountingInformation.transId = action.payload.transId
      state.status.type = StatusTypesEnum.SUCCESS
      // state.status.message = action.payload.message
    })

    builder.addCase(autoSaveTransactionIfNeeded.rejected, (state, action) => {
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error?.message
    })

    builder.addMatcher(isAnyOf(
      getRecordNo.pending,
      saveTransaction.pending,
      updateTransaction.pending,
      postTransaction.pending,
      getPropertyIdFromProperty.pending,
      autoSaveTransactionIfNeeded.pending
    ), (state) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = addTransactionInitialData.status.message
    })
  }
})

export const {
  setTransactionType
  , setAccountingInformationData
  , setCostInformationData
  , setCrossReferenceData
  , setPropertyData
  , updateStateBasedOnTransactionSection
  , resetAddTransactionFields
  , doValidationOnSave
  , resetErrorValue
  , addNewIndexToPropertyData
  , setTotalPropertyData
  , addNewIndexToCrossReferrenceData
  , updateErrorDescription
  , updatePropertyDataError
  , setIsPostSubmiitedValue
  , resetAddTransactionStatus
  // , resetAddTransactionEdmrmStatus
  , clearParcelNumber
} = addTransactionSlice.actions
export default addTransactionSlice.reducer
