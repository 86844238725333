import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { GET_RECORD_NO } from '../../Constants'

export const getRecordNo = createAsyncThunk('get-recordno', async (_, { rejectWithValue }) => {
  try {
    const responseData = await api.get(`/${GET_RECORD_NO}`)
    return responseData.data
  } catch (error: any) {
    if (error.response && error.response.data) {
      const { statusCode } = error.response.data
      if (statusCode === 401) {
        return rejectWithValue(error.response.data)
      } else {
        throw error.response.data
      }
    }
  }
})
