import React from 'react'
import '../routeOrganizations/RouteOrganizations.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { type ICompany } from '../../../../types/MaintenanceCategoryTypes'
import { setDeleteItemIndex, setMaintananceData, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
// import { NumericFormat } from 'react-number-format'
import Button from '../../../sharedModules/button/Button'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import Input from '../../../sharedModules/input/Input'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  companyData: ICompany[]
}

export const Company: React.FC<ChildProps> = ({ sort, onChange, onDelete, companyData }) => {
  const dispatch = useDispatch<AppDispatch>()

  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })
  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  const onAddButtonClickHandler = (): void => {
    const newData: ICompany = {
      companyId: '',
      companyName: ''
    }
    dispatch(setMaintananceData([...companyData, newData]))
    onChange()
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value } = e.target
    if (e.target.name === 'companyName') value = value.substring(0, 75)
    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }

  const renderComponent = (item: ICompany, index: number) => {
    const { companyId, companyName } = item

    return (
      <div className='div-container' key={index}>
        <section className='input-section small'>
          <Input
            name='companyId'
            className='input-select-box company-input company-id'
            value={companyId ?? ''}
            onChange={(e) => { handleChange(e, index) }}
            readOnly={true}
            // autoComplete='off'
            // aria-autocomplete='none'
          />
        </section>
        <section className='input-section large'>
          <Input
            name='companyName'
            value={companyName ?? ''}
            onChange={(e) => { handleChange(e, index) }}
          />
        </section>
        <div className='delete-icon'>
          <DeleteSVG onClick={(e) => { handleDeleteByIndex(e, index) }} />
        </div>
      </div>
    )
  }
  return (
        <div className='container'>
            <div className='Header-container'>
                <label className='heading-label'>Companies</label>
            </div>
            <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
                headerList={maintenanceDataMaster[MaintenanceOptionsEnum.COMPANIES].tableHeader}
                smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.COMPANIES].smallColumnIndexes}
                className={'icon'} />
            <div onChange={handleInputChange}>
                {
                    companyData?.length > 0 &&
                      companyData.map((item, index) => {
                        return renderComponent(item, index)
                      })

                }
            </div>
            <div className="add-another-button-container">
                <Button
                    buttonType="button"
                    className="add-another-button"
                    type="confirm"
                    onClick={onAddButtonClickHandler}
                >
                    +Add Another
                </Button>
            </div>
        </div>

  )
}
