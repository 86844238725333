import React from 'react'
import './People.scss'
import { type IPeople } from '../../../../types/MaintenanceCategoryTypes'
import Input from '../../../sharedModules/input/Input'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import { setDeleteItemIndex, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import { useDispatch } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'

interface ChildProps {
  item: IPeople
  index: number
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
}

const PeopleSub: React.FC<ChildProps> = ({ item, index, onDelete }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { employeeId, lastName, firstName, initial, title } = item
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    let { value } = e.target
    const { name } = e.target
    if (name === 'lastName') value = value.substring(0, 25)
    if (name === 'firstName') value = value.substring(0, 25)
    if (name === 'title') value = value.substring(0, 25)
    if (name === 'initial') value = value.substring(0, 2)

    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }

  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  return (
    <div className='people-item-container'>
            <section className='small'>
                <Input
                name = 'employeeId'
                className='input-select-box people-input people-id'
                value={employeeId ?? ''}
                onChange={handleInputChange}
                readOnly={true}
                // autoComplete='off'
                // aria-autocomplete="none"
                />
            </section>
             <section className='large'>
                <Input
                className='input-class'
                name='lastName'
                value= {lastName ?? ''}
                onChange={handleInputChange}
                />
            </section>
            <section className='large'>
                <Input
                name='firstName'
                value= {firstName ?? ''}
                className= 'input-class'
                onChange={handleInputChange}
                />
            </section>
            <section className='small'>
                <Input
                name='initial'
                value= {initial ?? ''}
                className= 'input-class'
                onChange={handleInputChange}
                />
            </section>
            <section className='large'>
                <Input
                name='title'
                value= {title ?? ''}
                className= 'input-class'
                onChange={handleInputChange}
                />
            </section>
            <div className='delete-icon'>
              <DeleteSVG onClick={(e) => { handleDeleteByIndex(e) }}/>
            </div>
           </div>
  )
}

export default PeopleSub
