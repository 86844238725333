import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { DELETE_PROPERTY, UNAUTHORISED_MESSAGE } from '../../Constants'
import { type IPropertyView } from '../slices/viewPropertySlice'

const getViewPropertyState = (state: any) => {
  return state.viewProperty
}

export const deleteProperty = createAsyncThunk('delete-property/post/id',
  async (_, { getState }): Promise<any> => {
    try {
      const propertyData: IPropertyView = getViewPropertyState(getState())
      const responseData = await api.delete(`/${DELETE_PROPERTY}/${propertyData.inventoryInformation.propNo}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      return responseData.data
    } catch (error: any) {
      const { statusCode } = error.response.data
      if (statusCode === 401) {
        throw new Error(UNAUTHORISED_MESSAGE)
      } else throw error.response.data
    }
  })
