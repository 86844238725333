import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../config/axios.config'
import { ADD_TRANSACTION_ENDPOINT, UNAUTHORISED_MESSAGE } from '../../Constants'
import { type IAddTransaction } from '../../types/TransactionTypes'

const getTransactionState = (state: any) => state.addTransaction

export const saveTransaction = createAsyncThunk('add-transaction/post', async (_, { getState }) => {
  try {
    const transactionData: IAddTransaction = getTransactionState(getState())
    const responseData = await api.post(`/${ADD_TRANSACTION_ENDPOINT}`,
      {
        transactionType: transactionData.transactionType,
        accountingInformation: transactionData.accountingInformation,
        costInformation: transactionData.costInformation,
        crossReference: transactionData.crossReference,
        propertyData: transactionData.propertyData
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    return responseData.data
  } catch (error: any) {
    if (error.response && error.response.data) {
      const { statusCode } = error.response.data
      if (statusCode === 401) {
        throw new Error(UNAUTHORISED_MESSAGE)
      } else {
        throw error.response.data
      }
    }
  }
})
