import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { duplicateComponentActionTypes } from '../types/CommonTypes'

export interface renderDataType {
  key: number
  component: ReactElement
}

interface returnType {
  updateComponent: (action: duplicateComponentActionTypes, elementsToSync?: number) => any
  renderData: renderDataType[]
}

const useDuplicateComponent = (
  DupComp: React.FC<{ id: number, view?: boolean, isBookedUpdatable?: boolean, refresh?: boolean, item?: any, removeHandler?: (id: number) => any | undefined }>, view?: boolean, isBookedUpdatable?: boolean, refresh?: boolean, item?: any
): returnType => {
  const [renderData, setRenderData] = useState<renderDataType[]>([
    { key: 1, component: <DupComp key={1} id={1} view={view} isBookedUpdatable={isBookedUpdatable} refresh={refresh} item={item} /> }
  ])

  const getComponentRendered = (id: number) => {
    return (
      <DupComp
        key={id}
        id={id}
        view={view}
        isBookedUpdatable={isBookedUpdatable}
        refresh={refresh}
        item={item}
        removeHandler={id > 1 ? onRemoveHandler : undefined}
      />
    )
  }

  const renderDataRef = useRef(renderData)

  useEffect(() => {
    renderDataRef.current = renderData
  }, [renderData])

  const onRemoveHandler = (id: number) => {
    const filteredRenderData = renderDataRef.current
      .filter((data: renderDataType) => data.key !== id)
      .map((item: renderDataType, index: number) => ({
        key: (item.key = index + 1),
        component: getComponentRendered(index + 1)
      }))
    setRenderData(filteredRenderData)
  }

  const updateComponent = (action: duplicateComponentActionTypes, elementsToSync?: number) => {
    if (action === duplicateComponentActionTypes.ADD) {
      if (typeof elementsToSync !== 'undefined' && elementsToSync <= renderData.length) {
        return
      }

      setRenderData((prevRenderData) => {
        const lastKey: number = prevRenderData[prevRenderData.length - 1]?.key
        const newRenderData: renderDataType[] = prevRenderData.concat({
          key: lastKey + 1,
          component: (
            <DupComp
              key={lastKey + 1}
              id={lastKey + 1}
              view={view}
              isBookedUpdatable = {isBookedUpdatable}
              refresh={refresh}
              item={item}
              removeHandler={onRemoveHandler}
            />
          )
        })
        return newRenderData
      })
    }

    if (action === duplicateComponentActionTypes.RENDER_VIEW) {
      if (!elementsToSync) {
        return
      }
      const updatedRenderData = []
      for (let i = 0; i < elementsToSync; i++) {
        updatedRenderData.push({
          key: i + 1,
          component: (
            <DupComp
              key={i + 1}
              id={i + 1}
              view={view}
              isBookedUpdatable={isBookedUpdatable}
              refresh={refresh}
              item={item}
              removeHandler={onRemoveHandler}
            />
          )
        })
      }
      setRenderData(updatedRenderData)
    }
    if (action === duplicateComponentActionTypes.REFRESH_VIEW) {
      if (!elementsToSync) {
        return
      }
      const updatedRenderData = []
      for (let i = 0; i < elementsToSync; i++) {
        updatedRenderData.push({
          key: i + 1,
          component: (
            <DupComp
              key={i + 1}
              id={i + 1}
              view={view}
              isBookedUpdatable={isBookedUpdatable}
              refresh={refresh}
              item={item}
              removeHandler={onRemoveHandler}
            />
          )
        })
      }
      setRenderData(updatedRenderData)
    }
  }

  return { updateComponent, renderData }
}

export default useDuplicateComponent
