import React, { useState, type ReactElement } from 'react'
import TransactionPDFmain from '../transactionPDF/TransactionPDFmain'
import PropertyInventoryPdf from '../PropertyInventoryPdf/PropertyInventoryPdf'
import { useSelector } from 'react-redux'
import { PDFViewer } from '@react-pdf/renderer'
import { ReactComponent as CloseButton } from '../../../assets/close.svg'
import { PDF_TYPES } from '../../../types/CommonTypes'
import { type IPropertyInventory } from '../../../store/slices/viewPropertyInventorySlice'
import '../../sharedModules/modal/Modal.scss'
import './PdfModal.scss'
// import { type AppDispatch } from '../../../store/store'
import { type IAddTransaction } from '../../../types/TransactionTypes'
// import { uploadPINDocumentToEDMRM } from '../../../store/middleWares/edmrm'
import { type ITransactionView } from '../../../store/slices/viewTransactionSlice'

interface IModalProp {
  className?: string
  onClose: (data: boolean) => any
  pdfType?: string
}

const PdfModal = ({ className, onClose, pdfType }: IModalProp): ReactElement => {
  // const dispatch = useDispatch<AppDispatch>()
  const handleCloseBtnclick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    onClose(false)
  }
  const [isUpdated, setIsUpdated] = useState(false)
  const inventoryData: IPropertyInventory = useSelector((state: any) => state.viewPropertyInventory)
  const viewTransactionData: ITransactionView = useSelector((state: any) => state.viewTransaction)
  const addTransactionData: IAddTransaction = useSelector((state: any) => state.addTransaction)

  const getPDFcomponent = (component: any) => {
    switch (component) {
      case PDF_TYPES.PROPERTY_INVENTORY_PDF: {
        return <PropertyInventoryPdf inventoryData={inventoryData} />
      }
      case PDF_TYPES.ADD_TRANSACTION_PDF: {
        const renderedComponent = <TransactionPDFmain transactionData={addTransactionData} />
        if (!isUpdated) {
          setIsUpdated(true)
          /* const content = pdf(renderedComponent).toBlob()
           dispatch(uploadPINDocumentToEDMRM({ file: content, view: false })) */
        }
        return renderedComponent
      }
      case PDF_TYPES.VIEW_TRANSACTION_PDF: {
        const renderedComponent = <TransactionPDFmain transactionData={viewTransactionData} />
        if (!isUpdated) {
          setIsUpdated(true)
          /* const content = pdf(renderedComponent).toBlob()
           dispatch(uploadPINDocumentToEDMRM({ file: content, view: true })) */
        }
        return renderedComponent
      }
    }
  }

  return (
    <div className="modal-wrapper">
      <div className="close-btn" onClick={handleCloseBtnclick}>
        <CloseButton className="close-svg" />
      </div>
      <div className={`modal-container ${className ?? ''}`}>
        <PDFViewer style={{ width: '100%', height: '100vh' }} >
          {getPDFcomponent(pdfType)}
        </PDFViewer>
      </div>
    </div>
  )
}

export default PdfModal
