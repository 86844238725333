import React, { type FormEvent, useEffect, useState } from 'react'
import CrossReferenceInformation from '../../components/transactions/addTransaction/crossReferenceInformation/CrossReferenceInformation'
import CostInformation from '../../components/transactions/addTransaction/costInformation/CostInformation'
import AddTransactionModal from '../../components/transactions/addTransaction/addTransactionModal/AddTransactionModal'
import AccountingInformation from '../../components/transactions/addTransaction/accountingInformation/AccountingInformation'
import { AUTO_SAVE_INFO_MESSAGE } from '../../Constants'
import { useSelector, useDispatch } from 'react-redux'
import { DropDownFiledsEnum, PDF_TYPES, TRANSACTION_TYPE_SECTION } from '../../types/CommonTypes'
import './AddTransaction.scss'
import Button from '../../components/sharedModules/button/Button'
import { useNavigate } from 'react-router-dom'
import PropertyActivityData from '../../components/transactions/addTransaction/propertyActivityData/PropertyActivityData'
import { getDropdownValues } from '../../store/middleWares/getDropdownValues'
import { type AppDispatch } from '../../store/store'
import { type IAddTransactionType, type IErrorData } from '../../types/TransactionTypes'
import { resetAddTransactionFields, doValidationOnSave, resetErrorValue, setIsPostSubmiitedValue, resetAddTransactionStatus } from '../../store/slices/addTransactionSlice'
import ErrorModal from '../../components/welcome/ErrorModal'
import TransactionBokedConfirmationModal from '../../components/transactions/addTransaction/addTransactionModal/TransactionBokedConfirmationModal'
import { saveTransaction } from '../../store/middleWares/saveTransaction'
import ResponseModal from '../../components/welcome/ResponseModal'
import { autoSaveTransactionIfNeeded } from '../../store/middleWares/autoSaveTransactionIfNeeded'
import { updateTransaction } from '../../store/middleWares/updateTransaction'
import PdfModal from '../../components/pdfGenerator/pdfModal/PdfModal'
// import EdmrmErrorModal from '../../components/welcome/EdmrmErrorModal'
import { resetViewTransactionStatus } from '../../store/slices/viewTransactionSlice'
import { restoreLastPageUrl } from '../../util/restoreLastPageUrl'
import UnauthorizedWarningPage from '../common/UnauthorizedWarningPage'

const AddTransaction = () => {
  const [show, setShowValue] = useState<boolean>(false)
  const [showResponse, setResponseValue] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const isAuthorized = useSelector(
    (state: any) => state.auth.isAuthorized
  )

  const transactionType: IAddTransactionType = useSelector(
    (state: any) => state.addTransaction.transactionType
  )
  // const transactionStatus: string = useSelector(
  //   (state: any) => state.addTransaction.accountingInformation.transactionStatus
  // )

  const transId = useSelector((state: any) => state.addTransaction.accountingInformation.transId)

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [formCancelled, setFormCancelled] = useState<boolean>(false)
  const [viewPdf, setViewPdf] = useState<boolean>(false)

  const isPostSubmitted: boolean = useSelector(
    (state: any) => state.addTransaction.isPostSubmitted
  )

  const isBookedTransaction: boolean | false = useSelector((state: any) => {
    return state.addTransaction.isBookedTransaction
  })

  const isStatusPostSaved: boolean | false = useSelector((state: any) => {
    return state.addTransaction.isStatusPostSaved
  })

  const isStatusPost: boolean | false = useSelector((state: any) => {
    return state.addTransaction.isStatusPost
  })

  const propNo = useSelector((state: any) => {
    return state.addTransaction.accountingInformation.propertyNo
  })
  const {
    isError,
    errorDesc,
    defaultErr
  }: IErrorData = useSelector(
    (state: any) => state.addTransaction.errorData
  )

  useEffect(() => {
    if (!transactionType.section || !transactionType.type) return
    const commonDropdownFields = [DropDownFiledsEnum.AGENT, DropDownFiledsEnum.COUNTY,
      DropDownFiledsEnum.DOCUMENT_TYPE, DropDownFiledsEnum.TRANSACTION_DOC_TYPE, DropDownFiledsEnum.ESCROW_COMPANY, DropDownFiledsEnum.FERC_ACCT,
      DropDownFiledsEnum.GL_ACCT, DropDownFiledsEnum.INSTRUMENT_TITLE, DropDownFiledsEnum.ORGANIZATION,
      DropDownFiledsEnum.PLANT_LOCATION, DropDownFiledsEnum.PROPERTY, DropDownFiledsEnum.TITLE_COMPANY,
      DropDownFiledsEnum.TRANSACTION_STATUS, DropDownFiledsEnum.TRANSACTION_TYPE, DropDownFiledsEnum.OWNERSHIP]
    dispatch(getDropdownValues(transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION ? [...commonDropdownFields, DropDownFiledsEnum.ACQ_DOC_NO] : commonDropdownFields))
  }, [transactionType])

  useEffect(() => {
    if (propNo && propNo !== '' && transactionType.section === TRANSACTION_TYPE_SECTION.DISPOSITION) dispatch(getDropdownValues([DropDownFiledsEnum.ACQ_DOC_NO]))
  }, [propNo])

  useEffect(() => {
    dispatch(resetAddTransactionStatus())
    setShowValue(false)
    setResponseValue(false)
    return () => {
      if (!formSubmitted && !isBookedTransaction && !formCancelled && !isPostSubmitted) { dispatch(autoSaveTransactionIfNeeded({ view: false })) }
      dispatch(resetAddTransactionFields())
      dispatch(resetViewTransactionStatus())
    }
  }, [])

  useEffect(() => {
    if (!isError && formSubmitted) {
      if (transId && !isPostSubmitted && !isStatusPost) {
        dispatch(updateTransaction({ addTransaction: true }))
        setFormSubmitted(false)
      } else if (!isPostSubmitted && !isStatusPost) {
        dispatch(saveTransaction())
        setFormSubmitted(false)
      }
    }
  }, [formSubmitted])

  useEffect(() => {
    if (formCancelled) navigate(restoreLastPageUrl())
  }, [formCancelled])

  const errorModalCallBackFunc = () => {
    setFormSubmitted(false)
    setResponseValue(false)
    setShowValue(false)
  }

  const onAddTransactionFormChange = (e: FormEvent<HTMLFormElement>) => {
    setResponseValue(false)
    setShowValue(false)
    if (formSubmitted) setFormSubmitted(false)
  }

  const onSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event?.preventDefault()
    dispatch(doValidationOnSave(false))
    setFormSubmitted(true)
    setShowValue(true)
    setResponseValue(true)
  }

  const onPostClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(setIsPostSubmiitedValue(true))
    dispatch(doValidationOnSave(true))
    setFormSubmitted(true)
    setShowValue(true)
    setResponseValue(true)
  }

  const resetShowValue = () => {
    setShowValue(false)
    setFormSubmitted(false)
  }

  const onCancelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setFormCancelled(true)
  }
  const handlePdfgeneration = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    setViewPdf(true)
  }

  const onFormSubmitHandler = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const navigateFn = () => {
    setRefresh(!refresh)
  }
  if (!isAuthorized) return <UnauthorizedWarningPage />
  else {
    return (
    <>
      <AddTransactionModal />
      <TransactionBokedConfirmationModal view={false} show={show} resetShowValue={resetShowValue} />
      {showResponse && <ResponseModal navigateFn={navigateFn}/>}
      {/* <EdmrmErrorModal /> */}
      <ErrorModal defaultErr={defaultErr}
        text={Object.values(errorDesc)}
        show={isError}
        callBack={errorModalCallBackFunc}
        resetFn={resetErrorValue}
      />
      <form onSubmit={onFormSubmitHandler} className="add-transaction-form" onChange={onAddTransactionFormChange}
        autoComplete='off' autoCorrect='off' autoSave='off'>
        <div className="add-transaction-heading">
          <h2>Add Transaction</h2>
          <label>{AUTO_SAVE_INFO_MESSAGE}</label>
        </div>
        {transactionType.type && (
          <>
            <AccountingInformation view={isBookedTransaction} />
            <PropertyActivityData view={isBookedTransaction} refresh={refresh}/>
            <CostInformation view={isBookedTransaction} />
            <CrossReferenceInformation view={isBookedTransaction} />
            <div className="add-transaction-footer">
              <span>
                <Button onClick={onCancelHandler} type="cancel">
                  Cancel
                </Button>
              </span>
              <span className="add-transaction-button-group">
                <Button
                  onClick={onPostClickHandler}
                  className="post-button"
                  disabled={!isStatusPostSaved}
                  type="confirm"
                >
                  Post
                </Button>
                <Button
                  onClick={onSave}
                  className="save-button"
                  disabled={isBookedTransaction}
                  type="confirm"
                >
                  Save
                </Button>
                <Button
                  onClick={handlePdfgeneration}
                  className="create-pdf-button"
                  type="confirm"
                >
                  Create .pdf
                </Button>
              </span>
            </div>
          </>
        )}
      </form>
      {viewPdf && (
        <>
          <PdfModal onClose={setViewPdf} className='transaction-pdf-modal' pdfType={PDF_TYPES.ADD_TRANSACTION_PDF} />
        </>)}
    </>
    )
  }
}

export default AddTransaction
