import React from 'react'
import '../routeOrganizations/RouteOrganizations.scss'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch } from '../../../../store/store'
import { type IPlanLoc } from '../../../../types/MaintenanceCategoryTypes'
import { setDeleteItemIndex, setMaintananceData, updateMaintenanceData } from '../../../../store/slices/maintenanceSlice'
import { type SORT_DIRECTION } from '../../../../types/CommonTypes'
import { MaintenanceOptionsEnum, maintenanceDataMaster } from '../../maintenanceOptions'
import MaintenanceCategortHeader from '../maintenanceDataHeader/MaintenanceCategoryHeader'
import Button from '../../../sharedModules/button/Button'
import { ReactComponent as DeleteSVG } from '../../../../assets/Delete.svg'
import Input from '../../../sharedModules/input/Input'
import { replaceNonDigit } from '../../../../util/sanitizeString'

interface ChildProps {
  onChange: () => void
  sort: (sortData: Record<string, SORT_DIRECTION>) => void
  onDelete: (event: React.MouseEvent<SVGSVGElement>) => void
  plantLocations: IPlanLoc[]
}

export const PlantLocation: React.FC<ChildProps> = ({ sort, onChange, onDelete, plantLocations }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { sortingOrder } = useSelector((state: any) => {
    return state.maintenance
  })
  const handleDeleteByIndex = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    dispatch(setDeleteItemIndex(index))
    onDelete(event)
  }
  const onAddButtonClickHandler = (): void => {
    const newData: IPlanLoc = {
      plantLocationId: '',
      plantLocation: '',
      plantLocDescription: ''
    }
    dispatch(setMaintananceData([...plantLocations, newData]))
    onChange()
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    onChange()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    let { value } = e.target
    const { name } = e.target
    if (name === 'plantLocation') {
      const val = replaceNonDigit(value)
      value = val?.substring(0, 4)
    }
    if (name === 'plantLocDescription') {
      value = value.substring(0, 50)
    }
    dispatch(updateMaintenanceData({ data: { [e.target.name]: value }, index }))
  }

  const renderComponent = (item: IPlanLoc, index: number) => {
    const { plantLocation, plantLocDescription } = item

    return (
      <div className='div-container' key={index}>
        <section className='input-section small'>
          <Input
            className='input-box'
            name='plantLocation'
            value={plantLocation ?? ''}
            onChange={(e) => { handleChange(e, index) }}
          />
        </section>
        <section className='input-section large'>
          <Input
            name='plantLocDescription'
            value={plantLocDescription ?? ''}
            onChange={(e) => { handleChange(e, index) }}
          />
        </section>
        <div className='delete-icon'>
          <DeleteSVG onClick={(e) => {
            handleDeleteByIndex(e, index)
          }} />
        </div>
      </div>
    )
  }
  return (
    <div className='container'>
      <div className='Header-container'>
        <label className='heading-label'>Plant Location</label>
      </div>
      <MaintenanceCategortHeader sortFn={sort} sortingOrderObj={sortingOrder}
        headerList={maintenanceDataMaster[MaintenanceOptionsEnum.PLANT_LOCATION].tableHeader}
        smallColumnIndexes={maintenanceDataMaster[MaintenanceOptionsEnum.PLANT_LOCATION].smallColumnIndexes}
        className={'icon'} />
      <div onChange={handleInputChange}>
        {
          plantLocations?.length > 0 &&
          plantLocations.map((item, index) => {
            return renderComponent(item, index)
          })
        }
      </div>
      <div className="add-another-button-container">
        <Button
          buttonType="button"
          className="add-another-button"
          type="confirm"
          onClick={onAddButtonClickHandler}
        >
          +Add Another
        </Button>
      </div>
    </div>
  )
}
