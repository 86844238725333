import React from 'react'
import './Table.scss'
import { Link } from 'react-router-dom'
import { type SORT_DIRECTION } from '../../../types/CommonTypes'
import { ReactComponent as TrainagleSVG } from '../../../assets/Triangle-White.svg'
import NotFound404Page from '../../../pages/common/NotFound404'

interface ITableNavigationLink {
  path: string
  linkKey: string | null
  linkIndex: number
}

interface TableProps {
  headings: Array<{ column: string, dataKey: string }>
  data: Array<Record<string, any>>
  onClick: (sortData: Record<string, SORT_DIRECTION>) => void
  sortingOrderObj: Record<any, SORT_DIRECTION>
  isAllSortingRequired?: boolean
  sortIndex?: number[]
  navigationLinks?: ITableNavigationLink[]
  alignRightIndexes?: number[]
}

const Table: React.FC<TableProps> = ({
  headings,
  data,
  onClick,
  sortIndex,
  isAllSortingRequired,
  sortingOrderObj,
  navigationLinks,
  alignRightIndexes
}) => {
  const regexPattern = /\s+|#/g

  const renderSortButton = (dataKey: string, index: number) => (
    <div className= 'svg-container'
      onClick={(e) => {
        e.preventDefault()
        onClick({ [dataKey]: sortingOrderObj[dataKey] ?? 'DESC' })
      }}
    >
      <TrainagleSVG className={sortingOrderObj[dataKey] === 'ASC' ? 'up-arrow' : 'arrow' } />
    </div>
  )

  const isAlignRightIndex = (index: number) => {
    return alignRightIndexes && alignRightIndexes.includes(index)
  }
  const isNavigationIndex = (index: number): ITableNavigationLink | undefined => {
    if (!navigationLinks || navigationLinks.length === 0) return
    return navigationLinks?.find((item) => item.linkIndex === index)
  }
  return (
    <div className="transactions-table-container">
      <table>
        <thead>
          <tr>
            {headings?.map(({ column, dataKey }, index) => (
              <th
                key={index}
                className={`sortable ${column.replace(regexPattern, '-')}`}
              >
                <div className='th-container'>
                <label>{column}</label>
                {isAllSortingRequired && renderSortButton(dataKey, index)}
                {sortIndex?.includes(index) && !isAllSortingRequired && renderSortButton(dataKey, index)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0
            ? (
                data.map((value, rowIndex) => (
              <tr key={rowIndex}>
                {headings?.map(({ dataKey }, index) => {
                  const navigationLinkData = isNavigationIndex(index)
                  return (
                  <td key={index} className={isAlignRightIndex(index) ? 'td-right' : ''} >
                    { navigationLinkData
                      ? (
                      <Link to={navigationLinkData.path + `${navigationLinkData.linkKey ? (value[navigationLinkData.linkKey] ?? 'error') : value[dataKey]}`}>
                        {value[dataKey]}
                      </Link>
                        )
                      : (
                          value[dataKey]
                        )}
                  </td>
                  )
                })}
              </tr>
                ))
              )
            : (
            <tr>
              <td colSpan={headings?.length} className="empty-data-message">
              <NotFound404Page />
              </td>
            </tr>
              )}
        </tbody>
      </table>
    </div>
  )
}

export default Table
