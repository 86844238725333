import React from 'react'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { type IAccountingInformation, type IAddTransactionType } from '../../../../types/TransactionTypes'
import { TRANSACTION_TYPE_SECTION, VIEW_TRANSACTION_TYPE } from '../../../../types/CommonTypes'

const PropertyDataTotal = (props: {
  view?: boolean
  isBookedUpdatable?: boolean
}) => {
  const totalAmountDisp = useSelector(
    (state: any) => props.view ? state.viewTransaction.propertyData.totalAmount : state.addTransaction.propertyData.totalAmount)

  const totalAmount = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.costInformation.totalCost
      : state.addTransaction.costInformation.totalCost
  })

  const totalAcres = useSelector(
    (state: any) => props.view ? state.viewTransaction.propertyData.totalAcres : state.addTransaction.propertyData.totalAcres
  )

  const status: IAccountingInformation = useSelector(
    (state: any) => state.viewTransaction.accountingInformation
  )

  const transactionType: IAddTransactionType = useSelector(
    (state: any) => state.addTransaction.transactionType
  )

  const isBookedTransaction: boolean | false = useSelector((state: any) => {
    return props.view
      ? state.viewTransaction.isBookedTransaction
      : state.addTransaction.isBookedTransaction
  })

  const isBooked = () => {
    return props.view && isBookedTransaction && !props.isBookedUpdatable
  }

  return (
    <div className="total-acres-amount-container">
      <div className="sub-section">
        <section>
          <label className="input-label">Total Acres</label>
          <NumericFormat
            name="totalAcres"
            thousandSeparator=","
            readOnly
            value={Math.round(totalAcres * 1000) / 1000 ?? ''}
            placeholder={isBooked() ? '' : '0.000'}
            className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
            decimalScale={3}
            fixedDecimalScale
          />
        </section>
        {transactionType.section === TRANSACTION_TYPE_SECTION.ACQUISITION ||
          status.transactionType === VIEW_TRANSACTION_TYPE.ACQUISITION
          ? (
            <>
              <section>
                <label className="input-label">Total Amount</label>
                <NumericFormat
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale
                  name="totalAmount"
                  value={Math.round(totalAmount * 100) / 100 ?? ''}
                  thousandSeparator=","
                  readOnly
                  placeholder={isBooked() ? '' : '$0.00'}
                  className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                />
              </section>
            </>
            )
          : (
            <>
              <section>
                <label className="input-label">Total Amount</label>
                <NumericFormat
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale
                  name="totalAmount"
                  value={totalAmountDisp ?? ''}
                  thousandSeparator=","
                  readOnly
                  placeholder={isBooked() ? '' : '$0.00'}
                  className={isBooked() ? 'read-input input-select-box' : 'input-select-box'}
                />
              </section>
            </>
            )}
      </div>
    </div>
  )
}

export default PropertyDataTotal
